import { Flex, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";

export interface unorderedList {
  title?: string;
  list: string[];
}

interface UnorderedListArrayProps {
  unorderedList: unorderedList[];
}

export const UnorderedListArray = ({
  unorderedList,
}: UnorderedListArrayProps) => {
  return (
    <Flex flexDir={"column"} gap={6}>
      {unorderedList.map((item, index) => (
        <Flex flexDir={"column"} gap={2} key={index}>
          {item.title && <Heading>{item.title}</Heading>}
          <UnorderedList color={"secondary.200"}>
            {item.list.map((listItem, i) => (
              <ListItem key={i}>
                <Text fontSize={"14px"}>{listItem}</Text>
              </ListItem>
            ))}
          </UnorderedList>
        </Flex>
      ))}
    </Flex>
  );
};
