/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

interface FormErrorProps {
  children?: React.ReactNode;
}

export const FormError: React.FC<FormErrorProps & BoxProps> = ({
  children,
  ...props
}) => {
  return (
    <Box
      minH={children ? "1.4em" : "0"}
      fontSize="sm"
      mt="2"
      color="red.500"
      transition="height 0.1s ease-in-out"
      {...props}
    >
      {children}
    </Box>
  );
};
