import { useTranslation } from "react-i18next";
import { SelectOption } from "@/components/Forms/Select/types";
import { object, string } from "yup";

const useValidation = () => {
  const { t } = useTranslation();

  return object({
    title: string().required(t("El campo es requerido")),
    agency: string().required(t("El campo es requerido")),
    categoryId: string().required(t("El campo es requerido")),
    subcategoryId: string().required(t("El campo es requerido")),
    subtitle: string().required(t("El campo es requerido")),
    description: string().required(t("El campo es requerido")),
    agencyImage: string().required(t("El campo es requerido")),
  });
};

export const categoryTypeOptions: SelectOption[] = [
  { name: "MAIN", value: "MAIN" },
  { name: "SUB", value: "SUB" },
];

export default useValidation;
