import { Flex } from "@chakra-ui/react";
import { ValidateBootcampEnrrollments } from "@/schemaTypes";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/Button";
import { ModalActionsProps } from "./utils";

export const ModalActions = ({
  data,
  onPrimaryAction,
  onSecondaryAction,
}: ModalActionsProps) => {
  const { t } = useTranslation();

  if (!data) return null;

  const isSuccess = data.type === ValidateBootcampEnrrollments.Success;
  const isBlockEnroll = data.type === ValidateBootcampEnrrollments.Blockenroll;

  const primaryButtonTitle = isSuccess
    ? t("Confirm")
    : isBlockEnroll
    ? t("No")
    : data.type === ValidateBootcampEnrrollments.Confirmblockenroll
    ? t("See other bootcamps")
    : t("Accept");

  const secondaryButtonTitle = isSuccess ? t("Back") : t("Yes, cancel");

  return (
    <Flex flexDirection="column" w="100%" gap={4}>
      <Button
        w="100%"
        variant="primary"
        title={primaryButtonTitle}
        onClick={onPrimaryAction}
      />
      {(isSuccess || isBlockEnroll) && (
        <Button
          w="100%"
          variant="link"
          title={secondaryButtonTitle}
          onClick={onSecondaryAction}
        />
      )}
    </Flex>
  );
};
