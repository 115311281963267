/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useQuery } from "@apollo/client";
import {
  CSSReset,
  ChakraProvider,
  ColorModeScript,
  Flex,
} from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import TagManager from "react-gtm-module-nonce";

import { mergeThemeWithDefaults } from "@/components/Theme";
import App from "./App";
import CookieBanner from "./components/CookieBanner";
import { config } from "./config";
import { GetCurrentSchoolDocument } from "./graphql/getCurrentSchool.generated";
import { useUser } from "./providers/useUser";

export const ThemeSwitcher = () => {
  const { theme, setCurrentSchool, setTheme } = useUser();
  const { data: currentSchoolData, loading: isLoadingSchoolData } = useQuery(
    GetCurrentSchoolDocument
  );

  useEffect(() => {
    if (currentSchoolData) {
      setCurrentSchool(currentSchoolData.currentSchool);
      if (currentSchoolData.currentSchool.theme) {
        setTheme(mergeThemeWithDefaults());
      }

      if (
        config.VITE_APP_ENV === "production" &&
        currentSchoolData.currentSchool.googleAnalytics?.tagManagerId
      ) {
        TagManager.initialize({
          gtmId: currentSchoolData.currentSchool.googleAnalytics?.tagManagerId,
          nonce: "3BLM0IRTIBnkzFUp5db2aTx8dJ",
        });
      }

      if (
        config.VITE_APP_ENV === "production" ||
        config.VITE_APP_ENV === "staging"
      ) {
        datadogRum.init({
          applicationId: config.DATADOG_APPLICATION_ID,
          clientToken: config.DATADOG_CLIENT_TOKEN,
          site: "datadoghq.com",
          service: config.DATADOG_SERVICE,
          env: config.DATADOG_ENV,
          version: `${String(config.APP_COMMIT_REF)}`,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 50,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: "mask-user-input",
        });
      }
    }
  }, [currentSchoolData]);

  if (isLoadingSchoolData && !currentSchoolData) {
    return (
      <Flex align="center" justify="center" w="100%" h="100vh">
        <svg
          width="120px"
          height="120px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#c5c5c5"
            strokeWidth="3"
            r="13"
            strokeDasharray="61.26105674500097 22.420352248333657"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
      </Flex>
    );
  }

  return (
    // <EmotionCacheProvider nonce="J23OrU3nna08YxqOQNE1UrO9mD">
    <ChakraProvider theme={theme}>
      <CSSReset />
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <CookieBanner />
      <App />
    </ChakraProvider>
    // </EmotionCacheProvider>
  );
};
