import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
} from "@chakra-ui/react";
import { ReactElement } from "react";

interface IconButtonProps
  extends Omit<ChakraIconButtonProps, "onChange" | "aria-label"> {
  icon: ReactElement;
  onClick: () => void;
  variant: "primary" | "secondary" | "link" | "text";
  ariaLabel: string;
  isDisabled?: boolean;
}

const IconButton = ({
  icon,
  onClick,
  variant,
  ariaLabel,
  isDisabled = false,
  fontSize = "xl",
  ...restProps
}: IconButtonProps) => {
  return (
    <ChakraIconButton
      {...restProps}
      w={"40px"}
      h={"40px"}
      aria-label={ariaLabel}
      variant={variant}
      onClick={onClick}
      icon={icon}
      isDisabled={isDisabled}
      isRound={true}
      fontSize={fontSize}
    />
  );
};

export default IconButton;
