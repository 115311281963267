import { Flex, Box, Text, useColorModeValue } from "@chakra-ui/react";
import { NotificationItemProps } from "../utils";
import { calculateTimeAgo } from "@/components/Forum/utils";
import { Trans } from "react-i18next";
import AvatarForum from "@/components/Forum/AvatarForum";
import { Author } from "@/schemaTypes";

const NotificationItem = ({
  notification,
  handleNotificationClick,
}: NotificationItemProps) => {
  const { description, status, author } = notification;
  const hoverBgColor = useColorModeValue("neutral.50", "transparent");
  const notificationColor = "blue.500";
  let timeAgo = "Fecha desconocida";
  if (notification.registerDate) {
    timeAgo = calculateTimeAgo(notification.registerDate);
  }

  return (
    <Flex
      direction="column"
      py={{ base: 2, lg: 4 }}
      px={{ base: 3, lg: 3 }}
      cursor="pointer"
      borderRadius={"8px"}
      _hover={{ backgroundColor: hoverBgColor }}
      onClick={handleNotificationClick}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap={2} alignItems="center">
          <AvatarForum w="24px" px={0} fs={"12px"} author={author as Author} />
          <Text
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxW={{ base: "240px", lg: "330px" }}
          >
            <Text fontSize="14px" as="span" fontWeight="bold">
              {author?.name} <Trans>{description?.descriptionEvent}</Trans>:
            </Text>
            <Text fontSize="14px" as="span" ml={1}>
              {description?.eventName}
            </Text>
          </Text>
        </Flex>
        {!status && (
          <Box w="10px" h="10px" borderRadius="50%" bg={notificationColor} />
        )}
      </Flex>
      <Text color="gray.500" fontSize="14px" pl={8}>
        {timeAgo}
      </Text>
    </Flex>
  );
};

export default NotificationItem;
