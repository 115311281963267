import { Box, Grid, Text } from "@chakra-ui/react";
import { usePresenter } from "../presenter/usePresenter";
import { MarketplaceService } from "@/schemaTypes";
import { ServiceMarketplaceCardFragment } from "../gatewayLayer/graphql/serviceMarketplaceFragment.generated";
import { ServiceCard } from "./ServiceCard";

export const ServicesGrid: React.FC = () => {
  const { isMobile, filteredServicesForView, servicesForView } = usePresenter();

  return (!servicesForView && !filteredServicesForView) ||
    (filteredServicesForView && filteredServicesForView.length === 0) ? (
    <Box>
      <Text>No se encontraron Agencias para la categoria seleccionada</Text>
    </Box>
  ) : (
    <Grid
      gap="16px"
      templateColumns={
        isMobile ? "1" : "repeat(auto-fit, minmax(319px, min-content))"
      }
      justifyItems="start"
      alignSelf="flex-start"
      justifyContent="flex-start"
      my="1"
      w="100%"
      display={isMobile ? "auto" : "grid"}
    >
      {(filteredServicesForView ?? servicesForView)?.map(
        (service: MarketplaceService | null) => (
          <ServiceCard
            key={service?.id}
            service={service as ServiceMarketplaceCardFragment}
          ></ServiceCard>
        )
      )}
    </Grid>
  );
};
