import * as Types from '../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateMasterClassMutationVariables = Types.Exact<{
  createMasterClassInput: Types.CreateMasterClassInput;
}>;


export type CreateMasterClassMutation = { __typename?: 'Mutation', createMasterClass: { __typename?: 'MasterClass', id: string, name: string, userId: string, mentorId: string, channelName: string, description: string, liveSessionType?: Types.LiveSessionType | null, startDate: Date, endDate: Date, ItIsScheduled?: boolean | null, imageUrlMentor?: string | null } };


export const CreateMasterClassDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateMasterClass"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createMasterClassInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateMasterClassInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createMasterClass"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createMasterClassInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createMasterClassInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"mentorId"}},{"kind":"Field","name":{"kind":"Name","value":"channelName"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"liveSessionType"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}},{"kind":"Field","name":{"kind":"Name","value":"ItIsScheduled"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrlMentor"}}]}}]}}]} as unknown as DocumentNode<CreateMasterClassMutation, CreateMasterClassMutationVariables>;