import { Category, MarketplaceService } from "@/schemaTypes";

export const filterByCategory = (
  rawData: Array<MarketplaceService | null> | null | undefined,
  selectedCategories: string | Array<string>
): Array<MarketplaceService | null> | null | undefined => {
  return (
    rawData?.filter((item) => {
      if (typeof selectedCategories === "string") {
        return item?.categoryId === selectedCategories;
      } else if (Array.isArray(selectedCategories)) {
        return rawData
          .flatMap((_) => _?.id)
          .includes(item?.subcategoryId as string);
      }
    }) ?? []
  );
};

export const handleAllSubcategoriesSelected = (
  subCategoriesForPresenter: Array<Category>,
  categoryIds: Array<string>
): Array<string> => {
  if (
    categoryIds.length > 1 &&
    categoryIds.length < subCategoriesForPresenter.length &&
    categoryIds.includes("ALL")
  ) {
    categoryIds = categoryIds.filter((category) => category !== "ALL");
  } else if (
    categoryIds.length === 0 ||
    (categoryIds.length === 1 && categoryIds.includes("ALL"))
  ) {
    categoryIds = ["ALL"];
  }
  return categoryIds;
};
