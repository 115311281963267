import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Trans } from "react-i18next";
import { Button } from "./Button";

interface ModalBodyProps {
  onClose: () => void;
  isOpen: boolean;
}

export const ForgotPassswordModal: React.FC<ModalBodyProps> = ({
  onClose,
  isOpen,
}) => {
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={"8px"}
        h="415px"
        w={{
          base: "90%",
          lg: "345px",
        }}
      >
        <Box
          onClick={onClose}
          display={"flex"}
          justifyContent={"flex-end"}
          p={5}
        >
          <svg
            cursor={"pointer"}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L12 10.5858L18.2929 4.2929C18.6834 3.90237 19.3166 3.90237 19.7071 4.2929C20.0976 4.68342 20.0976 5.31659 19.7071 5.70711L13.4142 12L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12 13.4142L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.5858 12L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
              fill="#1E1E23"
            />
          </svg>
        </Box>

        <ModalBody>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Box
              w={"88px"}
              h={"88px"}
              borderRadius={"100%"}
              backgroundColor={"neutral.50"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <svg
                width="231"
                height="186"
                viewBox="0 0 231 186"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M158.117 151.375C158.119 152.003 157.998 152.625 157.761 153.206C157.524 153.787 157.176 154.315 156.736 154.76C156.296 155.205 155.774 155.559 155.198 155.8C154.623 156.041 154.006 156.165 153.382 156.165L77.4344 156.321C76.8113 156.322 76.1941 156.2 75.6179 155.961C75.0417 155.722 74.5179 155.371 74.0765 154.927C73.635 154.484 73.2846 153.958 73.0453 153.378C72.806 152.798 72.6824 152.176 72.6817 151.548L72.575 97.7276L114.613 71.1281C114.801 71.0085 115.019 70.9446 115.241 70.9435C115.464 70.9424 115.682 71.0043 115.871 71.1222L157.025 96.938L158.01 97.5539V98.4151L158.117 151.375Z"
                  fill="#E3BF00"
                />
                <path
                  d="M151.921 75.7603H78.213C78.0373 75.7603 77.8948 75.9039 77.8948 76.081V155.573C77.8948 155.75 78.0373 155.893 78.213 155.893H151.921C152.097 155.893 152.239 155.75 152.239 155.573V76.081C152.239 75.9039 152.097 75.7603 151.921 75.7603Z"
                  fill="#F7F8F9"
                />
                <path
                  d="M153.625 155.862L115.929 126.894C115.898 126.871 115.874 126.84 115.858 126.806C115.841 126.771 115.833 126.733 115.834 126.694C115.835 126.656 115.845 126.618 115.863 126.584C115.881 126.551 115.907 126.522 115.939 126.5L157.95 97.5605C157.986 97.5356 158.028 97.5211 158.072 97.5186C158.116 97.5161 158.16 97.5256 158.198 97.5462C158.237 97.5668 158.27 97.5977 158.292 97.6355C158.315 97.6733 158.327 97.7166 158.327 97.7608L158.433 151.122C158.436 152.376 157.95 153.58 157.08 154.476C156.211 155.372 155.027 155.889 153.784 155.915C153.727 155.915 153.671 155.897 153.625 155.862Z"
                  fill="#FFF580"
                />
                <path
                  d="M77.4984 156.241L115.08 127.122C115.11 127.099 115.134 127.068 115.151 127.033C115.167 126.999 115.175 126.961 115.174 126.922C115.173 126.884 115.163 126.846 115.144 126.812C115.126 126.779 115.1 126.75 115.068 126.728L72.9432 97.9581C72.9069 97.9333 72.8645 97.919 72.8208 97.9166C72.777 97.9142 72.7334 97.924 72.6947 97.9447C72.656 97.9654 72.6236 97.9964 72.601 98.0343C72.5785 98.0722 72.5666 98.1155 72.5667 98.1597L72.6727 151.521C72.6752 152.774 73.1657 153.977 74.0386 154.869C74.9115 155.762 76.0972 156.274 77.3406 156.295C77.3977 156.295 77.4532 156.277 77.4984 156.241Z"
                  fill="#FFF580"
                />
                <path
                  d="M153.376 156.143L77.4287 156.296C76.2798 156.295 75.17 155.875 74.3042 155.114C73.4385 154.352 72.8752 153.301 72.7184 152.154C72.6926 151.958 72.715 151.759 72.7838 151.575C72.8525 151.39 72.9654 151.225 73.1124 151.095L114.535 114.16C114.751 113.967 115.03 113.86 115.319 113.86C115.607 113.859 115.887 113.965 116.104 114.157L157.672 150.924C157.819 151.054 157.933 151.218 158.002 151.403C158.072 151.587 158.095 151.786 158.07 151.982C157.918 153.13 157.359 154.183 156.496 154.948C155.633 155.713 154.525 156.137 153.376 156.143Z"
                  fill="#FFE32B"
                />
                <path
                  d="M96.7616 95.4948C98.2362 95.4948 99.4316 93.8884 99.4316 91.9068C99.4316 89.9253 98.2362 88.3188 96.7616 88.3188C95.287 88.3188 94.0916 89.9253 94.0916 91.9068C94.0916 93.8884 95.287 95.4948 96.7616 95.4948Z"
                  fill="#1E1E23"
                />
                <path
                  d="M134.142 95.4948C135.617 95.4948 136.812 93.8884 136.812 91.9068C136.812 89.9253 135.617 88.3188 134.142 88.3188C132.668 88.3188 131.472 89.9253 131.472 91.9068C131.472 93.8884 132.668 95.4948 134.142 95.4948Z"
                  fill="#1E1E23"
                />
                <path
                  d="M125.539 107.392C125.449 107.392 125.361 107.369 125.282 107.325C125.203 107.281 125.137 107.217 125.089 107.14C125.061 107.096 122.157 102.575 115.408 102.008C112.599 101.772 109.933 102.561 107.488 104.352C106.397 105.141 105.428 106.089 104.613 107.164C104.532 107.279 104.408 107.357 104.27 107.381C104.132 107.405 103.991 107.373 103.876 107.292C103.761 107.211 103.683 107.088 103.658 106.949C103.633 106.81 103.664 106.667 103.744 106.551C103.788 106.486 104.855 104.966 106.832 103.51C109.46 101.575 112.456 100.687 115.496 100.942C122.797 101.555 125.862 106.369 125.988 106.574C126.038 106.655 126.066 106.748 126.069 106.843C126.071 106.938 126.048 107.033 126.003 107.116C125.957 107.2 125.89 107.269 125.808 107.318C125.727 107.366 125.634 107.392 125.539 107.392L125.539 107.392Z"
                  fill="#1E1E23"
                />
                <path
                  d="M117.867 44.6126H15.8333C15.8333 44.6126 18.1295 36.3433 25.2772 38.4305C25.2772 38.4305 26.7406 33.4137 30.7121 30.8023C34.6835 28.1909 39.7749 30.8023 39.7749 30.8023C39.7749 30.8023 40.8571 26.8853 43.6757 25.4907C46.4943 24.0961 49.6047 25.4907 49.6047 25.4907C49.6047 25.4907 52.7621 14.4836 62.653 14.4836C73.1557 14.4836 74.9344 24.2224 74.9344 24.2224C74.9344 24.2224 79.0376 16.8236 87.1734 18.7423C93.9729 20.3475 95.3328 29.1831 95.3328 29.1831C95.3328 29.1831 97.2385 28.1676 99.4972 29.1831C101.756 30.1986 101.93 33.2405 101.93 33.2405C101.93 33.2405 104.767 31.5417 107.497 33.2405C110.616 35.1827 109.887 39.8812 109.887 39.8812C109.887 39.8812 114.677 39.7408 117.867 44.6079V44.6126Z"
                  fill="#F2F2F4"
                />
                <path
                  d="M115.5 186C179.013 186 230.5 180.997 230.5 174.825C230.5 168.654 179.013 163.651 115.5 163.651C51.9873 163.651 0.5 168.654 0.5 174.825C0.5 180.997 51.9873 186 115.5 186Z"
                  fill="#F7F8F9"
                />
                <path
                  d="M57.8714 174.434C57.8714 175.767 51.3778 176.844 43.3691 176.844C35.3603 176.844 28.8667 175.763 28.8667 174.434C28.8667 173.105 35.3603 172.024 43.3691 172.024C51.3778 172.024 57.8714 173.105 57.8714 174.434Z"
                  fill="#DADADC"
                />
                <path
                  d="M37.3178 175.454C36.245 175.393 35.1721 175.309 34.1087 175.131C33.5817 175.033 33.0405 174.958 32.5465 174.7C32.4853 174.663 32.4288 174.621 32.3771 174.555C32.3535 174.527 32.3441 174.476 32.3347 174.434C32.3441 174.396 32.3488 174.34 32.3771 174.312C32.4712 174.181 32.6076 174.13 32.73 174.073C32.9841 173.966 33.2476 173.896 33.5111 173.839C34.5698 173.615 35.6427 173.516 36.7155 173.428C37.7884 173.357 38.8613 173.32 39.9388 173.278L36.725 173.507C35.6568 173.61 34.5839 173.722 33.544 173.956C33.2852 174.013 33.0217 174.078 32.7817 174.176C32.6641 174.228 32.5371 174.284 32.4712 174.373C32.3959 174.457 32.4947 174.555 32.6029 174.621C33.0594 174.864 33.6099 174.953 34.1275 175.061C35.1863 175.243 36.2544 175.356 37.3226 175.459L37.3178 175.454Z"
                  fill="white"
                />
                <path
                  d="M43.3691 175.656C42.6021 175.684 41.8351 175.67 41.0728 175.656C40.3058 175.633 39.5388 175.609 38.7765 175.543C39.5435 175.515 40.3105 175.529 41.0728 175.543C41.8398 175.567 42.6021 175.59 43.3691 175.656Z"
                  fill="white"
                />
                <path
                  d="M51.7119 175.229C51.2037 175.323 50.6908 175.375 50.1779 175.426C49.665 175.464 49.1521 175.511 48.6345 175.506C49.1427 175.412 49.6556 175.361 50.1685 175.309C50.6814 175.272 51.1943 175.225 51.7119 175.229Z"
                  fill="white"
                />
                <path
                  d="M43.3691 173.217C44.3008 173.217 45.2325 173.226 46.1689 173.245C47.1006 173.268 48.0369 173.254 48.9639 173.348C49.8956 173.437 50.8226 173.507 51.7543 173.605C52.2154 173.671 52.6766 173.741 53.133 173.844C53.3589 173.9 53.5847 173.956 53.8059 174.036C53.9141 174.078 54.0271 174.12 54.1259 174.176C54.22 174.232 54.3376 174.307 54.347 174.429C54.3329 174.307 54.2153 174.242 54.1165 174.19C54.0129 174.134 53.9047 174.101 53.7918 174.064C53.5706 173.994 53.3448 173.942 53.1142 173.9C52.6578 173.807 52.1966 173.741 51.7355 173.68C50.8085 173.591 49.8768 173.535 48.9498 173.46C48.0228 173.353 47.0911 173.353 46.1595 173.32C45.2278 173.297 44.2961 173.259 43.3644 173.212L43.3691 173.217Z"
                  fill="white"
                />
                <path
                  d="M39.0117 175.028C38.2447 175.005 37.4777 174.958 36.7154 174.86C36.3343 174.804 35.9531 174.762 35.5814 174.617C35.5344 174.593 35.4873 174.57 35.445 174.523C35.3932 174.481 35.4073 174.359 35.4591 174.331C35.5485 174.256 35.6426 174.223 35.7367 174.191C35.9249 174.13 36.1131 174.088 36.3014 174.055C37.8259 173.821 39.3599 173.784 40.8939 173.755C40.1316 173.816 39.3646 173.858 38.6023 173.905C37.8401 173.966 37.0778 174.036 36.3249 174.172C36.1367 174.205 35.9484 174.242 35.7743 174.294C35.6849 174.317 35.5955 174.355 35.5391 174.401C35.5014 174.429 35.5155 174.434 35.5296 174.457C35.5485 174.481 35.5908 174.504 35.6285 174.528C35.9673 174.668 36.3578 174.724 36.7296 174.785C37.4871 174.893 38.2494 174.963 39.0117 175.033V175.028Z"
                  fill="white"
                />
                <path
                  d="M43.3693 175.151C42.8187 175.188 42.2682 175.179 41.7129 175.179C41.1624 175.16 40.6118 175.146 40.0613 175.089C40.6165 175.052 41.1671 175.061 41.7176 175.061C42.2682 175.08 42.8187 175.094 43.3693 175.151Z"
                  fill="white"
                />
                <path
                  d="M49.3778 174.897C49.0155 174.972 48.6438 175.005 48.2768 175.038C47.905 175.057 47.538 175.081 47.1663 175.062C47.5333 174.987 47.9003 174.954 48.2673 174.921C48.6344 174.902 49.0061 174.878 49.3778 174.897Z"
                  fill="white"
                />
                <path
                  d="M43.3691 173.723C44.0373 173.713 44.7054 173.713 45.3736 173.723C46.0418 173.732 46.71 173.723 47.3782 173.774L49.378 173.933C49.7121 173.97 50.0415 174.012 50.3709 174.073C50.5356 174.106 50.7003 174.143 50.8603 174.19C50.9402 174.214 51.0202 174.242 51.0955 174.274C51.1661 174.307 51.2602 174.344 51.2743 174.433C51.2555 174.349 51.1614 174.316 51.0861 174.288C51.0108 174.26 50.9261 174.237 50.8461 174.218C50.6861 174.181 50.5215 174.153 50.3568 174.129C50.0274 174.078 49.698 174.041 49.3639 174.008L47.3641 173.891C46.7006 173.825 46.0324 173.825 45.3642 173.802C44.696 173.788 44.0279 173.76 43.3644 173.723H43.3691Z"
                  fill="white"
                />
                <path
                  d="M164.48 165.719C167.395 165.719 169.759 165.31 169.759 164.806C169.759 164.302 167.395 163.894 164.48 163.894C161.564 163.894 159.2 164.302 159.2 164.806C159.2 165.31 161.564 165.719 164.48 165.719Z"
                  fill="#DADADC"
                />
                <path
                  d="M166.31 164.432C166.639 164.446 166.974 164.469 167.303 164.535C167.468 164.572 167.632 164.6 167.788 164.712C167.825 164.736 167.872 164.806 167.849 164.876C167.825 164.937 167.778 164.97 167.741 164.993C167.661 165.04 167.581 165.068 167.501 165.096C166.846 165.274 166.178 165.288 165.52 165.292C166.174 165.189 166.842 165.171 167.468 164.984C167.604 164.946 167.83 164.853 167.717 164.792C167.604 164.708 167.435 164.666 167.279 164.619C166.964 164.539 166.635 164.483 166.31 164.432Z"
                  fill="white"
                />
                <path
                  d="M164.48 164.353C164.71 164.31 164.945 164.315 165.176 164.315C165.407 164.329 165.642 164.338 165.868 164.395C165.632 164.437 165.402 164.433 165.171 164.433C164.941 164.419 164.71 164.409 164.48 164.353Z"
                  fill="white"
                />
                <path
                  d="M161.957 164.522C162.103 164.456 162.258 164.432 162.414 164.409C162.569 164.399 162.729 164.38 162.884 164.409C162.734 164.475 162.578 164.498 162.428 164.522C162.273 164.531 162.117 164.55 161.957 164.522Z"
                  fill="white"
                />
                <path
                  d="M164.48 165.317C164.193 165.331 163.91 165.336 163.623 165.336C163.336 165.336 163.049 165.336 162.767 165.298C162.484 165.261 162.197 165.237 161.915 165.186C161.774 165.158 161.633 165.12 161.496 165.078C161.43 165.054 161.36 165.026 161.299 164.989C161.242 164.956 161.162 164.909 161.157 164.834C161.214 164.97 161.388 164.984 161.51 165.021C161.647 165.054 161.788 165.078 161.929 165.097L162.776 165.181C163.058 165.228 163.341 165.223 163.623 165.247C163.905 165.265 164.193 165.289 164.475 165.317H164.48Z"
                  fill="white"
                />
                <path
                  d="M165.797 164.6C166.032 164.6 166.268 164.605 166.503 164.642C166.621 164.661 166.738 164.675 166.861 164.745C166.884 164.755 166.955 164.82 166.912 164.886C166.879 164.933 166.846 164.942 166.818 164.961C166.757 164.989 166.701 165.003 166.639 165.022C166.169 165.12 165.698 165.125 165.228 165.111C165.694 165.031 166.164 165.017 166.611 164.909C166.71 164.9 166.87 164.816 166.799 164.834C166.719 164.783 166.592 164.764 166.484 164.731C166.258 164.685 166.028 164.642 165.792 164.605L165.797 164.6Z"
                  fill="white"
                />
                <path
                  d="M164.48 164.554C164.644 164.511 164.814 164.511 164.983 164.507C165.148 164.516 165.317 164.525 165.482 164.577C165.312 164.62 165.148 164.62 164.978 164.624C164.814 164.615 164.649 164.606 164.48 164.554Z"
                  fill="white"
                />
                <path
                  d="M162.663 164.652C162.767 164.596 162.88 164.577 162.993 164.563C163.106 164.563 163.218 164.554 163.331 164.591C163.223 164.652 163.115 164.667 163.002 164.681C162.889 164.681 162.781 164.69 162.663 164.652Z"
                  fill="white"
                />
                <path
                  d="M164.48 165.115C164.075 165.148 163.666 165.157 163.256 165.129C163.054 165.106 162.847 165.092 162.645 165.059C162.546 165.04 162.442 165.017 162.343 164.988C162.254 164.951 162.127 164.932 162.085 164.834C162.136 164.918 162.263 164.913 162.353 164.937C162.452 164.956 162.55 164.965 162.654 164.974L163.261 165.017C163.463 165.045 163.666 165.045 163.868 165.059C164.07 165.073 164.273 165.092 164.475 165.12L164.48 165.115Z"
                  fill="white"
                />
                <path
                  d="M197.236 181.585C208.081 181.585 216.872 180.014 216.872 178.075C216.872 176.137 208.081 174.566 197.236 174.566C186.391 174.566 177.6 176.137 177.6 178.075C177.6 180.014 186.391 181.585 197.236 181.585Z"
                  fill="#DADADC"
                />
                <path
                  d="M205.518 179.788C207 179.611 208.482 179.409 209.931 179.058C210.294 178.965 210.651 178.871 211 178.74C211.343 178.609 211.701 178.473 211.969 178.235C212.11 178.122 212.2 177.954 212.152 177.799C212.101 177.645 211.964 177.519 211.809 177.42C211.503 177.224 211.15 177.097 210.802 176.98C209.386 176.531 207.899 176.316 206.421 176.124C205.682 176.03 204.934 175.993 204.191 175.923L201.96 175.722C203.452 175.82 204.944 175.899 206.431 176.044C207.913 176.222 209.404 176.424 210.84 176.863C211.193 176.985 211.55 177.116 211.87 177.327C212.03 177.43 212.185 177.57 212.251 177.767C212.312 177.977 212.185 178.188 212.04 178.3C211.743 178.553 211.385 178.689 211.033 178.815C210.68 178.941 210.317 179.035 209.95 179.124C208.492 179.47 207.005 179.643 205.518 179.779V179.788Z"
                  fill="white"
                />
                <path
                  d="M197.292 180.168L203.537 179.961C202.501 180.083 201.461 180.14 200.417 180.177C199.377 180.205 198.332 180.215 197.292 180.168Z"
                  fill="white"
                />
                <path
                  d="M185.952 179.372L188.046 179.62C188.743 179.709 189.439 179.784 190.135 179.891C189.43 179.882 188.729 179.817 188.032 179.737C187.336 179.648 186.639 179.545 185.952 179.372Z"
                  fill="white"
                />
                <path
                  d="M197.293 175.609C194.723 175.661 192.154 175.74 189.599 175.993C188.319 176.105 187.044 176.25 185.778 176.475C185.148 176.587 184.517 176.723 183.905 176.905C183.599 176.994 183.294 177.102 183.007 177.238C182.861 177.308 182.724 177.388 182.602 177.49C182.48 177.589 182.367 177.724 182.362 177.888C182.362 177.724 182.47 177.584 182.593 177.481C182.715 177.373 182.851 177.289 182.997 177.214C183.284 177.065 183.585 176.952 183.891 176.854C184.508 176.662 185.133 176.522 185.769 176.4C187.035 176.161 188.31 176.002 189.59 175.876C192.154 175.665 194.723 175.558 197.297 175.609H197.293Z"
                  fill="white"
                />
                <path
                  d="M203.217 178.998C204.271 178.885 205.32 178.764 206.36 178.563C206.619 178.506 206.878 178.455 207.127 178.38C207.376 178.305 207.635 178.226 207.842 178.085C207.946 178.02 208.03 177.912 207.974 177.823C207.922 177.73 207.795 177.664 207.687 177.608C207.452 177.5 207.198 177.425 206.943 177.365C205.922 177.107 204.868 176.981 203.819 176.873L200.652 176.625C201.711 176.672 202.77 176.714 203.824 176.794C204.878 176.892 205.937 177.004 206.972 177.252C207.231 177.318 207.485 177.393 207.734 177.514C207.852 177.584 207.983 177.641 208.063 177.781C208.078 177.818 208.092 177.861 208.092 177.903C208.082 177.94 208.078 177.987 208.054 178.02C208.012 178.085 207.955 178.132 207.899 178.169C207.668 178.324 207.405 178.394 207.151 178.469C206.892 178.539 206.633 178.595 206.374 178.647C205.329 178.843 204.275 178.937 203.217 179.007V178.998Z"
                  fill="white"
                />
                <path
                  d="M197.292 179.218C198.041 179.148 198.789 179.124 199.537 179.1C200.285 179.086 201.033 179.067 201.786 179.1C201.038 179.171 200.29 179.195 199.542 179.218C198.793 179.232 198.041 179.247 197.292 179.218Z"
                  fill="white"
                />
                <path
                  d="M189.128 178.754C189.637 178.754 190.14 178.806 190.639 178.848C191.138 178.904 191.641 178.96 192.14 179.059C191.636 179.059 191.133 179.007 190.629 178.965C190.126 178.909 189.627 178.853 189.128 178.754Z"
                  fill="white"
                />
                <path
                  d="M197.292 176.559C196.375 176.606 195.462 176.648 194.544 176.672C193.632 176.709 192.714 176.709 191.801 176.826C190.888 176.91 189.975 176.971 189.067 177.069C188.616 177.13 188.164 177.21 187.717 177.313C187.496 177.364 187.274 177.42 187.058 177.495C186.95 177.533 186.846 177.579 186.747 177.636C186.653 177.696 186.55 177.771 186.54 177.888C186.545 177.771 186.649 177.687 186.743 177.622C186.842 177.561 186.945 177.509 187.049 177.467C187.26 177.378 187.482 177.313 187.703 177.257C188.15 177.144 188.601 177.065 189.053 176.994C189.961 176.882 190.879 176.807 191.792 176.709C192.705 176.606 193.622 176.615 194.54 176.592C195.457 176.569 196.375 176.559 197.288 176.555L197.292 176.559Z"
                  fill="white"
                />
                <path
                  d="M63.6685 181.458C74.5133 181.458 83.3046 179.887 83.3046 177.948C83.3046 176.01 74.5133 174.438 63.6685 174.438C52.8238 174.438 44.0325 176.01 44.0325 177.948C44.0325 179.887 52.8238 181.458 63.6685 181.458Z"
                  fill="#DADADC"
                />
                <path
                  d="M72.4539 175.651C74.0585 175.805 75.6631 176.002 77.2347 176.39C77.6253 176.493 78.0158 176.596 78.397 176.741C78.7734 176.886 79.1593 177.036 79.4698 177.322C79.6298 177.448 79.7569 177.677 79.6957 177.897C79.6345 178.108 79.4745 178.272 79.3051 178.384C78.9663 178.623 78.5852 178.768 78.2041 178.908C76.6607 179.404 75.0514 179.634 73.4562 179.835C71.8516 179.999 70.2376 180.088 68.6331 180.205L71.0423 179.98C71.8422 179.905 72.6516 179.863 73.4468 179.755C75.042 179.545 76.6465 179.301 78.1664 178.796C78.5429 178.665 78.9193 178.52 79.244 178.295C79.4087 178.192 79.5451 178.042 79.5969 177.874C79.6439 177.701 79.5498 177.514 79.404 177.392C79.1169 177.13 78.7358 176.971 78.3687 176.826C77.997 176.681 77.6065 176.573 77.2206 176.47C75.6584 176.077 74.0585 175.857 72.4586 175.656L72.4539 175.651Z"
                  fill="white"
                />
                <path
                  d="M63.6075 175.229C64.7275 175.183 65.8474 175.197 66.9673 175.229C68.0872 175.276 69.2071 175.333 70.3223 175.464L63.6075 175.229Z"
                  fill="white"
                />
                <path
                  d="M51.4154 176.118C52.1495 175.935 52.8976 175.819 53.6505 175.72C54.4034 175.632 55.1563 175.557 55.9139 175.543C55.1657 175.655 54.4128 175.739 53.6646 175.837L51.4154 176.123V176.118Z"
                  fill="white"
                />
                <path
                  d="M63.6075 180.321C60.8359 180.368 58.0644 180.256 55.3069 180.012C53.9282 179.872 52.5542 179.694 51.1896 179.427C50.512 179.292 49.8344 179.133 49.1757 178.917C48.8463 178.805 48.5216 178.679 48.2157 178.515C48.0652 178.431 47.9146 178.337 47.7875 178.22C47.6605 178.108 47.5476 177.953 47.5523 177.775C47.5523 177.953 47.6699 178.103 47.8017 178.211C47.9287 178.323 48.0793 178.412 48.2299 178.491C48.5357 178.646 48.8604 178.768 49.1945 178.866C49.8533 179.072 50.5308 179.221 51.2084 179.353C52.5683 179.605 53.9423 179.769 55.3211 179.895C58.0738 180.176 60.8453 180.265 63.6122 180.321H63.6075Z"
                  fill="white"
                />
                <path
                  d="M69.9787 176.535C71.1175 176.615 72.2562 176.722 73.3808 176.942C73.6631 176.998 73.9408 177.059 74.2184 177.139C74.4913 177.223 74.7736 177.303 75.0183 177.476C75.136 177.546 75.2771 177.714 75.1971 177.892C75.1171 178.047 74.976 178.112 74.8536 178.187C74.5901 178.318 74.3172 178.402 74.0396 178.482C72.9244 178.763 71.7856 178.884 70.6516 178.997C69.5129 179.086 68.3694 179.137 67.2307 179.193L70.6422 178.922C71.7762 178.8 72.9103 178.66 74.0113 178.37C74.2843 178.299 74.5572 178.22 74.8066 178.094C74.9242 178.028 75.0513 177.958 75.1077 177.85C75.1595 177.747 75.0795 177.63 74.9666 177.555C74.7454 177.396 74.4678 177.307 74.1996 177.223C73.9267 177.139 73.649 177.078 73.3714 177.017C72.2562 176.793 71.1222 176.657 69.9881 176.535H69.9787Z"
                  fill="white"
                />
                <path
                  d="M63.6074 176.287C64.4121 176.263 65.2214 176.282 66.0261 176.296C66.8307 176.325 67.64 176.353 68.44 176.424C67.6306 176.452 66.826 176.428 66.0213 176.414C65.2167 176.386 64.4121 176.358 63.6074 176.287Z"
                  fill="white"
                />
                <path
                  d="M54.8268 176.807C55.3585 176.704 55.8996 176.643 56.4408 176.577C56.9819 176.526 57.523 176.474 58.0642 176.465C57.5277 176.568 56.9913 176.629 56.4502 176.694C55.909 176.746 55.3726 176.798 54.8268 176.807Z"
                  fill="white"
                />
                <path
                  d="M63.6074 179.263C62.6192 179.259 61.6358 179.245 60.6476 179.217C59.6595 179.189 58.6713 179.203 57.6879 179.086C56.7044 178.978 55.721 178.898 54.7422 178.772C54.2528 178.693 53.7682 178.604 53.2882 178.477C53.0482 178.412 52.813 178.337 52.5871 178.239C52.4742 178.192 52.3612 178.136 52.2577 178.065C52.1589 177.995 52.0554 177.902 52.0507 177.775C52.0601 177.902 52.1683 177.986 52.2671 178.056C52.3706 178.122 52.4836 178.173 52.5965 178.215C52.8271 178.304 53.0623 178.365 53.3023 178.421C53.7823 178.538 54.267 178.622 54.7516 178.693C55.7304 178.805 56.7138 178.875 57.6973 178.969C58.676 179.1 59.6642 179.1 60.6476 179.142C61.6358 179.175 62.6192 179.217 63.6074 179.263Z"
                  fill="white"
                />
                <path
                  d="M64.4862 44.9856H179.592C179.592 44.9856 177.889 41.3634 175.55 39.5476C173.207 37.7365 169.795 39.5476 169.795 39.5476C169.795 39.5476 170.172 36.3139 168.125 34.5028C166.078 32.6917 163.744 33.6183 163.744 33.6183C163.744 33.6183 165.405 25.7982 159.796 21.006C153.463 15.6008 147.938 19.925 147.938 19.925C147.938 19.925 148.484 17.3698 146.701 15.7225C144.917 14.0752 142.96 15.1656 142.96 15.1656C142.96 15.1656 140.998 2.08069 130.631 0.22747C118.99 -1.85507 114.082 11.0473 114.082 11.0473C114.082 11.0473 110.341 6.16153 105.245 8.04751C100.77 9.70418 100.714 16.6491 100.714 16.6491C100.714 16.6491 97.9894 14.6274 95.7448 15.9471C93.5003 17.2668 93.5897 20.1075 93.5897 20.1075C93.5897 20.1075 89.4442 18.1513 84.8092 20.7253C80.1743 23.2992 81.1154 29.018 81.1154 29.018C81.1154 29.018 78.5509 28.4891 76.9652 30.0007C75.3794 31.5123 75.9488 33.6978 75.9488 33.6978C75.9488 33.6978 73.3184 32.949 70.975 34.5683C68.6317 36.1875 69.0223 40.624 69.0223 40.624C69.0223 40.624 65.8037 40.8065 64.4814 44.9903L64.4862 44.9856Z"
                  fill="#DADADC"
                />
                <path
                  d="M132.137 57.902C129.554 57.902 129.7 54.9584 130.89 52.0194C131.276 51.0741 131.53 50.2739 132.137 49.0197C132.744 50.2739 132.998 51.0741 133.38 52.0194C134.57 54.9584 134.716 57.902 132.137 57.902Z"
                  fill="#DADADC"
                />
                <path
                  d="M141.346 90.8716C138.763 90.8716 138.908 87.928 140.099 84.989C140.485 84.0437 140.739 83.2435 141.346 81.9893C141.953 83.2435 142.207 84.0437 142.588 84.989C143.779 87.928 143.924 90.8716 141.346 90.8716Z"
                  fill="#DADADC"
                />
                <path
                  d="M158.86 68.6843C156.276 68.6843 156.422 65.7406 157.613 62.8017C157.999 61.8563 158.253 61.0561 158.86 59.8019C159.467 61.0561 159.721 61.8563 160.102 62.8017C161.292 65.7406 161.438 68.6843 158.86 68.6843Z"
                  fill="#DADADC"
                />
                <path
                  d="M172.755 109.928C170.172 109.928 170.318 106.984 171.508 104.045C171.894 103.1 172.148 102.3 172.755 101.045C173.362 102.3 173.616 103.1 173.998 104.045C175.188 106.984 175.334 109.928 172.755 109.928Z"
                  fill="#DADADC"
                />
                <path
                  d="M102.479 70.8324C99.8952 70.8324 100.041 67.8888 101.232 64.9499C101.617 64.0045 101.872 63.2043 102.479 61.9501C103.086 63.2043 103.34 64.0045 103.721 64.9499C104.911 67.8888 105.057 70.8324 102.479 70.8324Z"
                  fill="#DADADC"
                />
                <path
                  d="M71.328 56.1098C68.7447 56.1098 68.8906 53.1662 70.0811 50.2272C70.4669 49.2819 70.721 48.4816 71.328 47.2274C71.935 48.4816 72.1891 49.2819 72.5703 50.2272C73.7608 53.1662 73.9066 56.1098 71.328 56.1098Z"
                  fill="#DADADC"
                />
                <path
                  d="M53.1694 94.3816C50.5861 94.3816 50.732 91.438 51.9225 88.4991C52.3083 87.5537 52.5624 86.7535 53.1694 85.4993C53.7765 86.7535 54.0306 87.5537 54.4117 88.4991C55.6022 91.438 55.7481 94.3816 53.1694 94.3816Z"
                  fill="#DADADC"
                />
              </svg>
            </Box>

            <Box>
              <Heading
                textAlign={"center"}
                as={"h6"}
                mt={5}
                mb={5}
                fontSize={"24px"}
              >
                <Trans>Please wait, something went wrong</Trans>
              </Heading>

              <Text textAlign={"center"}>
                <Trans>We couldn't find your email. Please try again</Trans>
              </Text>
            </Box>
          </Box>

          <Button
            mt="10"
            w={"100%"}
            h={"57px"}
            borderRadius={"8px"}
            onClick={onClose}
            _hover={{ backgroundColor: "primary.400" }}
            color={"shades.white"}
            backgroundColor={"primary.300"}
            variant="primary"
          >
            <Text fontSize={16}>
              <Trans>Got it</Trans>
            </Text>
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
