import Hotjar from "@hotjar/browser";

import { config } from "@/config";
import { initializeHeapAnalytics } from "@/services/heap";

const initializeHotjar = (): void => {
  const hjid = Number(config.VITE_HOTJAR_ID);
  const hjsv = Number(config.VITE_HOTJAR_VERSION);
  Hotjar.init(hjid, hjsv);
};

const initializeServices = (): void => {
  initializeHotjar();
  initializeHeapAnalytics();
};

export { initializeServices };
