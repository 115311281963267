import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { activeStyles, useIsCurrentPathActive } from "./utils";

interface NavigationPopoverButtonProps {
  title: string;
  baseRoutes: string[];
  isMenuExpanded: boolean;
  icon: React.ReactNode;
  CustomPopoverContent: React.ReactNode;
  AccordionContent: React.ReactNode;
  onClick?: () => void;
}

const NavigationPopoverButton: React.FC<NavigationPopoverButtonProps> = ({
  title,
  baseRoutes,
  icon,
  isMenuExpanded,
  CustomPopoverContent,
  AccordionContent,
  onClick,
}) => {
  return (
    <AccordionItem
      w={!isMenuExpanded ? "36px !important" : "auto"}
      h={!isMenuExpanded ? "36px !important" : "auto"}
      border={"none"}
    >
      <Popover placement="right-start" trigger="hover">
        <PopoverTrigger>
          <Flex
            as={AccordionButton}
            {...(useIsCurrentPathActive(baseRoutes) && activeStyles.button)}
            w={"100%"}
            justify={isMenuExpanded ? "space-between" : "center"}
            align={"center"}
            p={"8px"}
            gap={"8px"}
            cursor={"pointer"}
            _hover={{ bg: "neutral.50" }}
            borderRadius={"6px"}
            onClick={onClick}
          >
            <span style={{ fontSize: "20px" }}>{icon}</span>

            {isMenuExpanded && (
              <>
                <Text variant={"caption"} flex={1} textAlign={"start"}>
                  {title}
                </Text>

                <AccordionIcon color={"secondary.300"} />
              </>
            )}
          </Flex>
        </PopoverTrigger>

        <PopoverContent
          w={"max-content"}
          display={isMenuExpanded ? "none" : "auto"}
          bg={"neutral.50"}
        >
          <PopoverArrow bg={"neutral.50"} />
          {CustomPopoverContent}
        </PopoverContent>
      </Popover>

      {isMenuExpanded && (
        <AccordionPanel p={0} w={"100%"}>
          {AccordionContent}
        </AccordionPanel>
      )}
    </AccordionItem>
  );
};

export default NavigationPopoverButton;
