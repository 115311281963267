import { colors } from "@/components/Theme/colors";
import { Divider, Flex } from "@chakra-ui/react";
import { Fragment } from "react";
import { InfoCard } from "./infoCard/infoCard";
import { InfoGridProps } from "./utils";

export const InfoGrid = ({ gridData }: InfoGridProps) => {
  return (
    <Flex
      gap={{ base: 4, md: 12 }}
      px={{ base: 4, md: 12 }}
      py={4}
      flexDir={{ base: "column", md: "row" }}
      w={"full"}
      justifyContent={"space-evenly"}
      border={`1px solid ${colors.neutral[100]}`}
      borderRadius={"6px"}
    >
      {gridData?.map((card, index) => (
        <Fragment key={index}>
          <InfoCard icon={card.icon} text={card.text} bgColor={card.bgColor} />
          {index + 1 < gridData.length && (
            <Divider
              h={"auto"}
              borderColor={colors.neutral[100]}
              color={colors.neutral[100]}
              borderLeftWidth={{ base: "0px", md: "1px" }}
              borderBottom={{ base: "1px", md: "0px" }}
              w={{ base: "full", md: "1px" }}
            />
          )}
        </Fragment>
      ))}
    </Flex>
  );
};
