/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Language } from "../locales/language";
// Esta es la lógica que hace que el lenguage por defecto sea español
const langString = (lang?: string): Language => {
  if (lang?.toLowerCase().includes("pt")) {
    return Language.ES;
  }

  if (lang?.toLowerCase().includes("en")) {
    return Language.ES;
  }

  return Language.ES;
};

const langIcon = (lang?: string): string => {
  if (lang?.toLowerCase().includes("pt")) {
    return `🇵🇹`;
  }

  if (lang?.toLowerCase().includes("en")) {
    return `🇬🇧`;
  }

  return `🇪🇸`;
};

export const LanguageChooser: React.FC = () => {
  const { i18n } = useTranslation();
  const { onClose, isOpen, onOpen } = useDisclosure();
  const buttonColor = useColorModeValue("secondary.300", "shades.100");
  const buttonHoverColor = useColorModeValue("secondary.400", "white");

  const handleLanguageChange = (languageCode: Language) => () => {
    onClose();
    i18n.changeLanguage(languageCode);
  };

  return (
    <Menu closeOnBlur isOpen={isOpen} onClose={onClose}>
      <MenuButton
        _focus={{ border: "none", outline: "none" }}
        _hover={{ color: buttonHoverColor }}
        textTransform="uppercase"
        mx="1"
        color={buttonColor}
        fontWeight="bold"
        w="50px"
        fontSize="1.1rem"
        onClick={onOpen}
      >
        <Flex gap="2" align="center">
          {langIcon(i18n.language)} {langString(i18n.language)}
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuOptionGroup defaultValue={langString(i18n.language)} type="radio">
          <MenuItemOption
            value={Language.ES}
            onClick={handleLanguageChange(Language.ES)}
          >
            {langIcon(Language.ES)} Español
          </MenuItemOption>
          <MenuItemOption
            value={Language.EN}
            onClick={handleLanguageChange(Language.EN)}
          >
            {langIcon(Language.EN)} English
          </MenuItemOption>
          <MenuItemOption
            value={Language.PT}
            onClick={handleLanguageChange(Language.PT)}
          >
            {langIcon(Language.PT)} Português
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
