import { Ico } from "@/assets/icons";
import {
  Box,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { Trans } from "react-i18next";

interface InputChatProps {
  onSendMessage: (message: string) => void;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

const InputChat: React.FC<InputChatProps> = ({
  onSendMessage,
  placeholder,
  value,
  onChange,
}) => {
  const characterCount = value.length;
  const isOverLimit = characterCount > 200;

  const getCharacterCountColor = () => {
    if (characterCount >= 200) {
      return "#ED3F3F";
    } else if (characterCount >= 136 && characterCount < 200) {
      return "#EEBE15";
    } else {
      return "neutral.200";
    }
  };

  const characterCountColor = getCharacterCountColor();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      characterCount >= 1 &&
      characterCount <= 200
    ) {
      event.preventDefault();
      event.stopPropagation();
      onSendMessage(value.trim());
    }
  };

  return (
    <Box>
      <InputGroup>
        <Input
          pr="4.5rem"
          type={"text"}
          borderColor={"#45454D"}
          placeholder={placeholder}
          backgroundColor={"#303036"}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={handleKeyDown}
          color={"neutral.50"}
          _focus={{
            border: "2px solid shades.white",
            boxShadow: "none",
          }}
        />
        <InputRightElement>
          <IconButton
            style={{ backgroundOrigin: "border-box" }}
            bgGradient="linear(to-t, #E5E5E9, #6A6A7A)"
            aria-label="Send message"
            w={"24px"}
            h={"24px"}
            fontSize="22px"
            icon={<Ico.Send />}
            onClick={() => onSendMessage(value.trim())}
            isDisabled={characterCount < 1 || characterCount > 200}
          />
        </InputRightElement>
      </InputGroup>
      <Flex bottom="20px" alignItems={"baseline"} justify={"space-between"}>
        <Box>
          {isOverLimit && (
            <Box>
              <Text fontSize={"12px"} color="#ED3F3F">
                <Trans>You are exceeding the character limit</Trans>
              </Text>
            </Box>
          )}
          {!isOverLimit && characterCount === 200 && (
            <Box>
              <Text fontSize={"12px"} color="#ED3F3F">
                <Trans>You have reached the character limit</Trans>
              </Text>
            </Box>
          )}
        </Box>
        <Box mt="10px">
          <Text fontSize={"12px"} as="span" color={characterCountColor}>
            {characterCount > 200 ? `-${characterCount - 200}` : characterCount}
          </Text>
          <Text fontSize={"12px"} as="span" color="neutral.200">
            /200
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
export default InputChat;
