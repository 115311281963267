import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import { Button } from "@/components/Button";
import { colors } from "@/components/Theme/colors";
import { GetActiveMentorshipForUserDocument } from "@/pages/HighTicketMentorship/gatewayLayer/graphql/getActiveMentorshipForUser.generated";
import { useUser } from "@/providers/useUser";
import { HighTicketMentorship } from "@/schemaTypes";
import { noCacheHeaders } from "@/utils/headers";
import { useLazyQuery } from "@apollo/client";
import { Box, Flex, Progress, Text, Avatar } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toInt } from "validator";

interface HighTicketWidgetProps {
  isMenuExpanded?: boolean;
}

export const HighTicketNavigationWidget: React.FC<HighTicketWidgetProps> = ({
  isMenuExpanded,
}: HighTicketWidgetProps) => {
  const { user } = useUser();
  const [activeMentorship, setActiveMentorship] = useState<
    HighTicketMentorship | null | undefined
  >();
  const [progressBar, setProgressBar] = useState<number>(0);
  const [getActiveMentorship] = useLazyQuery(
    GetActiveMentorshipForUserDocument,
    {
      variables: {
        userId: user?.id ?? "",
      },
      onCompleted: (data) => {
        setActiveMentorship(
          data.getActiveMentorshipForUser as HighTicketMentorship
        );
      },
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );
  const navigate = useNavigate();
  const openMentorship = (id: string) => {
    navigate(`${AppRoute.HighTicketMentorship}/${id}`);
  };
  useEffect(() => {
    if (activeMentorship) {
      setProgressBar(
        ((toInt(activeMentorship.duration) -
          toInt(activeMentorship.remainingDays ?? "0")) /
          toInt(activeMentorship.duration)) *
          100
      );
    }
  }, [activeMentorship]);
  useEffect(() => {
    getActiveMentorship();
  }, [getActiveMentorship]);
  return (
    activeMentorship && (
      <Box
        w={"100%"}
        h={"215px"}
        bg={isMenuExpanded ? "neutral.50" : "transparent"}
        mb={"48px"}
        borderRadius={"6px"}
      >
        {isMenuExpanded && (
          <Flex
            padding={"12px 12px 16px 12px"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            gap={2}
            alignSelf={"stretch"}
            borderRadius={"6px"}
            background={"neutral.50"}
          >
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              gap={"3px"}
              alignSelf={"stretch"}
            >
              <Flex flexDirection={"column"}>
                <Text
                  fontFamily={"inter"}
                  fontSize={"12px"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  lineHeight={"22.4px"}
                  color={"secondary.300"}
                  textTransform={"uppercase"}
                  letterSpacing={"1px"}
                >
                  Mentoría VIP
                </Text>
                <Text
                  fontFamily={"inter"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  lineHeight={"22.4px"}
                  color={"secondary.300"}
                >
                  {activeMentorship.title}
                </Text>
              </Flex>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={4}
              alignSelf={"stretch"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={2}
                alignSelf={"stretch"}
              >
                <Flex alignItems={"center"} gap={2} alignSelf={"stretch"}>
                  <Box position={"relative"}>
                    <Avatar
                      w={"48px"}
                      h={"48px"}
                      src={activeMentorship.mentor?.avatar ?? ""}
                    ></Avatar>
                    <Box position={"absolute"} bottom={0} right={0}>
                      <Ico.GeneralCheck fontSize={"16px"}></Ico.GeneralCheck>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    flex={"1 0 0"}
                  >
                    <Flex alignItems={"center"} gap={"6px"}>
                      <Text
                        fontSize={"12px"}
                        fontWeight={500}
                        fontFamily={"Inter"}
                        fontStyle={"normal"}
                        lineHeight={"19.2px"}
                        color={"secondary.300"}
                      >
                        {"Mentor"}
                      </Text>
                    </Flex>
                    <Text
                      fontFamily={"Inter"}
                      fontStyle={"normal"}
                      fontSize={"12px"}
                      fontWeight={700}
                      lineHeight={"19.2px"}
                      color={"secondary.300"}
                    >
                      {activeMentorship.mentor?.name}
                    </Text>
                  </Box>
                </Flex>
                <Flex
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  gap={1}
                  alignSelf={"stretch"}
                >
                  <Progress
                    value={progressBar}
                    style={{ backgroundColor: colors.neutral[100] }}
                    height="4px"
                    borderRadius="0.5rem"
                    w="100%"
                    sx={{
                      "& > div": {
                        backgroundColor: colors.secondary[300],
                      },
                    }}
                  />
                  <Text
                    fontFamily={"Inter"}
                    fontStyle={"normal"}
                    fontSize={"11px"}
                    fontWeight={400}
                    lineHeight={"19.2px"}
                    color={"secondary.300"}
                  >
                    Te quedan {activeMentorship.remainingDays} días incluídos de
                    tu mentoría privada.{" "}
                  </Text>
                </Flex>
              </Box>

              <Button
                cursor={"pointer"}
                fontFamily={"Inter"}
                variant={"link"}
                fontSize={"14px"}
                py={0}
                onClick={() => openMentorship(activeMentorship.id)}
              >
                Ver tu programa
              </Button>
            </Box>
          </Flex>
        )}
        {!isMenuExpanded && (
          <Box
            cursor={"pointer"}
            onClick={() => openMentorship(activeMentorship.id)}
            bg={"neutral.50"}
            h={"max-content"}
            p={"12px 6px"}
          >
            <Ico.YourMentor
              color={colors.primary[200]}
              fontSize={"20px"}
            ></Ico.YourMentor>
          </Box>
        )}
      </Box>
    )
  );
};
