import { LiveSession, LiveSessionAuth, MasterClass } from "@/schemaTypes";

export type LiveContentType = LiveSession | MasterClass;

export type AuthLiveSession = LiveSessionAuth | undefined;

export type LiveContentTypeOrUndefined = LiveContentType | undefined;

export enum DISPATCH_TYPE {
  SET_SCREEN_SHARE_ACTIVE = "SET_SCREEN_SHARE_ACTIVE",
  SET_GRID_CAMERA = "SET_GRID_CAMERA",
  CONNECT_CLIENT = "CONNECT_CLIENT",
  DISCONNECT_CLIENT = "DISCONNECT_CLIENT",
  SET_IS_VIDEO_ACTIVE = "SET_IS_VIDEO_ACTIVE",
  AUDIO_ENCODE = "audio-encode",
  AUDIO_DECODE = "audio-decode",
  VIDEO_ENCODE = "video-encode",
  VIDEO_DECODE = "video-decode",
  SHARE_ENCODE = "share-encode",
  SHARE_DECODE = "share-decode",
  RESET_MEDIA = "reset-media",
  SET_IS_OTHER_USER_VIDEO_ACTIVE = "SET_IS_OTHER_USER_VIDEO_ACTIVE",
}

export enum EVENTS {
  USER_ADDED = "user-added",
  USER_REMOVED = "user-removed",
  USER_UPDATED = "user-updated",
  ACTIVE_SHARE_CHANGE = "active-share-change",
  PEER_VIDEO_STATE_CHANGE = "peer-video-state-change",
  PASSIVELY_STOP_SHARE = "passively-stop-share",
  HOST_CHANGED = "host-changed",
  VIDEO_ACTIVE_CHANGE = "video-active-change",
  CONNECT_CHANGE = "connection-change",
}
