import React, { createContext, useReducer } from "react";
import { DISPATCH_TYPE } from "../types/LiveBar.type";

interface VideoState {
  isVideoActive: boolean;
  isOtherUserVideoActive: boolean;
  isScreenShareActive: boolean;
  isGridCamera: number;
}

type VideoAction =
  | { type: "SET_IS_VIDEO_ACTIVE"; payload: boolean }
  | { type: "SET_IS_OTHER_USER_VIDEO_ACTIVE"; payload: boolean }
  | { type: "SET_SCREEN_SHARE_ACTIVE"; payload: boolean }
  | { type: "SET_GRID_CAMERA"; payload: number };

const initialState: VideoState = {
  isVideoActive: false,
  isOtherUserVideoActive: false,
  isScreenShareActive: false,
  isGridCamera: 1,
};

export const VideoStateContext = createContext<{
  state: VideoState;
  dispatch: React.Dispatch<VideoAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const videoReducer = (state: VideoState, action: VideoAction): VideoState => {
  switch (action.type) {
    case DISPATCH_TYPE.SET_IS_VIDEO_ACTIVE:
      return { ...state, isVideoActive: action.payload };
    case DISPATCH_TYPE.SET_IS_OTHER_USER_VIDEO_ACTIVE:
      return { ...state, isOtherUserVideoActive: action.payload };
    case DISPATCH_TYPE.SET_SCREEN_SHARE_ACTIVE:
      return { ...state, isScreenShareActive: action.payload };
    case DISPATCH_TYPE.SET_GRID_CAMERA:
      return { ...state, isGridCamera: action.payload };
    default:
      return state;
  }
};

export const VideoStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(videoReducer, initialState);

  return (
    <VideoStateContext.Provider value={{ state, dispatch }}>
      {children}
    </VideoStateContext.Provider>
  );
};
