import { Flex, Text } from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import { ValidateBootcampEnrrollments } from "@/schemaTypes";
import { ModalHeaderProps } from "./utils";

export const ModalHeader = ({ data }: ModalHeaderProps) => {
  const getIcon = () => {
    switch (data?.type) {
      case ValidateBootcampEnrrollments.Blockenroll:
        return <Ico.Warning />;
      case ValidateBootcampEnrrollments.Confirmblockenroll:
        return <Ico.CircleCheck />;
      default:
        return null;
    }
  };

  const iconColor =
    data?.type === ValidateBootcampEnrrollments.Blockenroll
      ? "warning.300"
      : "success.bcModal";

  return (
    <Flex flexDirection="column" gap={2} alignItems="center">
      {getIcon() && (
        <Flex fontSize="32px" color={iconColor} justifyContent="center">
          {getIcon()}
        </Flex>
      )}
      {data?.title && (
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color="secondary.300"
          fontWeight="600"
          textAlign="center"
        >
          {data.title}
        </Text>
      )}
    </Flex>
  );
};
