import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { t } from "i18next";

import { IconControlsSharedScreen, TypeIcon } from "../IconsControls";
import ZoomClientContext from "@/components/LiveSessionsZoom/context/zoom-context";
import ZoomMediaContext from "@/components/LiveSessionsZoom/context/media-context";
import { isSupportWebCodecs } from "@/components/LiveSessionsZoom/utils/platform";
import { PassiveStopShareReason } from "@zoom/videosdk";
import { useVideoState } from "@/components/LiveSessionsZoom/context/videoState/useVideoState";
import { EVENTS } from "@/components/LiveSessionsZoom/types/LiveBar.type";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { UserRole } from "@/schemaTypes";
import { useUser } from "@/providers/useUser";

enum ErrorType {
  INVALID_OPERATION = "INVALID_OPERATION",
}

enum ErrorReason {
  USER_DENY_SCREEN_SHARE = "user deny screen share",
}

interface CustomError {
  type: ErrorType;
  reason?: ErrorReason;
}

export const ScreenShared = () => {
  const { hasRoles } = useUser();
  const isMentor = hasRoles([UserRole.Mentor]);
  const { zmClient } = useContext(ZoomClientContext);
  const zoomMediaContext = useContext(ZoomMediaContext);
  const [isStartedScreen, setIsStartedScreen] = useState(false);
  const toast = useToast();
  const MEDIA_STREAM = zmClient?.getMediaStream();
  const { dispatch } = useVideoState();

  const handleError = (error: CustomError) => {
    switch (error.type) {
      case ErrorType.INVALID_OPERATION:
        if (error.reason === ErrorReason.USER_DENY_SCREEN_SHARE) {
          toast({
            status: "info",
            isClosable: true,
            duration: 2000,
            description: t("Screen sharing has been cancelled."),
          });
        } else {
          SBErrorPubSub.publish({
            component: "ScreenShared.tsx",
            message: t(
              "A failure occurred while sharing or stopping the screen."
            ),
            showInProd: false,
          });
        }
        break;
      default:
        SBErrorPubSub.publish({
          component: "ScreenShared.tsx",
          message: `${t("Unknown error:")} ${JSON.stringify(error)}`,
          showInProd: false,
        });
    }
  };

  const screenSharedMainScene = async () => {
    if (isMentor) {
      const screenSharedElement = document.querySelector<HTMLCanvasElement>(
        `#my-screen-share-content-video`
      );
      const screenSharedElementCanvas =
        document.querySelector<HTMLCanvasElement>(
          `#my-screen-share-content-video-canvas`
        );

      try {
        if (MEDIA_STREAM) {
          if (screenSharedElement && screenSharedElementCanvas) {
            await MEDIA_STREAM.startShareScreen(
              !isSupportWebCodecs()
                ? screenSharedElementCanvas
                : screenSharedElement,
              {
                controls: {
                  systemAudio: "exclude",
                },
              }
            );

            screenSharedElement.style.display = isSupportWebCodecs()
              ? "block"
              : "none";
            screenSharedElementCanvas.style.display = isSupportWebCodecs()
              ? "none"
              : "block";
          }
        }

        setIsStartedScreen(true);
        dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: true });
      } catch (error) {
        setIsStartedScreen(false);
        handleError(error as CustomError);
      }
    }
  };

  const onScreenHandle = useCallback(async () => {
    if (isMentor) {
      if (zmClient) {
        if (isStartedScreen) {
          const mediaStream = zmClient.getMediaStream();
          if (mediaStream) {
            await mediaStream.stopShareScreen().then(async () => {
              setIsStartedScreen(false);
              dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: false });
            });
          }
        } else {
          screenSharedMainScene();
        }
      }
    }
  }, [
    isStartedScreen,
    zoomMediaContext?.mediaContext?.mediaStream,
    zmClient,
    isMentor,
  ]);

  useEffect(() => {
    if (!zmClient) return;

    const handlePassivelyStopShare = async (payload: {
      reason: PassiveStopShareReason;
    }) => {
      setIsStartedScreen(false);

      if (payload.reason === PassiveStopShareReason.StopScreenCapture) {
        const mediaStream = zmClient.getMediaStream();
        if (mediaStream) {
          try {
            await mediaStream.stopShareScreen().then(async () => {
              setIsStartedScreen(false);
              dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: false });
            });
          } catch (error) {
            toast({
              title: "Error",
              description: "No se pudo detener la pantalla compartida",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }
      }
    };

    zmClient.on(EVENTS.PASSIVELY_STOP_SHARE, handlePassivelyStopShare);

    return () => {
      zmClient.off(EVENTS.PASSIVELY_STOP_SHARE, handlePassivelyStopShare);
    };
  }, [zmClient]);

  return (
    <Box>
      <Flex
        onClick={onScreenHandle}
        cursor={"pointer"}
        w={"115px"}
        maxW={"115px"}
        userSelect={"none"}
        justify={"center"}
        align={"center"}
        flexDir={"column"}
      >
        <IconControlsSharedScreen
          type={!isStartedScreen ? TypeIcon.SHARED_ON : TypeIcon.SHARED_OFF}
        />
        <Text
          color={isStartedScreen ? "#00E324" : "#FF4848"}
          fontSize={"10px"}
          lineHeight={"19.2px"}
        >
          {t(!isStartedScreen ? "Shared" : "Stop sharing")}
        </Text>
      </Flex>
    </Box>
  );
};
