import { Button, Flex, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import type { HeaderMenuButtonProps } from "./types";
import { useState } from "react";

const HeaderMenuButton = ({
  label,
  ...props
}: HeaderMenuButtonProps): JSX.Element => {
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flex
      alignItems={"center"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Button
        _hover={{ color: "primary.300" }}
        bgColor={"transparent !important"}
        border={"none"}
        color={"inherit"}
        fontWeight={400}
        justifyContent={"flex-start"}
        rounded={"8px"}
        size={"sm"}
        variant={"ghost"}
        w={"100%"}
        {...props}
      >
        {t(label)}
      </Button>
      <Box
        bgColor={isHovered ? "primary.300" : "transparent"}
        h={"24px"}
        roundedLeft={"4px"}
        w={"4px"}
      />
    </Flex>
  );
};

export { HeaderMenuButton };
