import { Dispatch, SetStateAction, createContext } from "react";
import { MediaStream } from "../types/zoom";
import { DISPATCH_TYPE } from "../types/LiveBar.type";
interface MediaContext {
  audio: {
    encode: boolean;
    decode: boolean;
  };
  video: {
    encode: boolean;
    decode: boolean;
  };
  share: {
    encode: boolean;
    decode: boolean;
  };
  mediaStream: MediaStream | null;
  isVideoActive: boolean;
  isOtherUserVideoActive: boolean;
  setIsVideoActive: Dispatch<SetStateAction<boolean>>;
  setIsOtherUserVideoActive: Dispatch<SetStateAction<boolean>>;
}

export type MediaActionType =
  | { type: DISPATCH_TYPE.AUDIO_ENCODE | string; payload: boolean }
  | { type: DISPATCH_TYPE.AUDIO_DECODE | string; payload: boolean }
  | { type: DISPATCH_TYPE.VIDEO_ENCODE | string; payload: boolean }
  | { type: DISPATCH_TYPE.VIDEO_DECODE | string; payload: boolean }
  | { type: DISPATCH_TYPE.SHARE_ENCODE; payload: boolean }
  | { type: DISPATCH_TYPE.SHARE_DECODE; payload: boolean }
  | { type: DISPATCH_TYPE.RESET_MEDIA }
  | { type: DISPATCH_TYPE.SET_IS_VIDEO_ACTIVE; payload: boolean }
  | { type: DISPATCH_TYPE.SET_IS_OTHER_USER_VIDEO_ACTIVE; payload: boolean };

interface MediaContextValue {
  mediaContext: MediaContext;
  loadingZoom: boolean;
  loadingText: string;
  statusZoom: string;
  dispatch: Dispatch<MediaActionType>;
}

const ZoomMediaContext = createContext<MediaContextValue | undefined>(
  undefined
);
export default ZoomMediaContext;
