import { produce } from "immer";
import { MediaActionType } from "../media-context";
import { DISPATCH_TYPE } from "../../types/LiveBar.type";

export const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
  isVideoActive: false,
  isOtherUserVideoActive: false,
};

export const mediaReducer = produce((draft, action: MediaActionType) => {
  switch (action.type) {
    case DISPATCH_TYPE.AUDIO_ENCODE: {
      draft.audio.encode = action.payload;
      break;
    }
    case DISPATCH_TYPE.AUDIO_DECODE: {
      draft.audio.decode = action.payload;
      break;
    }
    case DISPATCH_TYPE.VIDEO_ENCODE: {
      draft.video.encode = action.payload;
      break;
    }
    case DISPATCH_TYPE.VIDEO_DECODE: {
      draft.video.decode = action.payload;
      break;
    }
    case DISPATCH_TYPE.SHARE_ENCODE: {
      draft.share.encode = action.payload;
      break;
    }
    case DISPATCH_TYPE.SHARE_DECODE: {
      draft.share.decode = action.payload;
      break;
    }
    case DISPATCH_TYPE.RESET_MEDIA: {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    case DISPATCH_TYPE.SET_IS_VIDEO_ACTIVE: {
      draft.isVideoActive = action.payload;
      break;
    }
    case DISPATCH_TYPE.SET_IS_OTHER_USER_VIDEO_ACTIVE: {
      draft.isOtherUserVideoActive = action.payload;
      break;
    }
    default:
      break;
  }
}, mediaShape);
