import * as Types from '../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetGamificationByUserIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type GetGamificationByUserIdQuery = { __typename?: 'Query', getGamificationByUserId?: { __typename?: 'Gamification', userId?: string | null, stars?: number | null, range?: string | null, eventRecords?: Array<{ __typename?: 'EventDataGamification', idEvent?: string | null, eventType?: string | null, rule?: string | null, nameEvent?: string | null, awardedStars?: number | null, previousStars?: number | null, previousRange?: string | null, registerDate?: Date | null } | null> | null } | null };


export const GetGamificationByUserIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetGamificationByUserId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getGamificationByUserId"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userId"}},{"kind":"Field","name":{"kind":"Name","value":"stars"}},{"kind":"Field","name":{"kind":"Name","value":"range"}},{"kind":"Field","name":{"kind":"Name","value":"eventRecords"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"idEvent"}},{"kind":"Field","name":{"kind":"Name","value":"eventType"}},{"kind":"Field","name":{"kind":"Name","value":"rule"}},{"kind":"Field","name":{"kind":"Name","value":"nameEvent"}},{"kind":"Field","name":{"kind":"Name","value":"awardedStars"}},{"kind":"Field","name":{"kind":"Name","value":"previousStars"}},{"kind":"Field","name":{"kind":"Name","value":"previousRange"}},{"kind":"Field","name":{"kind":"Name","value":"registerDate"}}]}}]}}]}}]} as unknown as DocumentNode<GetGamificationByUserIdQuery, GetGamificationByUserIdQueryVariables>;