/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormControl, FormLabel, Text } from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerField.css";

interface DatePickerProps {
  value?: Date | null;
  onChange?: (date: Date, isValid?: boolean) => void;
  isRequired?: boolean;
  label?: string;
  legend?: React.ReactNode;
  showTimeSelect?: boolean;
  isDisabled?: boolean;
}

export const DatePickerField: React.FC<DatePickerProps> = ({
  value,
  onChange,
  isRequired,
  label,
  legend,
  showTimeSelect = false,
  isDisabled = false,
}) => {
  const initialValue =
    typeof value === "string" ? DateTime.fromISO(value).toJSDate() : value;
  const isInvalid = initialValue === null;

  const handleChange = (date: Date) => {
    if (onChange) {
      onChange(date, !isInvalid);
    }
  };

  return (
    <FormControl
      id="country"
      isRequired={isRequired}
      pb="3"
      isDisabled={isDisabled}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <ReactDatePicker
        disabled={isDisabled}
        showTimeSelect={showTimeSelect}
        className={isInvalid ? "invalid" : ""}
        selected={initialValue}
        onChange={handleChange}
        dateFormat={showTimeSelect ? "Pp" : undefined}
      />
      {legend && (
        <Text fontSize="sm" color="gray.500" mt="1" ml="1" fontStyle="italic">
          {legend}
        </Text>
      )}
    </FormControl>
  );
};
