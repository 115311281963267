import * as Types from '../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateMasterClassMutationVariables = Types.Exact<{
  updateMasterClassInput: Types.UpdateMasterClassInput;
}>;


export type UpdateMasterClassMutation = { __typename?: 'Mutation', updateMasterClass: string };


export const UpdateMasterClassDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateMasterClass"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"updateMasterClassInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateMasterClassInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateMasterClass"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"updateMasterClassInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"updateMasterClassInput"}}}]}]}}]} as unknown as DocumentNode<UpdateMasterClassMutation, UpdateMasterClassMutationVariables>;