import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";

import { GetUserSettingsDocument } from "./graphql/getUserSettings.generated";
import { UpdateUserSettingsDocument } from "./graphql/updateUserSettings.generated";
import { useUser } from "@/providers/useUser";
import { UserSettingsContext, UserSettingsValue } from "./Context";

interface UserSettingsProviderProps {
  children: React.ReactNode;
}

const UserSettingsProvider = ({
  children,
}: UserSettingsProviderProps): JSX.Element => {
  const [settings, setSettings] = useState<UserSettingsValue>();

  const { user, geolocation } = useUser();

  const [getUserSettings] = useLazyQuery(GetUserSettingsDocument);
  const [updateUserSettings] = useMutation(UpdateUserSettingsDocument);

  useEffect(() => {
    if (!user?.id) return;
    getUserSettings({ variables: { userId: user.id } }).then(({ data }) => {
      setSettings(data?.userSettings ?? {});
    });
  }, [getUserSettings, user?.id]);

  const update = useCallback(
    (newVal: UserSettingsValue) => {
      setSettings((val) => ({ ...val, ...newVal }));
      if (!user?.id) return;
      updateUserSettings({
        variables: {
          input: { ...newVal, userId: user.id },
        },
      });
    },
    [user?.id, updateUserSettings]
  );

  useEffect(() => {
    if (settings === undefined) return;
    if (!settings.timeZone) {
      update({ timeZone: geolocation.location });
    }
  }, [settings, geolocation, update]);

  return (
    <UserSettingsContext.Provider value={{ settings, update }}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export { UserSettingsProvider };
