import { useContext } from "react";
import { PresenterState, PresenterContext } from "./context";

const usePresenter = (): PresenterState => {
  const ctx = useContext(PresenterContext);
  if (ctx === null) {
    throw Error("ServiceFilterAndSearch context provider not found");
  }
  return ctx;
};

export { usePresenter };
