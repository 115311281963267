import { useContext } from "react";
import { DataLayerState, DataLayerContext } from "./context";

const useDataLayer = (): DataLayerState => {
  const ctx = useContext(DataLayerContext);
  if (ctx === null) {
    throw Error("ServiceMarketplace context provider not found");
  }
  return ctx;
};

export { useDataLayer };
