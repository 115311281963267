import { Box, Flex, Text } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { IconControlsMicrophone, TypeIcon } from "../IconsControls";
import ZoomMediaContext from "@/components/LiveSessionsZoom/context/media-context";
import ZoomClientContext from "@/components/LiveSessionsZoom/context/zoom-context";
import { Trans } from "react-i18next";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";

export const Microphone = () => {
  const { hasRoles } = useUser();
  const isMentor = hasRoles([UserRole.Mentor]);

  const [isStartedAudio, setIsStartedAudio] = useState(false);
  const { zmClient } = useContext(ZoomClientContext);
  const zoomMediaContext = useContext(ZoomMediaContext);

  const getSelectedDevices = () => {
    const selectedDevices = localStorage.getItem("selectedDevices");
    if (selectedDevices) {
      return JSON.parse(selectedDevices);
    }
    return null;
  };

  const onMicrophoneHandle = useCallback(() => {
    if (isMentor) {
      setIsStartedAudio(!isStartedAudio);
      if (isStartedAudio) {
        zoomMediaContext?.mediaContext?.mediaStream?.startAudio({
          speakerId: getSelectedDevices()?.speaker,
        });
        zoomMediaContext?.mediaContext?.mediaStream?.muteAudio();
      } else {
        zoomMediaContext?.mediaContext?.mediaStream?.unmuteAudio();
      }
    }
  }, [
    isStartedAudio,
    zoomMediaContext?.mediaContext?.mediaStream,
    zmClient,
    isMentor,
  ]);

  useEffect(() => {
    zmClient?.on("user-updated", () => {
      const users = zmClient?.getAllUser();

      if (users) {
        users.forEach((user) => {
          if (user.userId === zmClient?.getCurrentUserInfo().userId) {
            setIsStartedAudio(!user.muted);
          }
        });
      }
    });
  }, [zmClient]);

  return (
    <Box>
      <Flex
        onClick={onMicrophoneHandle}
        cursor={"pointer"}
        w={"115px"}
        maxW={"115px"}
        userSelect={"none"}
        justify={"center"}
        align={"center"}
        flexDir={"column"}
      >
        <IconControlsMicrophone
          type={isStartedAudio ? TypeIcon.MICROFONE_ON : TypeIcon.MICROFONE_OFF}
        />
        <Text
          color={isStartedAudio ? "#00E324" : "#FF4848"}
          fontSize={"10px"}
          lineHeight={"19.2px"}
        >
          <Trans>{isStartedAudio ? "Active" : "Disabled"} Audio</Trans>
        </Text>
      </Flex>
    </Box>
  );
};
