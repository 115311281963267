import { Flex, Tab, TabIndicator, TabList, Tabs, Text } from "@chakra-ui/react";
import { usePresenter } from "../presenter/usePresenter";
import { Category } from "@/schemaTypes";

export const CategoryPills: React.FC = () => {
  const { categoriesForView, selectedIndex, selectMainCategory } =
    usePresenter();
  return (
    <Flex mb={"24px"}>
      {categoriesForView && categoriesForView.length > 0 ? (
        <Tabs index={selectedIndex} maxW={"100%"} overflow={"hidden"}>
          <TabList
            pl={0}
            ml={{ base: "16px", lg: 0 }}
            color={"neutral.300"}
            maxW={"max-content"}
          >
            {categoriesForView.map((tab: Category, index: number) => (
              <Tab
                key={tab.id}
                _selected={{ color: "secondary.300" }}
                _active={{ color: "secondary.300" }}
                pt={0}
                onClick={() => {
                  selectMainCategory(tab.id, index);
                }}
              >
                <Text variant={"caption"}>{tab.name}</Text>
              </Tab>
            ))}
          </TabList>
          <TabIndicator
            mt="-2px"
            height="2px"
            bg="secondary.300"
            borderRadius="8px"
          />
        </Tabs>
      ) : null}
    </Flex>
  );
};
