/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Flex, useColorModeValue, useTheme } from "@chakra-ui/react";
import React from "react";

export const Layout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    colors: { primary, secondary, tertiary, dark, light, gray },
  } = useTheme();
  const bg = useColorModeValue("white", "gray.900");
  const borderColor = useColorModeValue(gray["200"], gray["700"]);

  return (
    <Flex
      style={{
        "--colors-primary-400": primary?.["400"] ?? "gray",
        "--colors-secondary-400": secondary?.["400"] ?? "gray",
        "--colors-tertiary-400": tertiary?.["400"] ?? "gray",
        "--colors-dark-700": dark?.["700"] ?? "gray",
        "--colors-light-100": light?.["100"] ?? "gray",
        "--border-color": borderColor,
      }}
      direction="column"
      as="main"
      bg={bg}
      color={"secondary.400"}
      minHeight="100%"
    >
      {children}
    </Flex>
  );
};
