/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Input, InputGroup, InputProps } from "@chakra-ui/react";
import React from "react";

export const InputStyled: React.FC<Omit<InputProps, "as">> = (props) => {
  return (
    <InputGroup>
      <Input {...props} />
    </InputGroup>
  );
};
