/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Flex, Spinner, SpinnerProps } from "@chakra-ui/react";
import React from "react";

export const BigSpinner: React.FC<SpinnerProps> = ({ size = "lg" }) => (
  <Flex w={"100%"} h={"100%"} justify="center" align="center" p="4">
    <Spinner color="secondary.100" thickness="4px" size={size} />
  </Flex>
);
