/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export enum TestIds {
  NextQuestionButton = "next-question",
  PreviousQuestionButton = "previous-question",
  SendResponsesButton = "send-responses",
  CompleteButton = "complete-button",
  DisplayResultsButton = "display-results-button",
  NextButton = "next-button",
  StarRating = "star-rating",
  TimezoneSelect = "timezone-select",
  SendFeedback = "send-feedback",
  ModuleTab = "module-tab",
  EnrollButton = "enroll-button",
  HeaderMenuButton = "header-menu-button",
  EditCourseButton = "edit-course-button",
  EditUserButton = "edit-user-button",
  NewCourseButton = "new-course-button",
  EditModuleButton = "edit-module-button",
  NewModuleButton = "new-module-button",
  InputField = "input-field",
  RadioInput = "radio-input-field",
  CheckIcon = "check-icon",
  XIcon = "x-icon",
  EditFormButton = "edit-form-button",
  EnrollUserButton = "enroll-user-button",
  DisenrollUserButton = "disenroll-user-button",
  SideCalendar = "side-calendar",
  CohortCard = "cohort-card",
  DeliverableCard = "deliverable-card",
  CreateGradeButton = "create-grade-button",
  ConfirmDialogOk = "confirm-dialog-ok",
  Tabs = "tabs",
  DeliverableInput = "deliverable-input",
  SendDeliverableButton = "deliverable-send-button",
  EmailLoginInput = "email-login-input",
  PasswordLoginInput = "password-login-input",
  LoginWithCredentialsButton = "login-with-credentials-button",
  CreateAccountButton = "create-account-button",
  GradeInput = "grade-input",
  UserCoins = "user-coins",
  UserLevel = "user-level",
  UserXP = "user-xp",
  RocketfyBanner = "rocketfy-banner",
  RocketfyBannerImage = "rocketfy-banner-image",
  RocketfyBannerMainTitle = "rocketfy-banner-main-title",
  RocketfyBannerParagraph = "rocketfy-banner-paragraph",
  RocketfyBannerNavigateButton = "rocketfy-banner-navigate-button",
  RocketfyCancelButton = "rocketfy-cancel-button",
  RocketfyCreateAccountButton = "rocketfy-create-account-button",
  Container = "container",
  MainTitle = "main-title",
  MainImage = "main-image",
  Paragraph = "paragraph",
  UserCohortsList = "user-cohorts-list",
  EnrollNowButton = "enroll-now",
  PlaceOrderButton = "place-order-button",
  CourseCard = "course-card",
  SignUpButton = "sign-up-button",
  InviteCard = "invite-card",
  ChipsInput = "chips-input",
  ChipsButton = "chips-button",
  SendInviteButton = "send-invite-button",
  NotificationsButton = "notifications-button",
  NotificationsPanel = "notifications-panel",
  LogoutButton = "Logout-button",
  ReferrerCodeInput = "referrer-code-input",
  PaymentMethodInviteSelect = "payment-method-invite-select",
  CircularProgress = "circular-progress",
  TermsCheck = "terms-check",
  Academy = "academy-Button",
  Comunity = "cominity-Button",
  Solutions = "Solutions-Button",
  Products = "Products-Button",
  myProfile = "my-profile",
  myProgress = "my-progress",
  myCertificates = "my-certificates",
  myOrders = "my-orders",
  clubScale = "club-scale",
  EditMode = "edit-mode",
  ProMode = "pro-mode",
  TermsAndConditions = "terms-and-conditions",
  AdminPanel = "admin-panel",
  HomeButton = "home-button",
  Gamification = "gamification",
  Diplomats = "diplomats",
  BootcampsLaunch = "bootcamps-launch",
  Specializations = "specializations",
  AdvancedCourse = "advanced-course",
  BootcampsAdvanced = "bootcamps-advanced",
  Orgs = "orgs",
  Liveclasses = "live-classes",
  Badges = "badges",
  Reports = "reports",
  MasterClass = "master-class",
  DiscoverHereButton = "discover-here",
  RecoverPassword = "recover-password",
  ConsultantButton = "consultant-button",
  ConsultantName = "consultant-name",
  ConsultantJobs = "consultant-job",
  ConsultantSkills = "consultant-skills",
  ConsultanDetailsName = "consultant-details-name",
  ConsultanDetailsJobs = "consultant-details-Job",
  ConsultantsDetailsPhoto = "consultan-detailst-photo",
  ConsultantsDetailsSections = "consultant-details-sections",
  ConsultantsDetailsSkills = "consultant-details-skills",
  ConsultantsDetailsDescription = "consultant-details-description",
  ConsultantsDetailsValue30Min = "consultant-details-value30Min",
  ConsultantsDetailsValue = "consultant-details-value",
  ConsultantsDetailsButtonUrl = "consultant-details-buttonUrl",
  ConsultantsDetailsButtonUrl30Min = "consultant-details-buttonUrl30Min",
  ConsultantButtonDetails = "consultant-details",
  ConsultantButtonAgend = "consultant-Agend",
  ConsultantButtonBadge = "consultant-badge",
  ConsultantBio = "consultant-Bio",
  ConsultantSocial = "consultant-social",
  ConsultantExperienceTitle = "consultant-experience-title",
  ConsultantAgend1hourTitle = "consultant-Agend1hour-title",
  ConsultantAgend30minTitle = "consultant-Agend30min-title",
  ConsultantPrice = "consultant-price",
  ConsultantSchedule = "constultant-schedule",
  SolutionsSeeDetails = "solutions-see-details",
  SolutionsDetailsLink = "solutions-details-link",
  Productselection = "product-selection",
  ChinaAgent = "china-agent",
  ProductWeek = "product-week",
  ProductSeeAll = "product-see-all",
  ProductWantit = "product-wantit",
  ProductPath = "product-path",
  ProductConnectWithAgent = "Product-connect-with-agent",
  DownloadReport = "download-report",
  Next = "next",
  Previous = "previous",
  ChinaAgentForm = "china-agent-form",
  ChinaAgentFormproductselected = "china-agent-form-product-selected",
  ChinaAgentFormproductquoted = "china-agent-form-product-quoted",
  ChinaAgentFormPlatforms = "china-agent-form-platforms",
  ChinaAgentFormAlreadySelling = "china-agent-already-selling",
  ChinaAgentFormBudget = "china-agent-budget",
  ChinaAgentFormMoreAbout = "china-agent-more-about",
  DiplomatCardButton = "diplomat-card-button",
  BootcampCard = "bootcamp-card",
  goBack = "go-back",
  MasterClassCard = "masterclass-card",
  HelpCenter = "help-center",
  PostFormPlatforms = "post-form-platforms",
  communityWhatsApp = "community-whatsapp",
  Forum = "forum",
}
