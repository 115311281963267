import * as Types from '../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateBadgeMutationVariables = Types.Exact<{
  createBadgeInput: Types.CreateBadge2Input;
}>;


export type CreateBadgeMutation = { __typename?: 'Mutation', createBadge2: { __typename?: 'Badge2', color: string, id: string, name: string, imageUrl: { __typename?: 'Badge2ImageUrl', complete: string, mini: string }, trigger: { __typename?: 'Badge2Trigger', id?: string | null, type: string } } };


export const CreateBadgeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateBadge"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createBadgeInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateBadge2Input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createBadge2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createBadgeInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createBadgeInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"color"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"imageUrl"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"complete"}},{"kind":"Field","name":{"kind":"Name","value":"mini"}}]}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"trigger"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]}}]} as unknown as DocumentNode<CreateBadgeMutation, CreateBadgeMutationVariables>;