/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputProps,
  Text,
} from "@chakra-ui/react";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { TestIds } from "../../utils/testIds";
import { FormError } from "./FormError";
import { InputStyled } from "./InputStyled";

interface InputFieldProps {
  name?: string;
  label: string;
  validator?: (value: string) => Array<string>;
  onChange?: (value: string, isValid?: boolean) => void;
  value?: string | null;
  type?: InputProps["type"];
  isRequired?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  autoComplete?: InputProps["autoComplete"];
  leftAddon?: string;
  leftIcon?: React.ReactNode;
  legend?: React.ReactNode;
}

export const InputField: React.FC<
  Omit<InputProps, "onChange" | "value"> & InputFieldProps
> = ({
  name,
  label,
  validator,
  onChange,
  isLoading,
  value = "",
  type = "text",
  isRequired,
  placeholder,
  autoComplete,
  leftAddon,
  legend,
  min,
  max,
  isDisabled,
  size,
  leftIcon,
  ...props
}) => {
  const [errors, setErrors] = useState<Array<string>>([]);

  const isValid = useCallback(
    (
      isFieldRequired: boolean | undefined,
      fieldErrors: Array<string>,
      fieldValue: string | null | undefined
    ): boolean =>
      isFieldRequired
        ? fieldErrors.length === 0
        : fieldValue === null ||
          fieldValue === undefined ||
          fieldValue.length === 0 ||
          fieldErrors.length === 0,
    [isRequired]
  );

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldValue = e.target.value;
    const validationErrors = validator ? validator(fieldValue) : [];

    setErrors(validationErrors);
    onChange?.(fieldValue, isValid(isRequired, validationErrors, fieldValue));
  };

  useEffect(() => {
    if (value !== null) {
      const validationErrors = validator
        ? isLoading
          ? []
          : validator(value)
        : [];
      setErrors(validationErrors);
      onChange?.(value, isValid(isRequired, validationErrors, value));
    }
  }, [value]);

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={!isValid(isRequired, errors, value)}
    >
      <FormLabel fontSize="sm" htmlFor={name} fontWeight={600}>
        {label}
      </FormLabel>
      <InputGroup>
        {leftAddon && (
          <InputLeftAddon color="gray.500">{leftAddon}</InputLeftAddon>
        )}
        <InputGroup>
          <InputLeftElement pointerEvents="none">{leftIcon}</InputLeftElement>

          <InputStyled
            id={name}
            name={name}
            type={type}
            isRequired={isRequired}
            value={value ?? ""}
            onChange={handleValueChange}
            isInvalid={!isValid(isRequired, errors, value)}
            placeholder={placeholder}
            autoComplete={autoComplete}
            min={min}
            max={max}
            data-cy={TestIds.InputField}
            isDisabled={isDisabled}
            size={size}
            {...props}
          />
        </InputGroup>
      </InputGroup>
      {legend && (
        <Text fontSize="sm" color="gray.500" mt="1" ml="1" fontStyle="italic">
          {legend}
        </Text>
      )}
      {!isLoading && errors.length > 0 && (
        <FormError>{errors.join(", ")}</FormError>
      )}
    </FormControl>
  );
};
