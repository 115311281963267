import { breadcrumbAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(breadcrumbAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    color: "neutral.400",
    fontSize: "14px",
  },
  item: {
    _last: {
      color: "primary.500",
      fontWeight: 600,
    },
  },
  separator: {
    fontSize: "16px",
  },
});

const breadcrumbTheme = defineMultiStyleConfig({ baseStyle });

export { breadcrumbTheme };
