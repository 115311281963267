import * as Types from '../../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StoreUserLeftEventMutationVariables = Types.Exact<{
  storeUserLeftEventInput: Types.StoreUserLeftEventInput;
}>;


export type StoreUserLeftEventMutation = { __typename?: 'Mutation', storeUserLeftEvent: { __typename?: 'StoreUserLeftEventResponse', liveSessionId: string } };


export const StoreUserLeftEventDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StoreUserLeftEvent"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"storeUserLeftEventInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"StoreUserLeftEventInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"storeUserLeftEvent"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"storeUserLeftEventInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"storeUserLeftEventInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"liveSessionId"}}]}}]}}]} as unknown as DocumentNode<StoreUserLeftEventMutation, StoreUserLeftEventMutationVariables>;