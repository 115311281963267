import {
  Badge,
  Box,
  Divider,
  Flex,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";

import type { CardProps } from "./types";

const Card = ({
  isNewUi,
  actionButtons,
  badge,
  children,
  isProductCard,
  img,
  showDivider = true,
  childrenPadding,
  ...restProps
}: CardProps): JSX.Element => {
  const bgColor = useColorModeValue("white", "gray.800");

  return (
    <Flex
      bgColor={bgColor}
      direction={"column"}
      pos={"relative"}
      rounded={"8px"}
      minW={isNewUi ? "300px" : undefined}
      maxW={isNewUi ? "300px" : undefined}
      {...restProps}
    >
      {img === undefined ? null : (
        <Image
          userSelect={"none"}
          draggable={false}
          aspectRatio={16 / 9}
          bgColor={"gray.300"}
          objectFit={"cover"}
          borderBottom={isProductCard ? "solid 1px" : undefined}
          borderColor={isProductCard ? "neutral.200" : undefined}
          roundedTop={"8px"}
          roundedBottom={isNewUi ? "8px" : 0}
          w={"100%"}
          {...img}
        />
      )}
      {badge === undefined ? null : (
        <Badge
          color={"secondary.300"}
          fontSize={"12px"}
          fontWeight={"400"}
          m={isNewUi ? undefined : "16px"}
          p={isNewUi ? undefined : "4px 8px"}
          pos={"absolute"}
          rounded={"50px"}
          variant={"solid"}
          {...badge}
        />
      )}
      <Box flexGrow={isNewUi ? 0 : 1} p={isNewUi ? childrenPadding : "16px"}>
        {children}
      </Box>
      {actionButtons === undefined ? null : (
        <>
          {showDivider ? (
            <Divider m={"16px"} mt={0} w={"calc(100% - 32px)"} />
          ) : null}
          <Flex
            align={"center"}
            flexShrink={0}
            gap={"8px"}
            p={isNewUi ? undefined : "16px"}
            mt={isNewUi ? "16px" : undefined}
            pt={0}
          >
            {actionButtons}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export { Card };
