type ManyOrSingle<T> = T | T[];

const asArray = <T>(el: ManyOrSingle<T>): T[] => {
  return Array.isArray(el) ? el : [el];
};

const asSingle = <T>(el: ManyOrSingle<T>): T => {
  return Array.isArray(el) ? el[0] : el;
};

export type { ManyOrSingle };
export { asArray, asSingle };
