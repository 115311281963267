import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
);

const inputTheme = defineMultiStyleConfig({
  baseStyle: {
    field: {
      _placeholder: {
        color: "secondary.100",
      },
      borderRadius: "8px",
      color: "secondary.300",
      fontSize: "14px",
      lineHeight: 1.6,
    },
  },
  variants: {
    outline: {
      field: {
        _disabled: {
          bgColor: "neutral.50 !important",
          borderColor: "neutral.200 !important",
        },
        _focus: {
          borderColor: "primary.300",
        },
        _hover: {
          borderColor: "primary.200",
        },
        borderColor: "neutral.300",
        outline: "unset",
      },
    },
    color: {
      field: {
        _disabled: {
          bgColor: "neutral.50 !important",
          borderColor: "neutral.200 !important",
        },
        _focus: {
          borderColor: "primary.300",
        },
        _hover: {
          borderColor: "primary.200",
        },
        borderColor: "neutral.300",
        outline: "unset",
        padding: "0",
        background: "transparent",
        color: "gray !important",
      },
    },
  },
});

export { inputTheme };
