import * as Types from '../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteMasterClassMutationVariables = Types.Exact<{
  masterClassId: Types.Scalars['ID'];
}>;


export type DeleteMasterClassMutation = { __typename?: 'Mutation', deleteMasterClass: string };


export const DeleteMasterClassDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteMasterClass"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"masterClassId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteMasterClass"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"masterClassId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"masterClassId"}}}]}]}}]} as unknown as DocumentNode<DeleteMasterClassMutation, DeleteMasterClassMutationVariables>;