import { Ico } from "@/assets/icons";
import {
  Flex,
  Heading,
  Box,
  Badge,
  Grid,
  OrderedList,
  ListItem,
  Image,
  Text,
} from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { usePresenter } from "../presenter/usePresenter";
import { ServiceImageGallery } from "./ServiceImageGallery";

export const ServiceDetailBody: React.FC = () => {
  const { isMobile, currentServiceForView: currentService } = usePresenter();

  return (
    <Flex
      w={isMobile ? "100%" : "60%"}
      minW={isMobile ? undefined : "680px"}
      direction={"column"}
      pb={isMobile ? "200px" : 0}
    >
      <Flex direction={isMobile ? "column-reverse" : "column"}>
        <Flex mb={"24px"} alignItems={"center"} justifyContent={"start"}>
          <Heading as={isMobile ? "p" : "h4"}>
            <Trans>{currentService?.title}</Trans>
          </Heading>
        </Flex>
        <Flex
          direction={isMobile ? "column" : "row"}
          gap={"16px"}
          mb={isMobile ? "16px" : 0}
        >
          <Box
            borderRadius={"250px"}
            border={"1px solid"}
            borderColor={"neutral.100"}
            p={"4px"}
            py={"6px"}
            w={"64px"}
            h={"64px"}
            overflow={"hidden"}
            textAlign={"center"}
            alignItems={"flex-start"}
          >
            <Image
              w={"50px"}
              h={"50px"}
              mx={"auto"}
              src={
                currentService?.agencyImage &&
                currentService?.agencyImage !== ""
                  ? currentService?.agencyImage
                  : "/img/logo_smartbeemo.svg"
              }
            ></Image>
          </Box>
          <Box mx={isMobile ? 0 : 2} mb={2}>
            <Heading as={"p"}>{currentService?.agency}</Heading>
            {[currentService?.category, currentService?.subcategory].map(
              (category) => {
                return category?.id ? (
                  <Badge
                    key={currentService?.id + "-" + category?.id}
                    px={"8px"}
                    py={"2px"}
                    bg={"neutral.50"}
                    textTransform={"capitalize"}
                    w={"fit-content"}
                    color={"neutral.500"}
                    borderRadius={"6px"}
                    mr={"8px"}
                  >
                    <Text fontSize={"12px"}>{category?.name}</Text>
                  </Badge>
                ) : null;
              }
            )}
            <Heading mt={2} as={"p"} fontSize={"14px"}>
              {currentService?.subtitle}
            </Heading>
          </Box>
        </Flex>
      </Flex>
      <Text fontSize={"14px"} lineHeight={"22.4px"}>
        {currentService?.description}
      </Text>
      <ServiceImageGallery></ServiceImageGallery>
      <Heading mt={"32px"} as={"h6"}>
        ¿Por qué nosotros?
      </Heading>
      <Text fontSize={"14px"} mt={"8px"}>
        {currentService?.whyUs ?? "No hay informacion para mostrar"}
      </Text>
      <Heading mt={"32px"} as={"h6"}>
        ¿Qué servicios ofrecemos?
      </Heading>
      <Grid
        templateColumns={"repeat(auto-fit, minmax(min-content, 200px))"}
        fontSize={"14px"}
        gap={"16px"}
        my={"16px"}
        display={"grid"}
        flexWrap={"nowrap"}
      >
        {currentService?.services && currentService?.services.length > 0 ? (
          currentService?.services.map((_) => {
            return (
              <Flex
                direction={"row"}
                alignItems={"center"}
                gap={"4px"}
                w={"max-content"}
                key={_}
              >
                <Ico.Check fontSize={"16px"} fill={"#427AFF"}></Ico.Check>
                <Text fontSize={"14px"}>{_}</Text>
              </Flex>
            );
          })
        ) : (
          <Text>No hay informacion para mostrar</Text>
        )}
      </Grid>
      <Heading mt={"32px"} as={"h6"} mb={"8px"}>
        ¿Cómo funciona?
      </Heading>
      <OrderedList type="1">
        {currentService?.howItWorks && currentService?.howItWorks.length > 0 ? (
          currentService?.howItWorks.map((_) => {
            return (
              <ListItem key={_}>
                <Text fontSize={"14px"}>{_}</Text>
              </ListItem>
            );
          })
        ) : (
          <Text>No hay informacion para mostrar</Text>
        )}
      </OrderedList>
    </Flex>
  );
};
