import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Box,
  Card,
  Flex,
  ResponsiveValue,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import LiveChat from "./LiveChat/LiveChat";
import Questions from "./Questions/Questions";
import ChatContext from "../context/chat-context";
import { ChatMessage } from "@zoom/videosdk";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";
import { Ico } from "@/assets/icons";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import ZoomClientContext from "../context/zoom-context";
import { Participants } from "./Participants/Participants";

interface ChatProps {
  width?: ResponsiveValue<number | string>;
}

const Chat: React.FC<ChatProps> = () => {
  const { hasRoles } = useUser();
  const isMentor = hasRoles([UserRole.Mentor]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const chatClient = useContext(ChatContext);
  const { clientState, zmClient } = useContext(ZoomClientContext);
  const { liveSessionId } = useParams<{ liveSessionId: string }>();
  const [generalChatMessages, setGeneralChatMessages] = useState<ChatMessage[]>(
    []
  );
  const [questionChatMessages, setQuestionChatMessages] = useState<
    ChatMessage[]
  >([]);
  const [isHostOrCoHost, setIsHostOrCoHost] = useState(isMentor);

  const addUniqueMessage = useCallback(
    (messages: ChatMessage[], newMessage: ChatMessage) => {
      if (!messages.some((msg) => msg.id === newMessage.id)) {
        return [...messages, newMessage];
      }
      return messages;
    },
    []
  );

  useEffect(() => {
    const onChatMessage = (payload: ChatMessage) => {
      const { sender, message, timestamp, receiver, file, id } = payload;
      const chatMessage: ChatMessage = {
        sender,
        message,
        timestamp,
        receiver,
        file,
        id,
      };

      if (!payload.message?.startsWith("Question:")) {
        setGeneralChatMessages((prevMessages) => {
          const updatedMessages = addUniqueMessage(prevMessages, chatMessage);
          sessionStorage.setItem(
            "generalChatMessages" + "-" + liveSessionId,
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        });
      } else {
        setQuestionChatMessages((prevMessages) => {
          const updatedMessages = addUniqueMessage(prevMessages, chatMessage);
          sessionStorage.setItem(
            "questionChatMessages" + "-" + liveSessionId,
            JSON.stringify(updatedMessages)
          );
          return updatedMessages;
        });
      }
    };

    const onChatPrivilegeChange = (_: { chatPrivilege: number }) => {
      if (zmClient && isMentor && chatClient) {
        setIsHostOrCoHost(
          isMentor || zmClient.isHost() || zmClient.isManager()
        );
      }
    };

    if (zmClient) {
      zmClient.on("chat-on-message", onChatMessage);
      zmClient.on("chat-privilege-change", onChatPrivilegeChange);
    }

    return () => {
      if (zmClient) {
        zmClient.off("chat-on-message", onChatMessage);
        zmClient.off("chat-privilege-change", onChatPrivilegeChange);
      }
    };
  }, [zmClient, chatClient, addUniqueMessage, liveSessionId, isMentor]);

  useEffect(() => {
    const fetchInitialMessages = () => {
      const savedGeneralMessages = JSON.parse(
        sessionStorage.getItem("generalChatMessages" + "-" + liveSessionId) ||
          "[]"
      );
      setGeneralChatMessages(savedGeneralMessages);

      const savedQuestionMessages = JSON.parse(
        sessionStorage.getItem("questionChatMessages" + "-" + liveSessionId) ||
          "[]"
      );
      setQuestionChatMessages(savedQuestionMessages);
    };

    fetchInitialMessages();
  }, [liveSessionId]);

  const sendMessage = useCallback(
    (isPublic: boolean, message: string) => {
      if (message.trim() !== "" && zmClient && chatClient) {
        chatClient.sendToAll(message);
      }
    },
    [zmClient, chatClient]
  );

  if (!clientState?.isAuth || !zmClient || !chatClient) return null;

  return (
    <Card
      bg={"#27272E"}
      boxSize={"100%"}
      display={"flex"}
      flexDir={"column"}
      id="chatCard"
      border={"1px solid #32323A"}
    >
      <Flex
        flexDir={"column"}
        h={{ base: "100vh", lg: "100%" }}
        id={"chatContainer"}
      >
        <Box
          backgroundColor={"secondary.300"}
          py={"6px"}
          borderRadius={"8px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          color={"neutral.200"}
          gap={"8px"}
          mt={"24px"}
          mx={"24px"}
        >
          <Ico.Users color={"neutral.200"} fontSize={"24px"} />
          <Text color={"neutral.200"} fontSize={"14px"}>
            {zmClient.getAllUser().length || 0} Participantes
          </Text>
        </Box>

        <Tabs
          mt={{ base: "12px", xl: "16px" }}
          isLazy
          display={"flex"}
          flex={1}
          flexDir={"column"}
          isFitted
          onChange={(index: number) => {
            setTabIndex(index);
          }}
          overflow={"auto"}
          mx={"24px"}
          h={{ base: "calc(100% - 120px)", md: "auto" }}
          maxH={tabIndex === 0 ? { base: "100%" } : { base: "auto" }}
          id="Tabs"
        >
          <TabList>
            <Tab
              fontSize={"14px"}
              _selected={{
                color: "tertiary.400",
                borderBottom: "3px solid #E3BF00",
              }}
              color={"secondary.50"}
            >
              Chat
            </Tab>
            <Tab
              fontSize={"14px"}
              _selected={{
                color: "tertiary.400",
                borderBottom: "3px solid #E3BF00",
              }}
              color={"secondary.50"}
            >
              ¿{t("You have questions")}?
            </Tab>
            {isMentor && (
              <Tab
                fontSize={"14px"}
                _selected={{
                  color: "tertiary.400",
                  borderBottom: "3px solid #E3BF00",
                }}
                color={"secondary.50"}
              >
                {t("Participants")}
              </Tab>
            )}
          </TabList>
          <TabPanels
            id="chatpanel"
            bg={"#303036"}
            borderBottomRadius={"8px"}
            overflowY={"auto"}
            height={{
              base: "90%",
              sm: "80%",
              md: "85%",
              lg: "85%",
              xl: "85%",
              "2xl": "90%",
            }}
          >
            <LiveChat
              generalChatMessages={generalChatMessages}
              sendMessage={sendMessage}
            />

            <Questions
              isHostOrCoHost={isHostOrCoHost}
              questionChatMessages={questionChatMessages}
              sendMessage={sendMessage}
            />

            {isMentor && <Participants />}
          </TabPanels>
        </Tabs>
      </Flex>
    </Card>
  );
};

export default Chat;
