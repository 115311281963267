import * as Types from '../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteLiveSessionMutationVariables = Types.Exact<{
  liveSessionId: Types.Scalars['ID'];
}>;


export type DeleteLiveSessionMutation = { __typename?: 'Mutation', deleteLiveSession: string };


export const DeleteLiveSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteLiveSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"liveSessionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteLiveSession"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"liveSessionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"liveSessionId"}}}]}]}}]} as unknown as DocumentNode<DeleteLiveSessionMutation, DeleteLiveSessionMutationVariables>;