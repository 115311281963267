import { Flex, Text } from "@chakra-ui/react";

import { Ico } from "@/assets/icons";
import type { FormAlertProps, FormAlertStatus } from "./types";

const statuses = {
  error: {
    color: "error.300",
    Icon: Ico.TriangleWarning,
  },
  success: {
    color: "warning.300",
    Icon: Ico.CircleCheck,
  },
  warning: {
    color: "success.300",
    Icon: Ico.TriangleWarning,
  },
} satisfies Record<FormAlertStatus, object>;

const FormAlert = ({
  children = "",
  isTouched = false,
  status = "error",
  ...restProps
}: FormAlertProps): JSX.Element => {
  const { color, Icon } = statuses[status];
  const hasAlert = !!children && isTouched;

  return (
    <Flex
      {...restProps}
      align={"center"}
      color={color}
      gap={"8px"}
      h={hasAlert ? "1.6em" : "0"}
      mt={hasAlert ? "8px" : "0"}
      opacity={hasAlert ? 1 : 0}
      transition={"all 0.3s linear"}
    >
      <Icon color={color} />
      <Text color={"secondary.100"} variant={"feedback"}>
        {children}
      </Text>
    </Flex>
  );
};

export { FormAlert };
