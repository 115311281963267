import { useUser } from "@/providers/useUser";
import { segment } from "@/segmentConfig";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useGA4 = () => {
  const location = useLocation();
  const { user, firebaseAnalyticsApp } = useUser();

  useEffect(() => {
    if (!user || !firebaseAnalyticsApp) return;

    const analyticsApp = getAnalytics(firebaseAnalyticsApp);

    setUserId(analyticsApp, user.id);

    setUserProperties(analyticsApp, {
      email: user.email,
      name: user.name,
    });

    segment.identify(user.id, {
      email: user.email,
      name: user.name,
    });
  }, [user, firebaseAnalyticsApp]);

  useEffect(() => {
    if (!firebaseAnalyticsApp) return;

    const analyticsApp = getAnalytics(firebaseAnalyticsApp);

    const pagePath = location.pathname + location.search + location.hash;

    logEvent(analyticsApp, "page_view", {
      page_path: pagePath,
      page_location: window.location.href,
      page_title: document.title,
    });

    segment.track("page_view", {
      page_path: pagePath,
      page_location: window.location.href,
      page_title: document.title,
    });
  }, [location]);
};

export default useGA4;
