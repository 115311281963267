import React, { useContext, useEffect, useState, useRef } from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import ZoomClientContext from "@/components/LiveSessionsZoom/context/zoom-context";
import ZoomMediaContext from "@/components/LiveSessionsZoom/context/media-context";
import { VideoQuality } from "@zoom/videosdk";
import { useVideoState } from "@/components/LiveSessionsZoom/context/videoState/useVideoState";
import {
  DISPATCH_TYPE,
  EVENTS,
} from "@/components/LiveSessionsZoom/types/LiveBar.type";

interface CameraGridProps {
  userId?: number;
}

export const CameraGrid: React.FC<CameraGridProps> = ({ userId }) => {
  const zoomMediaContext = useContext(ZoomMediaContext);
  const { state, dispatch } = useVideoState();
  const { zmClient } = useContext(ZoomClientContext);
  const [cameraListNumber, setCameraListNumber] = useState<Array<number>>([]);
  const [, setGridColumns] = useState(1);
  const videoRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  const getColumnsCamera = (cameraList: number, isScreenSharing: boolean) => {
    if (isScreenSharing) {
      setGridColumns(1);
      dispatch({ type: DISPATCH_TYPE.SET_GRID_CAMERA, payload: 1 });
      return;
    }

    if (cameraList <= 1) {
      setGridColumns(1);
      dispatch({ type: DISPATCH_TYPE.SET_GRID_CAMERA, payload: 1 });
      return;
    }
    if (cameraList > 1 && cameraList <= 4) {
      setGridColumns(2);
      dispatch({ type: DISPATCH_TYPE.SET_GRID_CAMERA, payload: 2 });
      return;
    }
    setGridColumns(1);
    dispatch({ type: DISPATCH_TYPE.SET_GRID_CAMERA, payload: 1 });
    return;
  };

  useEffect(() => {
    if (zmClient) {
      const updateCameraList = () => {
        const participants = zmClient.getAllUser();
        const cameraList = participants
          .filter((user) => user.bVideoOn && user.userId !== userId)
          .map((user) => user.userId);

        if (participants.find((user) => user.userId === userId)?.bVideoOn) {
          if (userId) cameraList.unshift(userId);
        }

        setCameraListNumber(cameraList);

        const isScreenSharing = participants.some((user) => user.sharerOn);
        getColumnsCamera(cameraList.length, isScreenSharing);
      };

      updateCameraList();

      zmClient.on(EVENTS.USER_UPDATED, updateCameraList);
      zmClient.on(EVENTS.USER_ADDED, updateCameraList);
      zmClient.on(EVENTS.USER_REMOVED, updateCameraList);

      return () => {
        zmClient.off(EVENTS.USER_UPDATED, updateCameraList);
        zmClient.off(EVENTS.USER_ADDED, updateCameraList);
        zmClient.off(EVENTS.USER_REMOVED, updateCameraList);
      };
    }
  }, [zmClient, userId]);

  useEffect(() => {
    if (
      zoomMediaContext?.mediaContext.mediaStream?.isSupportMultipleVideos &&
      zoomMediaContext?.mediaContext.mediaStream &&
      cameraListNumber.length > 0
    ) {
      cameraListNumber.forEach((userIdCamera) => {
        const videoContainer = videoRefs.current[userIdCamera];

        if (videoContainer && videoContainer.childElementCount === 0) {
          zoomMediaContext.mediaContext.mediaStream
            ?.attachVideo(userIdCamera, VideoQuality.Video_720P)
            .then((videoElement) => {
              videoContainer.innerHTML = "";
              videoContainer.appendChild(videoElement as Node);
            });
        }
      });
    }
  }, [zoomMediaContext, cameraListNumber]);

  return (
    <Grid
      display={state.isScreenShareActive ? "flex" : "grid"}
      gap={2}
      flexDirection={"column"}
      width="100%"
      height={state.isScreenShareActive ? "none" : "100%"}
      templateColumns={
        cameraListNumber.length === 3 && !state.isScreenShareActive
          ? "repeat(2, 1fr)"
          : `repeat(${state.isGridCamera}, 1fr)`
      }
      templateRows={
        cameraListNumber.length === 3 && !state.isScreenShareActive
          ? "repeat(2, 1fr)"
          : "1fr"
      }
      overflow="hidden"
      marginBottom={"20px"}
      marginTop={"20px"}
      padding={
        !state.isScreenShareActive && cameraListNumber.length === 1 ? 0 : 2
      }
    >
      {cameraListNumber.map((userIdCamera, index) => (
        <GridItem
          key={userIdCamera}
          colSpan={cameraListNumber.length === 3 ? (index === 2 ? 2 : 1) : 1}
          rowSpan={1}
          overflow="hidden"
          display="flex"
          justifyContent={"center"}
        >
          <Box
            key={userIdCamera}
            width={
              cameraListNumber.length === 3 && !state.isScreenShareActive
                ? index === 2
                  ? "50%"
                  : "100%"
                : "100%"
            }
            height="100%"
            backgroundColor="secondary.300"
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            borderRadius={"8px"}
          >
            <Box
              id="video-container"
              width="100%"
              height="auto"
              borderRadius={"8px"}
              overflow={"hidden"}
              sx={{
                "& > video-player-container": {
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  "& > video-player": {
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                    aspectRatio: "16/9",
                    objectFit: "contain",
                  },
                },
              }}
            >
              <Box
                borderRadius={"8px"}
                as="video-player-container"
                id={`camera-${userIdCamera}`}
                ref={(el) => (videoRefs.current[userIdCamera] = el)}
              ></Box>
            </Box>
          </Box>
        </GridItem>
      ))}
    </Grid>
  );
};
