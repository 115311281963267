import * as Types from '../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateLiveSessionMutationVariables = Types.Exact<{
  updateLiveSessionOrMasterclassInput: Types.UpdateLiveSessionOrMasterclassInput;
}>;


export type UpdateLiveSessionMutation = { __typename?: 'Mutation', updateLiveSession: string };


export const UpdateLiveSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateLiveSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"updateLiveSessionOrMasterclassInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateLiveSessionOrMasterclassInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateLiveSession"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"updateLiveSessionOrMasterclassInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"updateLiveSessionOrMasterclassInput"}}}]}]}}]} as unknown as DocumentNode<UpdateLiveSessionMutation, UpdateLiveSessionMutationVariables>;