import { useMutation } from "@apollo/client";
import { noCacheHeaders } from "@/utils/headers";
import { EnrollUserInMultipleCohortsDocument } from "@/pages/GuideTraining/graphql/enrollUserInBootcamp.generated";
import { UnblockUserFromMultipleCohortsDocument } from "@/pages/GuideTraining/graphql/unBlockBcUserEnrroll.generated";
import { BlockUserFromMultipleCohortsDocument } from "@/pages/GuideTraining/graphql/blockBcUserEnrroll.generated";

export const useEnrollmentMutations = () => {
  const [enrollUserMutation] = useMutation(
    EnrollUserInMultipleCohortsDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );

  const [unblockUserMutation] = useMutation(
    UnblockUserFromMultipleCohortsDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );

  const [blockUserMutation] = useMutation(
    BlockUserFromMultipleCohortsDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );

  return {
    enrollUserMutation,
    unblockUserMutation,
    blockUserMutation,
  };
};
