import { Divider, Flex, Heading } from "@chakra-ui/react";
import { ProfileCard } from "../ProfileCard/profileCard";
import { colors } from "@/components/Theme/colors";
import { Fragment } from "react";
import { ProfilesArrayProps } from "../utils";

export const ProfilesArray = ({ profilesData, title }: ProfilesArrayProps) => {
  return (
    <Flex flexDir={"column"} gap={4}>
      <Heading>{title}</Heading>
      <Flex
        flexDir={"column"}
        gap={4}
        border={`1px solid ${colors.neutral[100]}`}
        borderRadius={"6px"}
        p={4}
      >
        {profilesData.map((profile, index) => (
          <Fragment key={index}>
            <ProfileCard profileData={profile} />
            {index + 1 < profilesData.length && (
              <Divider borderBottomWidth={"1px"} />
            )}
          </Fragment>
        ))}
      </Flex>
    </Flex>
  );
};
