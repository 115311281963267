import { GetUserGamificationInfoDocument } from "@/components/Gamification/graphql/getMyGamificationInfo.generated";
import { useUser } from "@/providers/useUser";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

enum levels {
  Newbie = "Newbie",
  Silver = "Plata",
  Gold = "Oro",
  Platinium = "Platino",
  Emerald = "Esmeralda",
  Ruby = "Rubi",
  Sapphire = "Zafiro",
  Diamond = "Diamante",
}

export const useGamification = () => {
  const animationDuration = 7000;

  const {
    lastUserStatsUpdate: lastGamificationUpdate,
    userExperience,
    userLevel,
    setUserCoins,
  } = useUser();
  const [amount, setAmount] = useState(0);
  const [level, setLevel] = useState(levels.Newbie);
  const [imgLevel, setImgLevel] = useState("");
  const [colorLevel, setColorLevel] = useState("#F5ECEA");
  const [newCoins, setNewCoins] = useState(0);
  const [newBadges, setNewBadges] = useState(0);
  const [userHasNoExperience, setUserHasNoExperience] = useState(false);

  const { data, refetch, loading, previousData } = useQuery(
    GetUserGamificationInfoDocument
  );

  useEffect(() => {
    if (lastGamificationUpdate) {
      refetch();
    }
  }, [lastGamificationUpdate]);

  useEffect(() => {
    if (
      previousData?.me?.coins?.amount === undefined ||
      previousData?.me?.coins?.amount === null
    ) {
      setAmount(data?.me?.coins?.amount ?? 0);
      setUserCoins(data?.me?.coins?.amount ?? 0);
    } else if (
      data?.me?.coins?.amount !== undefined &&
      data.me.coins.amount !== previousData.me.coins.amount
    ) {
      const earnedCoins = data.me.coins.amount - previousData.me.coins.amount;
      setNewCoins(earnedCoins);

      setTimeout(() => {
        setAmount(data?.me?.coins?.amount ?? 0);
        setUserCoins(data?.me?.coins?.amount ?? 0);
        setNewCoins(0);
      }, animationDuration);
    }

    if (
      previousData?.me?.badges === undefined ||
      previousData?.me?.badges === null
    ) {
      // setBadgesCount(data?.me?.badges?.length ?? 0);
    } else if (
      data?.me?.badges !== undefined &&
      data?.me?.badges !== null &&
      data.me.badges.length !== previousData?.me?.badges?.length
    ) {
      setNewBadges(previousData?.me?.badges?.length - data.me.badges.length);

      setTimeout(() => {
        // setBadgesCount(data?.me?.badges?.length ?? 0);
        setNewBadges(0);
      }, animationDuration);
    }
  }, [previousData, data]);

  const coinsForLevel = {
    silver: 5000,
    gold: 10000,
    platinium: 20000,
  };

  const assignGamificationLevel = (totalCoins: number) => {
    if (totalCoins > coinsForLevel.silver && totalCoins < coinsForLevel.gold) {
      setLevel(levels.Silver);
      setColorLevel("#CACCD8");
      setImgLevel(
        "https://storage.googleapis.com/lms-stg-media/status/status/plata.png"
      );
      return;
    } else if (
      totalCoins > coinsForLevel.gold &&
      totalCoins < coinsForLevel.platinium
    ) {
      setLevel(levels.Gold);
      setColorLevel("#EACF77");
      setImgLevel(
        "https://storage.googleapis.com/lms-stg-media/status/status/oro.png"
      );
      return;
    } else if (totalCoins > coinsForLevel.platinium) {
      setLevel(levels.Platinium);
      setColorLevel("#CBC2C3");
      setImgLevel(
        "https://storage.googleapis.com/lms-stg-media/status/status/platino.png"
      );
      return;
    } else if (totalCoins > 30000) {
      setLevel(levels.Emerald);
      setColorLevel("#0A4136");
      setImgLevel(
        "https://storage.googleapis.com/lms-stg-media/status/status/esmeralda.png"
      );
      return;
    } else if (totalCoins > 30010) {
      setLevel(levels.Ruby);
      setColorLevel("#B90909");
      return;
    }
    if (totalCoins > 30020) {
      setLevel(levels.Sapphire);
      setColorLevel("#0049BE");
      return;
    } else if (totalCoins > 30030) {
      setLevel(levels.Diamond);
      setColorLevel("#D0CFE3");
      return;
    } else {
      setLevel(levels.Newbie);
      setColorLevel("#F5ECEA");
      return;
    }
  };

  useEffect(() => {
    if (data?.me?.experience === null) {
      setUserHasNoExperience(true);
    } else if (data?.me?.coins?.amount && data?.me?.experience) {
      setUserHasNoExperience(
        data.me.experience.experience === 0 && data.me.experience.level === 0
      );
    }
  }, [userExperience, userLevel, data?.me]);

  useEffect(() => {
    assignGamificationLevel(data?.me?.coins?.amount ?? 0);
    if (userHasNoExperience) {
      setTimeout(() => {
        setUserHasNoExperience(false);
      }, 10 * 1000);
    }
  }, [userHasNoExperience]);

  return {
    newCoins,
    loading,
    amount,
    newBadges,
    level,
    colorLevel,
    levels,
    imgLevel,
  };
};
