import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { AdminRoute, AppRoute, PublicRoute } from "@/AppRoute";
import { SyncABdataDocument } from "@/graphql/syncABdata.generated";
import useGA4Event from "@/hooks/useGA4Event";
import { useMutation } from "@apollo/client";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { NewHeader } from "./Header/newHeader";
import NewSideMenu from "./newSideMenu";

import type { NavigationProps } from "./types";

const useIsPath = (paths: (AppRoute | AdminRoute)[]): boolean => {
  const { pathname } = useLocation();
  const cleanPathname = pathname === "/" ? pathname : pathname.substring(1);
  return useMemo(() => {
    return paths.some((path) => {
      const cleanPath = path === "/" ? path : path.substring(1);
      return cleanPathname.startsWith(cleanPath);
    });
  }, [cleanPathname, paths]);
};

const Navigation = ({ children }: NavigationProps): JSX.Element => {
  const [syncABdata] = useMutation(SyncABdataDocument);

  const abData = useFeatureIsOn("new-navigation");
  const analytics = useGA4Event();

  useLayoutEffect(() => {
    analytics.setUserProperty({ ui_version: abData ? "1.1" : "1.0" });

    syncABdata({
      variables: {
        syncABdataInput: { key: "new-navigation", value: abData },
      },
    });
  }, [window.document, abData]);

  const pathsWithoutSideBarDesktop: (AppRoute | AdminRoute)[] = [
    AppRoute.SettingDevices,
    AppRoute.LiveSessionStreaming,
    AppRoute.LiveSessionBroadcast,
    AppRoute.ConnectWithRocketfy,
    AppRoute.Profiling,
    PublicRoute.LiveSessionPortalV1 as unknown as AppRoute,
    PublicRoute.CreatePassword as unknown as AppRoute,
  ];
  const pathsWithoutTopBar = [
    AppRoute.Profiling,
    AppRoute.SuscriptionExpider,
    AppRoute.ConnectWithRocketfy,
    AppRoute.SettingDevices,
    AppRoute.LiveSessionStreaming,
    AppRoute.LiveSessionBroadcast,
  ];

  const { pathname } = useLocation();
  const [openSideBar, setOpenSideBar] = useState(false);
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const showTopBar = !useIsPath(pathsWithoutTopBar);
  const showSideBar = !useIsPath(pathsWithoutSideBarDesktop);

  const sideBarState = showSideBar ? (openSideBar ? "open" : "close") : "hide";

  const toggleOpenSideBar = useCallback(() => {
    setOpenSideBar((val) => !val);
  }, []);

  useEffect(() => {
    setOpenSideBar(false);
  }, [pathname]);

  return (
    <Box position={"relative"}>
      {showTopBar && (
        <NewHeader
          onClickMenuButton={toggleOpenSideBar}
          sideBar={sideBarState}
        />
      )}
      <Flex>
        <NewSideMenu show={showSideBar && (!isMobile || openSideBar)} />
        <Box flex={"1"} overflowX={"auto"}>
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export { Navigation };
