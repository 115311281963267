import { MarketplaceService } from "@/schemaTypes";

export const searchByTerm = (
  services: Array<MarketplaceService | null> | null,
  term: string
): Array<MarketplaceService | null> => {
  const lowerSearchString = term.toLowerCase();
  return (
    services?.filter(
      (srvc) =>
        srvc?.agency?.toLowerCase().includes(lowerSearchString) ||
        srvc?.title?.toLowerCase().includes(lowerSearchString) ||
        srvc?.description?.toLowerCase().includes(lowerSearchString) ||
        srvc?.subtitle?.toLowerCase().includes(lowerSearchString)
    ) ?? []
  );
};
