import { useState, useEffect } from "react";
import axios from "axios";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { YouTubePlayerProps } from "react-player/youtube";

export interface VideoItem {
  id: string;
  title: string;
  description: string;
  thumbnailUrl: string;
  publishedAt: string;
}

interface UseYoutubePlaylistResult {
  videos: VideoItem[];
  loading: boolean;
  error: boolean;
}

export const useYoutubePlaylist = (
  playlistId: string,
  apiKey: string
): UseYoutubePlaylistResult => {
  const [videos, setVideos] = useState<VideoItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const fetchPlaylistItems = async () => {
      try {
        let nextPageToken = "";
        const allVideos: VideoItem[] = [];

        do {
          const response = await axios.get(
            "https://www.googleapis.com/youtube/v3/playlistItems",
            {
              params: {
                part: "snippet",
                maxResults: 50,
                playlistId,
                pageToken: nextPageToken,
                key: apiKey,
              },
            }
          );

          const items = response.data.items.map((item: YouTubePlayerProps) => ({
            id: item.snippet.resourceId.videoId,
            title: item.snippet.title,
            description: item.snippet.description,
            thumbnailUrl: item.snippet.thumbnails?.medium?.url || "",
            publishedAt: item.snippet.publishedAt,
          }));

          allVideos.push(...items);
          nextPageToken = response.data.nextPageToken;
        } while (nextPageToken);

        setVideos(allVideos);
      } catch (fetchError) {
        SBErrorPubSub.publish({
          component: "RecordedSessions.tsx line 61",
          message:
            (fetchError as Error)?.message ||
            "Error fetching recorded sessions",
          showInProd: true,
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchPlaylistItems();
  }, [playlistId, apiKey]);
  return { videos, loading, error };
};

export const extractPlaylistId = (url: string): string => {
  try {
    const urlObj = new URL(url);
    const playlistId = urlObj.searchParams.get("list") || "";
    return playlistId;
  } catch (fetchError) {
    SBErrorPubSub.publish({
      component: "useYoutubePlaylist.tsx line 88",
      message: (fetchError as Error)?.message || "Error fetching PlaylistID",
      showInProd: true,
    });
    return (fetchError as Error)?.message;
  }
};
