import { useUser } from "@/providers/useUser";
import { segment } from "@/segmentConfig";
import {
  Analytics,
  CustomParams,
  getAnalytics,
  logEvent,
  setUserProperties,
} from "firebase/analytics";
import { useEffect, useState } from "react";

export enum GA4EventCategory {
  Click = "click",

  // New definition
  Login = "Inicio de sesión",
  Programs = "Diplomados",
  Courses = "Cursos",
  Lessons = "Lecciones",
  Specializations = "Especializaciones",
  AdvancedCourses = "Cursos avanzados",
  Bootcamps = "Bootcamps",
  Masterclasses = "Masterclasses",
  Solutions = "Soluciones",
  Products = "Productos",
  LiveSessions = "Clases en vivo",
  Community = "Comunidad",
  ServicesMarkeplace = "Marketplace Servicios",
}

export enum GA4EventAction {
  ConsultingButtonClick = "consulting-button-click",
  ConsultantCardButtonClick = "consultant-button-click",
  ConsultantDetailLinkedinClick = "consultant-detail-linkedin-click",
  ConsultantDetailInstagramClick = "consultant-detail-instagram-click",
  Consultant30MinScheduleClick = "consultant-30-min-schedule-click",
  Consultant1HourScheduleClick = "consultant-1-hour-schedule-click",
  CommunityVerticalCardClick = "community-vertical-card-click",
  CommunityVerticalHeaderClick = "community-vertical-header-click",
  CommunityEventCardRegisterClick = "community-event-card-register-click",
  ConnectWithRocketfyButtonClick = "connect-with-rocketfy-button-click",
  ProductsVerticalSeeProductsClick = "products-vertical-see-product-card-click",
  ProductsVerticalChinaAgentClick = "products-vertical-china-agent-card-click",
  RocketfySolutionsBannerButtonClick = "rocketfy-solutions-banner-button-click",
  RocketfyAcademyBannerButtonClick = "rocketfy-academy-banner-button-click",
  RocketfyProductsBannerButtonClick = "rocketfy-products-banner-button-click",
  AcademyVerticalCardClick = "academy-vertical-card-click",
  AcademyVerticalHeaderClick = "academy-vertical-header-click",
  AcademyDiplomaTabClick = "academy-diploma-tab-click",
  AcademyBootcampsReleaseTabClick = "academy-bootcamps-release-tab-click",
  AcademySpecializationsTabClick = "academy-specializations-tab-click",
  AcademyAdvancedCoursesTabClick = "academy-advanced-courses-tab-click",
  AcademyBootcampsAdvancedTabClick = "academy-bootcamps-advanced-tab-click",
  AcademyOrganizationsTabClick = "academy-organizations-tab-click",
  AcademyLiveSessionsTabClick = "academy-live-sessions-tab-click",
  AcademyBadgesTabClick = "academy-badges-tab-click",
  AcademyReportsTabClick = "academy-reports-tab-click",
  AcademyMasterclassesTabClick = "academy-master-classes-tab-click",

  // New definition
  Login = "log-in",
  StartProgram = "start-diplomado-button",
  ContinueProgram = "continue-diplomado-button",
  RepeatProgram = "repeat-diplomado-button",
  SeeProgramModule = "fase-diplomado-button",
  StartCourseKanban = "start-curso-button-kanban",
  ContinueCourseKanban = "continue-curso-button-kanban",
  RepeatCourseKanban = "repeat-curso-button-kanban",
  StartCourseCard = "start-curso-card-button",
  ContinueCourseCard = "continue-curso-card-button",
  RepeatCourseCard = "repeat-curso-card-button",
  SeeLessonsKanban = "view-lesson-list-button",
  LessonsTab = "tab-lecciones-button",
  LessonsSupportMaterialTab = "tab-materiales-button",
  CompleteLesson = "complete-lesson",
  RepeatLesson = "repeat-lesson",
  FinishCourse = "finish-course",
  ClickContentLesson = "session-list-nav",
  StartQuiz = "start-quiz",
  QuizNextQuestion = "quiz-next-question-button",
  QuizPreviousQuestion = "quiz-previous-question-button",
  SendQuizAnswers = "send-quiz-answers-button",
  SeeQuizResults = "quiz-results-button",
  RetryQuiz = "retry-quiz-button",
  StartSpecialization = "start-especializacion-button",
  ContinueSpecialization = "continue-especializacion-button",
  RepeatSpecialization = "repeat-especializacion-button",
  SeeSpecializationModule = "fase-especializacion-button",
  StartAdvancedCourseCard = "start-curso-avanzado-button",
  ContinueAdvancedCourseCard = "continue-curso-avanzado-button",
  RepeatAdvancedCourseCard = "repeat-curso-avanzado-button",
  BootcampRegistration = "bootcamp-grid-register",
  BootcampSeeAgenda = "bootcamp-grid-schedule",
  BootcampSeePreviousSessions = "bootcamp-grid-previous-sessions",
  BootcampAgendaTab = "bootcamp-schedule-button",
  BootcampPreviousSessionsTab = "bootcamp-previous-sessions-button",
  BootcampFrequentQuestionsTab = "bootcamp-frequent-questions-button",
  BootcampPreviousSessionsVideo = "bootcamp-previous-session-video",
  BootcampSeeFrequentQuestion = "bootcamp-faq-button",
  BootcampDownloadResources = "bootcamp-materiales-button",
  BootcampDownloadSessionResources = "bootcamp-download",
  BootcampJoinSession = "bootcamp-enter",
  MasterClassRegistration = "masterclass-register-button",
  JoinMasterclass = "masterclass-enter-button",
  TabProximasCnV = "cnv-tab-proximas-button",
  TabAgendadasCnV = "cnv-tab-agendadas-button",
  CnVDiplomadoFilter = "cnv-filtro-diplomado",
  CnVSpecializationFilter = "cnv-filtro-especializacion",
  EnterLiveSession = "cnv-ingresar",
  EnterLiveSessionFromPreview = "cnv-preview-ingresar",
  LeaveLiveSession = "cnv-salir",
  ReconnectLiveSession = "cnv-reconectar",
  CommunityEventCardRegister = "comunidad-registro",
  SolutionCardSeeMore = "soluciones-ver-mas",
  AccessSolution = "soluciones-acceder",
  SeeProducts = "productos-opcion-ver-lista",
  TalkToChinaAgent = "productos-opcion-agente",
  ConnectWithAgent = "productos-conectar-agente",
  SendChinaAgentForm = "productos-conectar-agente-enviar",
  ChinaAgentFormConfirmation = "productos-conectar-agente-gracias",
  HotProductsWeek = "productos-semana",
  ProductMonthSeeAll = "productos-semana-todos",
  ProductCardSeeDetails = "productos-detalles-button",
  DownloadProductReport = "productos-descargar-reporte-button",
  IWantProduct = "productos-formulario-button",
  SendProductForm = "productos-formulario-enviar",
  ProductFormConfirmation = "productos-formulario-gracias",
  LeaveLesson = "salir-lecciones-button",
  LeaveSpecialization = "salir-especializacion-button",
  AskLiveSessionQuestion = "cnv-preguntas",
  CancelLiveSessionAttendance = "cnv-cancelar-registro",
  RegisterLiveSessionAttendance = "cnv-guardar-puesto",
  LeaveSolutionDetail = "soluciones-salir",
  LeaveServiceDetail = "marketplace-service-salir",
  CommunityFilterClick = "comunidad-filtro",
  SolutionsFilterClick = "soluciones-filtro",
}

export enum GA4EventLabel {
  Login = "Iniciar sesión",
  StartProgram = "Comenzar un diplomado",
  ContinueProgram = "Continuar un diplomado",
  RepeatProgram = "Ver de nuevo un diplomado",
  SeeProgramModule = "Ver fase diplomado",
  StartCourseKanban = "Comenzar curso kanban",
  ContinueCourseKanban = "Continuar un curso kanban",
  RepeatCourseKanban = "Ver de nuevo un curso",
  StartCourseCard = "Comenzar un curso (Card)",
  ContinueCourseCard = "Continuar un curso (Card)",
  RepeatCourseCard = "Ve de nuevo un curso (Card)",
  SeeLessonsKanban = "Ver lecciones",
  LessonsTab = "Tab lecciones",
  LessonsSupportMaterialTab = "Tab materiales de apoyo",
  CompleteLesson = "Completar lección",
  RepeatLesson = "Repetir lección",
  FinishCourse = "Finalizar curso",
  ClickContentLesson = "Navegar entre las lecciones finalizadas",
  StartQuiz = "Empezar quiz",
  QuizNextQuestion = "Siguiente pregunta",
  QuizPreviousQuestion = "Anterior pregunta",
  SendQuizAnswers = "Ver resultados",
  SeeQuizResults = "Ver resultados quiz",
  RetryQuiz = "Repetir quiz",
  StartSpecialization = "Comenzar una especialización",
  ContinueSpecialization = "Continuar una especialización",
  RepeatSpecialization = "Ver de nuevo una especialización",
  SeeSpecializationModule = "Ver fase especializacion",
  StartAdvancedCourseCard = "Comenzar un curso avanzado",
  ContinueAdvancedCourseCard = "Continuar un curso avanzado",
  RepeatAdvancedCourseCard = "Ver de nuevo un curso avanzado",
  BootcampRegistration = "Registrarse a un bootcamp",
  BootcampSeeAgenda = "Ingresar a un bootcamp",
  BootcampSeePreviousSessions = "Ver sesiones previas",
  BootcampAgendaTab = "Menú agenda",
  BootcampFrequentQuestionsTab = "Menú sesiones previas",
  BootcampPreviousSessionsVideo = "Ver sesion previa",
  BootcampSeeFrequentQuestion = "Menú preguntas frequentes",
  BootcampDownloadResources = "Descargar material de apoyo",
  BootcampDownloadSessionResources = "Descargar material de sesión",
  BootcampJoinSession = "Entrar a la sesión",
  MasterClassRegistration = "Registrarse en masterclass",
  JoinMasterclass = "Ingresar a masterclass",
  TabProximasCnV = "Tab próximas clases en vivo",
  TabAgendadasCnV = "Tab agendadas clases en vivo",
  CnVDiplomadoFilter = "Filtro por diplomado",
  CnVSpecializationFilter = "Filtro por especializacion",
  EnterLiveSession = "Entrar a la clase",
  EnterLiveSessionFromPreview = "Entrar a la clase desde el preview",
  LeaveLiveSession = "Salir de clase en vivo",
  ReconnectLiveSession = "Reconectar",
  CommunityEventCardRegister = "Registrarse card comunidad",
  SolutionCardSeeMore = "Ver más card soluciones",
  AccessSolution = "Acceder a solución",
  SeeProducts = "Ver productos",
  TalkToChinaAgent = "Hablar con agente",
  ConnectWithAgent = "Conecta con agente",
  SendChinaAgentForm = "Enviar formulario agente en china",
  ChinaAgentFormConfirmation = "Confirmacion de formulario enviado",
  HotProductsWeek = "Seleccionar semana",
  ProductMonthSeeAll = "Ver todos",
  ProductCardSeeDetails = "Ver detalles",
  DownloadProductReport = "Descargar reportes",
  IWantProduct = "Quiero este producto",
  SendProductForm = "Enviar formulario producto",
  ProductFormConfirmation = "Confirmacion de formulario producto enviado",
  LeaveLesson = "Salir de la lección",
  LeaveSpecialization = "Salir de la especializacion",
  AskLiveSessionQuestion = "Preguntar",
  CancelLiveSessionAttendance = "Cancelar registro",
  RegisterLiveSessionAttendance = "Guardar puesto",
  LeaveSolutionDetail = "Salir",
  LeaveServiceDetail = "Salir Servicio",
  CommunityFilterClick = "Filtrar comunidad",
  SolutionsFilterClick = "Filtrar soluciones",
}

const useGA4Event = () => {
  const { firebaseAnalyticsApp } = useUser();
  const [analyticsApp, setAnalyticsApp] = useState<Analytics>();

  useEffect(() => {
    if (!firebaseAnalyticsApp) return;

    setAnalyticsApp(getAnalytics(firebaseAnalyticsApp));
  }, [firebaseAnalyticsApp]);

  const setUserProperty = (properties: CustomParams) => {
    if (!analyticsApp) return;
    setUserProperties(analyticsApp, properties);
  };

  const sendEvent = (
    {
      action,
      label,
      category,
    }: { action: string; label: string; category: string },
    params: unknown = {}
  ) => {
    if (!analyticsApp) return;

    logEvent(analyticsApp, action, {
      ...(params as object),
      category,
      label,
    });

    segment.track(action, {
      ...(params as object),
      category,
      label,
    });
  };

  return { sendEvent, setUserProperty };
};

export default useGA4Event;
