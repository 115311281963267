/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useColorModeValue } from "@chakra-ui/color-mode";
import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";

export const FlexCard = React.forwardRef<
  HTMLDivElement,
  Omit<FlexProps, "as"> & { children?: React.ReactNode }
>((props, ref) => {
  const bg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const color = useColorModeValue("inherit", "gray.300");

  return (
    <Flex
      as="article"
      color={color}
      border="1px solid"
      borderColor={borderColor}
      bg={bg}
      ref={ref}
      {...props}
    />
  );
});

FlexCard.displayName = "FlexCard";
