import * as Types from '../../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ValidateBannedUserQueryVariables = Types.Exact<{
  usersIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type ValidateBannedUserQuery = { __typename?: 'Query', searchById: Array<{ __typename?: 'User', isBanned?: boolean | null }> };


export const ValidateBannedUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ValidateBannedUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"usersIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"searchById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"usersIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"usersIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isBanned"}}]}}]}}]} as unknown as DocumentNode<ValidateBannedUserQuery, ValidateBannedUserQueryVariables>;