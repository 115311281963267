export enum TypeIcon {
  MUTE,
  SOUND,
  FULL_SCREEN,
  FULL_SCREEN_EXIT,
  MICROFONE_ON,
  MICROFONE_OFF,
  CAMERA_ON,
  CAMERA_OFF,
  SHARED_ON,
  SHARED_OFF,
  EndLive_ON,
  EndLive_OFF,
}

interface PropInterface {
  type: TypeIcon;
}

export const IconControlsFullScreen = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.FULL_SCREEN ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4.28571 14.5714C3.57857 14.5714 3 15.15 3 15.8571V19.7143C3 20.4214 3.57857 21 4.28571 21H8.14286C8.85 21 9.42857 20.4214 9.42857 19.7143C9.42857 19.0071 8.85 18.4286 8.14286 18.4286H5.57143V15.8571C5.57143 15.15 4.99286 14.5714 4.28571 14.5714ZM4.28571 9.42857C4.99286 9.42857 5.57143 8.85 5.57143 8.14286V5.57143H8.14286C8.85 5.57143 9.42857 4.99286 9.42857 4.28571C9.42857 3.57857 8.85 3 8.14286 3H4.28571C3.57857 3 3 3.57857 3 4.28571V8.14286C3 8.85 3.57857 9.42857 4.28571 9.42857ZM18.4286 18.4286H15.8571C15.15 18.4286 14.5714 19.0071 14.5714 19.7143C14.5714 20.4214 15.15 21 15.8571 21H19.7143C20.4214 21 21 20.4214 21 19.7143V15.8571C21 15.15 20.4214 14.5714 19.7143 14.5714C19.0071 14.5714 18.4286 15.15 18.4286 15.8571V18.4286ZM14.5714 4.28571C14.5714 4.99286 15.15 5.57143 15.8571 5.57143H18.4286V8.14286C18.4286 8.85 19.0071 9.42857 19.7143 9.42857C20.4214 9.42857 21 8.85 21 8.14286V4.28571C21 3.57857 20.4214 3 19.7143 3H15.8571C15.15 3 14.5714 3.57857 14.5714 4.28571Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M5.14286 16.5714H7.42857V18.8571C7.42857 19.4857 7.94286 20 8.57143 20C9.2 20 9.71429 19.4857 9.71429 18.8571V15.4286C9.71429 14.8 9.2 14.2857 8.57143 14.2857H5.14286C4.51429 14.2857 4 14.8 4 15.4286C4 16.0571 4.51429 16.5714 5.14286 16.5714ZM7.42857 7.42857H5.14286C4.51429 7.42857 4 7.94286 4 8.57143C4 9.2 4.51429 9.71429 5.14286 9.71429H8.57143C9.2 9.71429 9.71429 9.2 9.71429 8.57143V5.14286C9.71429 4.51429 9.2 4 8.57143 4C7.94286 4 7.42857 4.51429 7.42857 5.14286V7.42857ZM15.4286 20C16.0571 20 16.5714 19.4857 16.5714 18.8571V16.5714H18.8571C19.4857 16.5714 20 16.0571 20 15.4286C20 14.8 19.4857 14.2857 18.8571 14.2857H15.4286C14.8 14.2857 14.2857 14.8 14.2857 15.4286V18.8571C14.2857 19.4857 14.8 20 15.4286 20ZM16.5714 7.42857V5.14286C16.5714 4.51429 16.0571 4 15.4286 4C14.8 4 14.2857 4.51429 14.2857 5.14286V8.57143C14.2857 9.2 14.8 9.71429 15.4286 9.71429H18.8571C19.4857 9.71429 20 9.2 20 8.57143C20 7.94286 19.4857 7.42857 18.8571 7.42857H16.5714Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
};

export const IconControlsMicrophone = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.MICROFONE_ON ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.25362 3.18578C10.0129 2.42654 11.0426 2 12.1164 2C13.1901 2 14.2198 2.42654 14.9791 3.18578C15.7383 3.94503 16.1649 4.97478 16.1649 6.04852V10.1887C16.1649 11.2624 15.7383 12.2922 14.9791 13.0514C14.2198 13.8107 13.1901 14.2372 12.1164 14.2372C11.0426 14.2372 10.0129 13.8107 9.25362 13.0514C8.49437 12.2922 8.06783 11.2624 8.06783 10.1887V6.04852C8.06783 4.97478 8.49437 3.94503 9.25362 3.18578ZM12.1164 3.88679C11.543 3.88679 10.9932 4.11455 10.5878 4.51995C10.1824 4.92535 9.95462 5.47519 9.95462 6.04852V10.1887C9.95462 10.762 10.1824 11.3118 10.5878 11.7173C10.9932 12.1227 11.543 12.3504 12.1164 12.3504C12.6897 12.3504 13.2395 12.1227 13.6449 11.7173C14.0503 11.3118 14.2781 10.762 14.2781 10.1887V6.04852C14.2781 5.47519 14.0503 4.92535 13.6449 4.51995C13.2395 4.11455 12.6897 3.88679 12.1164 3.88679ZM4.80765 10.2902C5.32324 10.2151 5.80204 10.5723 5.87708 11.0878C6.09522 12.5867 6.84569 13.9568 7.99121 14.9477C9.13673 15.9386 10.6007 16.4839 12.1153 16.4839C13.6299 16.4839 15.0939 15.9386 16.2394 14.9477C17.3849 13.9568 18.1354 12.5867 18.3535 11.0878C18.4286 10.5723 18.9074 10.2151 19.423 10.2902C19.9386 10.3652 20.2957 10.844 20.2207 11.3596C19.9372 13.307 18.9621 15.0873 17.4738 16.3747C16.2249 17.455 14.6847 18.1277 13.0587 18.3162V21.0566C13.0587 21.5776 12.6363 22 12.1153 22C11.5943 22 11.1719 21.5776 11.1719 21.0566V18.3162C9.54596 18.1277 8.00574 17.455 6.75686 16.3747C5.26848 15.0873 4.29339 13.307 4.00996 11.3596C3.93492 10.844 4.29206 10.3652 4.80765 10.2902Z"
            fill="#00E324"
          />
          <rect x="9.5" y="3" width="5" height="10" rx="2.5" fill="#00E324" />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.25362 3.18578C10.0129 2.42654 11.0426 2 12.1164 2C13.1901 2 14.2198 2.42654 14.9791 3.18578C15.7383 3.94503 16.1649 4.97478 16.1649 6.04852V10.1887C16.1649 11.2624 15.7383 12.2922 14.9791 13.0514C14.2198 13.8107 13.1901 14.2372 12.1164 14.2372C11.0426 14.2372 10.0129 13.8107 9.25362 13.0514C8.49437 12.2922 8.06783 11.2624 8.06783 10.1887V6.04852C8.06783 4.97478 8.49437 3.94503 9.25362 3.18578ZM12.1164 3.88679C11.543 3.88679 10.9932 4.11455 10.5878 4.51995C10.1824 4.92535 9.95462 5.47519 9.95462 6.04852V10.1887C9.95462 10.762 10.1824 11.3118 10.5878 11.7173C10.9932 12.1227 11.543 12.3504 12.1164 12.3504C12.6897 12.3504 13.2395 12.1227 13.6449 11.7173C14.0503 11.3118 14.2781 10.762 14.2781 10.1887V6.04852C14.2781 5.47519 14.0503 4.92535 13.6449 4.51995C13.2395 4.11455 12.6897 3.88679 12.1164 3.88679ZM4.80765 10.2902C5.32324 10.2151 5.80204 10.5723 5.87708 11.0878C6.09522 12.5867 6.84569 13.9568 7.99121 14.9477C9.13673 15.9386 10.6007 16.4839 12.1153 16.4839C13.6299 16.4839 15.0939 15.9386 16.2394 14.9477C17.3849 13.9568 18.1354 12.5867 18.3535 11.0878C18.4286 10.5723 18.9074 10.2151 19.423 10.2902C19.9386 10.3652 20.2957 10.844 20.2207 11.3596C19.9372 13.307 18.9621 15.0873 17.4738 16.3747C16.2249 17.455 14.6847 18.1277 13.0587 18.3162V21.0566C13.0587 21.5776 12.6363 22 12.1153 22C11.5943 22 11.1719 21.5776 11.1719 21.0566V18.3162C9.54596 18.1277 8.00574 17.455 6.75686 16.3747C5.26848 15.0873 4.29339 13.307 4.00996 11.3596C3.93492 10.844 4.29206 10.3652 4.80765 10.2902Z"
            fill="#FF4848"
          />
          <rect x="9.5" y="3" width="5" height="10" rx="2.5" fill="#FF4848" />
          <path
            d="M3 4L19.9706 20.9706"
            stroke="#1E1E23"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4 3L20.9706 19.9706"
            stroke="#FF4848"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}
    </>
  );
};

export const IconControlsCamera = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.CAMERA_ON ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.0566 2C8.72107 2 8.41079 2.17822 8.24172 2.46805L6.92984 4.71698H5.20755C3.43607 4.71698 2 6.15305 2 7.92453V17.2075C2 18.979 3.43607 20.4151 5.20755 20.4151H18.7925C20.5639 20.4151 22 18.979 22 17.2076V7.92453C22 6.15305 20.5639 4.71698 18.7925 4.71698H17.0702L15.7583 2.46805C15.5892 2.17822 15.2789 2 14.9434 2H9.0566ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
            fill="#00E324"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.0566 2C8.72107 2 8.41079 2.17822 8.24172 2.46805L6.92984 4.71698H5.20755C3.43607 4.71698 2 6.15305 2 7.92453V17.2075C2 18.979 3.43607 20.4151 5.20755 20.4151H18.7925C20.5639 20.4151 22 18.979 22 17.2076V7.92453C22 6.15305 20.5639 4.71698 18.7925 4.71698H17.0702L15.7583 2.46805C15.5892 2.17822 15.2789 2 14.9434 2H9.0566ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
            fill="#FF4848"
          />
          <path
            d="M3 3.5752L21 22.0752"
            stroke="#1E1E23"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4 2.5752L22 21.0752"
            stroke="#FF4848"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      )}
    </>
  );
};

export const IconControlsSharedScreen = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.SHARED_ON ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.3 19H12.7C17.2 19 19 17.2 19 12.7V7.3C19 2.8 17.2 1 12.7 1H7.3C2.8 1 1 2.8 1 7.3V12.7C1 17.2 2.8 19 7.3 19Z"
            fill="#FF4848 "
            stroke="#FF4848"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.8623 8.65L9.8873 6.625L11.9123 8.65"
            stroke="#1E1E23"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.8877 6.625V12.025"
            stroke="#1E1E23"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.3 19H12.7C17.2 19 19 17.2 19 12.7V7.3C19 2.8 17.2 1 12.7 1H7.3C2.8 1 1 2.8 1 7.3V12.7C1 17.2 2.8 19 7.3 19Z"
            fill="#00E324"
            stroke="#00E324"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.8623 8.65L9.8873 6.625L11.9123 8.65"
            stroke="#1E1E23"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.8877 6.625V12.025"
            stroke="#1E1E23"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};

export const IconControlsEnd = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.EndLive_ON ? (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="6" fill="#FF4848" />
          <path
            d="M11.9973 9C8.65835 9.00669 5.5584 9.62248 4.01216 11.008C3.31748 11.6305 2.95893 12.3868 3.00375 13.3105C3.03363 13.8527 3.22784 14.3279 3.58639 14.6559C3.87024 14.9035 4.25867 15.0508 4.71432 14.9838L7.49307 14.5622C7.92632 14.5019 8.22511 14.3814 8.41932 14.2074C8.66582 13.9865 8.74052 13.6653 8.74052 13.2302L8.74799 12.5408C8.74799 12.4337 8.80028 12.3534 8.86751 12.2864C8.94967 12.2061 9.06172 12.166 9.15136 12.1526C9.66677 12.0455 10.72 11.9384 12.0048 11.9384C13.2821 11.9384 14.3354 12.0187 14.8508 12.1526C14.933 12.1726 15.045 12.2128 15.1272 12.2864C15.1944 12.3534 15.2392 12.4337 15.2467 12.5341L15.2541 13.2302C15.2616 13.6653 15.3363 13.9865 15.5828 14.2074C15.777 14.3814 16.0758 14.5019 16.5091 14.5622L19.2579 14.9772C19.7285 15.0508 20.1244 14.8968 20.4232 14.6291C20.7818 14.3212 20.9835 13.846 20.9984 13.2971C21.0283 12.3801 20.6398 11.6238 19.9526 11.008C18.4064 9.62248 15.3438 9 11.9973 9Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="6" fill="#FF4848" />
          <path
            d="M11.9973 9C8.65835 9.00669 5.5584 9.62248 4.01216 11.008C3.31748 11.6305 2.95893 12.3868 3.00375 13.3105C3.03363 13.8527 3.22784 14.3279 3.58639 14.6559C3.87024 14.9035 4.25867 15.0508 4.71432 14.9838L7.49307 14.5622C7.92632 14.5019 8.22511 14.3814 8.41932 14.2074C8.66582 13.9865 8.74052 13.6653 8.74052 13.2302L8.74799 12.5408C8.74799 12.4337 8.80028 12.3534 8.86751 12.2864C8.94967 12.2061 9.06172 12.166 9.15136 12.1526C9.66677 12.0455 10.72 11.9384 12.0048 11.9384C13.2821 11.9384 14.3354 12.0187 14.8508 12.1526C14.933 12.1726 15.045 12.2128 15.1272 12.2864C15.1944 12.3534 15.2392 12.4337 15.2467 12.5341L15.2541 13.2302C15.2616 13.6653 15.3363 13.9865 15.5828 14.2074C15.777 14.3814 16.0758 14.5019 16.5091 14.5622L19.2579 14.9772C19.7285 15.0508 20.1244 14.8968 20.4232 14.6291C20.7818 14.3212 20.9835 13.846 20.9984 13.2971C21.0283 12.3801 20.6398 11.6238 19.9526 11.008C18.4064 9.62248 15.3438 9 11.9973 9Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
};

export const OnFullScreenIcon = ({ type }: PropInterface) => {
  return (
    <>
      {type === TypeIcon.FULL_SCREEN ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4.28571 14.5714C3.57857 14.5714 3 15.15 3 15.8571V19.7143C3 20.4214 3.57857 21 4.28571 21H8.14286C8.85 21 9.42857 20.4214 9.42857 19.7143C9.42857 19.0071 8.85 18.4286 8.14286 18.4286H5.57143V15.8571C5.57143 15.15 4.99286 14.5714 4.28571 14.5714ZM4.28571 9.42857C4.99286 9.42857 5.57143 8.85 5.57143 8.14286V5.57143H8.14286C8.85 5.57143 9.42857 4.99286 9.42857 4.28571C9.42857 3.57857 8.85 3 8.14286 3H4.28571C3.57857 3 3 3.57857 3 4.28571V8.14286C3 8.85 3.57857 9.42857 4.28571 9.42857ZM18.4286 18.4286H15.8571C15.15 18.4286 14.5714 19.0071 14.5714 19.7143C14.5714 20.4214 15.15 21 15.8571 21H19.7143C20.4214 21 21 20.4214 21 19.7143V15.8571C21 15.15 20.4214 14.5714 19.7143 14.5714C19.0071 14.5714 18.4286 15.15 18.4286 15.8571V18.4286ZM14.5714 4.28571C14.5714 4.99286 15.15 5.57143 15.8571 5.57143H18.4286V8.14286C18.4286 8.85 19.0071 9.42857 19.7143 9.42857C20.4214 9.42857 21 8.85 21 8.14286V4.28571C21 3.57857 20.4214 3 19.7143 3H15.8571C15.15 3 14.5714 3.57857 14.5714 4.28571Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4.28571 14.5714C3.57857 14.5714 3 15.15 3 15.8571V19.7143C3 20.4214 3.57857 21 4.28571 21H8.14286C8.85 21 9.42857 20.4214 9.42857 19.7143C9.42857 19.0071 8.85 18.4286 8.14286 18.4286H5.57143V15.8571C5.57143 15.15 4.99286 14.5714 4.28571 14.5714ZM4.28571 9.42857C4.99286 9.42857 5.57143 8.85 5.57143 8.14286V5.57143H8.14286C8.85 5.57143 9.42857 4.99286 9.42857 4.28571C9.42857 3.57857 8.85 3 8.14286 3H4.28571C3.57857 3 3 3.57857 3 4.28571V8.14286C3 8.85 3.57857 9.42857 4.28571 9.42857ZM18.4286 18.4286H15.8571C15.15 18.4286 14.5714 19.0071 14.5714 19.7143C14.5714 20.4214 15.15 21 15.8571 21H19.7143C20.4214 21 21 20.4214 21 19.7143V15.8571C21 15.15 20.4214 14.5714 19.7143 14.5714C19.0071 14.5714 18.4286 15.15 18.4286 15.8571V18.4286ZM14.5714 4.28571C14.5714 4.99286 15.15 5.57143 15.8571 5.57143H18.4286V8.14286C18.4286 8.85 19.0071 9.42857 19.7143 9.42857C20.4214 9.42857 21 8.85 21 8.14286V4.28571C21 3.57857 20.4214 3 19.7143 3H15.8571C15.15 3 14.5714 3.57857 14.5714 4.28571Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
};
