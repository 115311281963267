import { Ico } from "@/assets/icons";
import { Flex, Box, Heading, Button, Image, Text } from "@chakra-ui/react";
import { usePresenter } from "../presenter/usePresenter";

export const ContactMeWidget: React.FC = () => {
  const { currentServiceForView: currentService, isMobile } = usePresenter();

  const handleContactMeClick = () => {
    window.open(currentService?.contactLink ?? "", "_blank");
  };
  const handleHelpClick = () => {
    window.open("https://google.com", "_blank");
  };

  return (
    <Flex
      px={"24px"}
      py={"16px"}
      w={isMobile ? "100%" : "40%"}
      minH={"190px"}
      maxH={isMobile ? "220px" : "260px"}
      minW={isMobile ? undefined : "352px"}
      position={isMobile ? "fixed" : "initial"}
      bottom={0}
      left={0}
      direction={"column"}
      justifyContent={"center"}
      textAlign={"center"}
      rounded={"5px"}
      border={"1px solid"}
      borderColor={"neutral.100"}
      background={isMobile ? "white" : "none"}
      boxShadow={isMobile ? "" : "none"}
    >
      <Flex direction={"row"} gap={"16px"} alignItems={"center"} w={"100%"}>
        <Box
          borderRadius={"250px"}
          border={"1px solid"}
          borderColor={"neutral.100"}
          p={"4px"}
          w={"40px"}
          h={"40px"}
          mb={"16px"}
          overflow={"hidden"}
        >
          <Image
            w={"40px"}
            maxW={"40px"}
            h={"40px"}
            maxH={"40px"}
            mx={"auto"}
            src={
              currentService?.agencyImage && currentService?.agencyImage !== ""
                ? currentService?.agencyImage
                : "/img/logo_smartbeemo.svg"
            }
          ></Image>
        </Box>
        <Box mx={2} mb={2}>
          <Heading as={"p"}>{currentService?.agency}</Heading>
        </Box>
      </Flex>
      <Flex
        pt={"4px"}
        direction={"row"}
        align={"center"}
        textAlign={"center"}
        justifyContent={"center"}
        gap={1}
      >
        <Ico.Clock fontSize={"18px"} color={"#69696C"}></Ico.Clock>
        <Text color={"secondary.100"} fontSize={"14px"}>
          Tiempo de respuesta: 24 horas
        </Text>
      </Flex>
      <Button
        mt={"32px"}
        mb={"16px"}
        h={isMobile ? "36px" : "auto"}
        variant={"primary"}
        onClick={handleContactMeClick}
      >
        <Text mr={2} fontWeight={"500"} fontSize={"14px"}>
          Contáctame
        </Text>
        <Ico.PaperPlane fontSize={"18px"}></Ico.PaperPlane>
      </Button>
      <Button
        variant={"link"}
        onClick={handleHelpClick}
        h={isMobile ? "36px" : "auto"}
      >
        <Text mr={2} fontWeight={"500"} fontSize={"14px"} py={"8px"}>
          Necesito ayuda
        </Text>
      </Button>
    </Flex>
  );
};
