import { createContext } from "react";

import type { UserSettings } from "@/schemaTypes";

type UserSettingsValue = Omit<UserSettings, "__typename" | "userId">;
interface UserSettingsState {
  settings?: UserSettingsValue;
  update: (settings: UserSettingsValue) => void;
}

const UserSettingsContext = createContext<UserSettingsState | null>(null);

export type { UserSettingsState, UserSettingsValue };
export { UserSettingsContext };
