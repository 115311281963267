import React from "react";
import {
  Box,
  Modal,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Center,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import flashes from "/img/flashes.svg";
import { Trans } from "react-i18next";
import { Button } from "../Button";

enum HeadingTypes {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
}

interface ModalInteractiveContentProps {
  isOpen: boolean;
  onClose: () => void;
  badge?: string;
  badges?: [];
  title?: string;
  description?: string;
  showShining?: boolean;
  headingSize: string;
}
const ModalInteractiveContent: React.FC<ModalInteractiveContentProps> = ({
  isOpen,
  onClose,
  badge,
  badges,
  title,
  description,
  showShining,
  headingSize,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="8px" py="28px">
        <ModalCloseButton mr={"8px"} mt={"12px"} />
        <ModalBody>
          <Flex justify="center" align="center" flexDir="column">
            {badge && (
              <Box pos="relative" display="inline-block" mt={"40px"}>
                {showShining ? (
                  <Center>
                    <Image
                      pos="absolute"
                      src={badge}
                      w="40%"
                      alt="StartQuizImg"
                      textAlign="center"
                      zIndex="1"
                    />
                    <Image
                      display="block"
                      src={flashes}
                      w={"120%"}
                      alt="flashes"
                      textAlign="center"
                      zIndex="-1"
                    />
                  </Center>
                ) : (
                  <Center>
                    <Image
                      display="block"
                      src={badge}
                      w="120%"
                      alt="StartQuizImg"
                      textAlign="center"
                      zIndex="1"
                    />
                  </Center>
                )}
              </Box>
            )}
            {badges?.map((insignia) => (
              <Box key={insignia} pos="relative" display="inline-block">
                {showShining ? (
                  <Center>
                    <Image
                      pos="absolute"
                      src={badge}
                      w="50%"
                      alt="StartQuizImg"
                      textAlign="center"
                      zIndex="1"
                    />
                    <Image
                      display="block"
                      src={flashes}
                      w={"120%"}
                      alt="flashes"
                      textAlign="center"
                      zIndex="-1"
                    />
                  </Center>
                ) : (
                  <Center>
                    <Image
                      pos="absolute"
                      src={badge}
                      w="55%"
                      alt="StartQuizImg"
                      textAlign="center"
                      zIndex="1"
                    />
                  </Center>
                )}
              </Box>
            ))}
            {title && headingSize === HeadingTypes.h4 && (
              <Box mt="24px">
                <Heading textAlign={"center"} as={"h4"}>
                  <Trans>{title}</Trans>
                </Heading>
              </Box>
            )}
            {title && headingSize === HeadingTypes.h6 && (
              <Box mt="24px">
                <Heading textAlign={"center"} as={"h6"}>
                  <Trans>{title}</Trans>
                </Heading>
              </Box>
            )}
            {description && (
              <Box mt="16px">
                <Text textAlign={"center"} variant="caption">
                  <Trans>{description}</Trans>
                </Text>
              </Box>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            w="full"
            p="8px"
            borderRadius="100px"
            border="2px solid var(--Terciarios-Main, #FFD700)"
            bg="radial-gradient(circle at 17.58% 0%, #FFF580 0%, #FFD700 52%, #FFF580 100%)"
            onClick={onClose}
          >
            <Trans>Continue</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalInteractiveContent;
