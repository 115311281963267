import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import { Button } from "@/components/Button";
import { BackButton } from "@/components/PageContainer/BackButton";
import {
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PasswordCreatedCard = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const navigate = useNavigate();

  return (
    <Flex
      w={isMobile ? "100%" : "491px"}
      px={isMobile ? "16px" : "24px"}
      py={isMobile ? "0" : "40px"}
      mt={isMobile ? "0" : "13rem"}
      textAlign={"center"}
      border={"1px solid"}
      borderColor={isMobile ? "transparent" : "neutral.200"}
      borderRadius={"8px"}
      flexDir={"column"}
      align={"center"}
      justify={"center"}
    >
      {isMobile && (
        <Flex
          w={"100vw"}
          flexDir={"column"}
          align={"center"}
          px={isMobile ? "16px" : "48px"}
          pt={isMobile ? "24px" : "48px"}
          mb={isMobile ? "0" : "100px"}
        >
          <BackButton showLabel={false} to={AppRoute.Base} />

          <Image
            alt={`Smartbeemo Logo`}
            h={"100%"}
            w={isMobile ? "232px" : "287px"}
            mt={"24px"}
            mb={isMobile ? "48px" : "56px"}
            objectFit={"contain"}
            src={"/img/logo_smartbeemo.svg"}
          />
        </Flex>
      )}

      <Flex flexDir={"column"} align={"center"} justify={"center"}>
        <Image
          alt={`Password Created`}
          h={"100%"}
          w={isMobile ? "168px" : "153px"}
          mb={isMobile ? "48px" : "56px"}
          objectFit={"contain"}
          src={"/img/reviewEmailSuccess.svg"}
        />

        <Heading as={"h5"} mb={"16px"}>
          <Trans>Your password was created</Trans>
        </Heading>

        <Text mb={isMobile ? "40px" : "56px"} variant={"body"}>
          <Trans>Enjoy the new experience</Trans>
        </Text>

        <Button
          gap={"12px"}
          w={"100%"}
          title="Go to portal"
          variant="primary"
          onClick={() => navigate(AppRoute.Base)}
          rightIcon={<Ico.ArrowRight fontSize={25} />}
        />
      </Flex>
    </Flex>
  );
};

export default PasswordCreatedCard;
