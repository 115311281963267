import { useContext } from "react";
import { GatewayLayerContext, GatewayLayerState } from "./context";

const useGateway = (): GatewayLayerState => {
  const ctx = useContext(GatewayLayerContext);
  if (ctx === null) {
    throw Error("ServiceMarketplace context provider not found");
  }
  return ctx;
};

export { useGateway };
