import { SBErrorPubSub } from "@/utils/errors/SBError";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

declare global {
  interface Window {
    dataLayer: Array<{ [key: string]: unknown }>;
  }
}
const useTrackPageViews = () => {
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    function pushPageView() {
      try {
        if (!window.dataLayer) {
          setError("window.dataLayer is not defined");
          return;
        }
        window.dataLayer.push({
          event: "pageview",
          page: {
            path: location.pathname + location.search + location.hash,
          },
        });
      } catch (err: unknown) {
        const errorMessage =
          err instanceof Error ? err.message : "An unknown error occurred";
        setError(errorMessage);

        SBErrorPubSub.publish({
          component: "useTrackPageViews.ts line 31",
          message: errorMessage,
          showInProd: true,
        });
      }
    }
    pushPageView();
    return () => setError(null);
  }, [location]);
  return { error };
};

export default useTrackPageViews;
