import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Ico } from "@/assets/icons";
import DateTimeInterface from "@/utils/dateTimeFormat/dateTimeInteface";
import DateTimeMoment from "@/utils/dateTimeFormat/dateTimeMoment";
import { useUser } from "@/providers/useUser";
import { useEffect, useState } from "react";
import moment from "moment";

interface AlertSubscriptionProps {
  programTitles: Array<string>;
  availableUntil: string;
}

const AlertProgramsToDeprecate = ({
  programTitles,
  availableUntil,
}: AlertSubscriptionProps): JSX.Element => {
  const { t } = useTranslation();
  const [startDateFormatted, setStartDateFormatted] = useState("");

  const dateTime: DateTimeInterface = new DateTimeMoment();
  const { geolocation } = useUser();
  const calculateStartDateFormatted = (dateMoment: moment.Moment) => {
    const convertLocalTime = dateTime.convertLocalTime(
      dateMoment,
      geolocation.location
    );

    let format = dateTime.fortmatDate(convertLocalTime, {
      weekday: "long",
      month: "long",
      day: "numeric",
    });

    format = format.charAt(0).toLocaleLowerCase() + format.slice(1).toString();

    setStartDateFormatted(format);
  };

  useEffect(() => {
    if (availableUntil) {
      calculateStartDateFormatted(moment(availableUntil));
    }
  }, [availableUntil]);

  let programsText = "";
  const singleOrPluralPrograms =
    programTitles.length > 1
      ? "Los siguientes programas estarán disponibles hasta el "
      : "El siguiente programa estará disponible hasta el ";
  if (programTitles.length > 2 && programTitles) {
    let i = 0;
    while (i < programTitles.length - 1) {
      if (i === programTitles.length - 2) {
        programsText += programTitles[i] + " y ";
      } else {
        programsText += programTitles[i] + ", ";
      }
      i++;
    }
    programsText += programTitles[i];
  } else if (programTitles.length === 2) {
    programsText += programTitles[0] + " y " + programTitles[1];
  } else {
    programsText += programTitles[0];
  }
  return (
    <Flex
      align={{ lg: "center" }}
      bgColor={"error.50"}
      gap={"8px"}
      justify={"center"}
      p={{ base: "16px", lg: "8px" }}
    >
      <Text
        as={Ico.TriangleWarning}
        boxSize={"24px"}
        color={"error.400"}
        flexShrink={0}
      />
      <Text>
        <strong>{`${t("Aviso importante : ")}`}</strong>
        {`${t(singleOrPluralPrograms)}`}
        {startDateFormatted + ":"}
        {programTitles ? (
          <Text
            display={{ base: "block", lg: "inline" }}
            m={{ base: "8px 0 0 0", lg: "0 0 0 8px" }}
          >
            {programsText}
          </Text>
        ) : null}
      </Text>
    </Flex>
  );
};

export { AlertProgramsToDeprecate };
