import { createContext } from "react";
import type { Category, MarketplaceService } from "@/schemaTypes";

type DataLayerValue = {
  servicesForPresenter: (MarketplaceService | null)[] | null | undefined;
  searchServicesForPresenter: (MarketplaceService | null)[] | null | undefined;
  filteredServicesForPresenter:
    | (MarketplaceService | null)[]
    | null
    | undefined;
  categoriesForPresenter: Array<Category>;
  subCategoriesForPresenter: Array<Category>;
  currentServiceForPresenter: MarketplaceService | null | undefined;
  searchServicesCallback: (term: string | null) => void;
  filterData: (
    selectedMainCategory: string,
    selectedSubcategories: string[]
  ) => void;
};

interface DataLayerState {
  servicesForPresenter?: (MarketplaceService | null)[] | null | undefined;
  searchServicesForPresenter?: (MarketplaceService | null)[] | null | undefined;
  filteredServicesForPresenter?:
    | (MarketplaceService | null)[]
    | null
    | undefined;
  currentServiceForPresenter?: MarketplaceService | null | undefined;
  categoriesForPresenter?: Array<Category>;
  subCategoriesForPresenter?: Array<Category>;
  searchServicesCallback: (term: string | null) => void;
  filterData: (
    selectedMainCategory: string,
    selectedSubcategories: string[]
  ) => void;
}
const DataLayerContext = createContext<DataLayerValue | null>(null);

export type { DataLayerState, DataLayerValue };
export { DataLayerContext };
