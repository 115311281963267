import { useEffect } from "react";

import { config } from "@/config";

const cssStyles = `
    .dockableContainer.showDockableContainer {
        z-index: 99999;
    }
    .embeddedServiceHelpButton .helpButton .uiButton,
    .embeddedServiceSidebarMinimizedDefaultUI.newMessage,
    .embeddedServiceSidebarMinimizedDefaultUI.idleTimeout {
        background-color: #ffc800;
        font-family: "Salesforce Sans", sans-serif;
    }
    .embeddedServiceHelpButton .helpButton .uiButton:focus {
        outline: 1px solid #ffc800;
    }
    .embeddedServiceHelpButton .helpButton .message,
    .embeddedServiceSidebarMinimizedDefaultUI .minimizedText .message,
    .embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:focus,
    .helpButtonEnabled:focus .helpButtonLabel,
    .embeddedServiceHelpButton .embeddedServiceIcon::before {
        color: #000000 !important;
        text-decoration: none !important;
    }
    @font-face {
        font-family: 'Salesforce Sans';
        src: url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.woff') format('woff'),
        url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.ttf') format('truetype');
    }
`;

const logoScript = `
<script src="<?php echo get_stylesheet_directory_uri(); ?>/js/logo.js"></script>
`;

const mainScript = `
var initESW = function(gslbBaseURL) {
    embedded_svc.settings.displayHelpButton = true; //O falso
    embedded_svc.settings.language = ''; //Por ejemplo, introduzca 'en' o 'en-US'

    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    embedded_svc.settings.entryFeature = 'LiveAgent';
    embedded_svc.settings.defaultMinimizedText = "Hablemos aquí";
    embedded_svc.settings.disabledMinimizedText = "Hablemos aquí";
    embedded_svc.settings.loadingText = "Cargando...";
    embedded_svc.settings.prechatBackgroundImgURL = "https://smartbeemo.com/wp-content/uploads/2021/12/sb-logo-320x100-1.svg";
    embedded_svc.settings.smallCompanyLogoImgURL = "https://smartbeemo.com/wp-content/uploads/2021/12/sb-beemo-36x36-1.svg";
    embedded_svc.settings.waitingStateBackgroundImgURL = "https://smartbeemo.com/wp-content/uploads/2021/12/sb-logo-250x60-1.svg";
    embedded_svc.settings.avatarImgURL = "https://smartbeemo.com/wp-content/uploads/2021/12/sb-beemo-40x40-1.svg";
    embedded_svc.settings.chatbotAvatarImgURL = "https://smartbeemo.com/wp-content/uploads/2021/12/sb-beemo-40x40-1.svg";

    embedded_svc.init(
        'https://smartbeemo.my.salesforce.com',
        'https://smartbeemo.my.salesforce-sites.com/chatestudiantes',
        gslbBaseURL,
        '${config.SALESFORCE_CHAT_ORGID}',
        'Chat_Estudiantes',
        {
            baseLiveAgentContentURL: 'https://c.la4-c2-ia4.salesforceliveagent.com/content',
            deploymentId: '${config.SALESFORCE_CHAT_DEPLOYMENTID}',
            buttonId: '${config.SALESFORCE_CHAT_BUTTONID}',
            baseLiveAgentURL: 'https://d.la4-c2-ia4.salesforceliveagent.com/chat',
            eswLiveAgentDevName: 'Chat_Estudiantes',
            isOfflineSupportEnabled: false
        }
    );
};

if (!window.embedded_svc) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://smartbeemo.my.salesforce.com/embeddedservice/5.0/esw.min.js');
    s.onload = function() {
        initESW(null);
    };
    document.body.appendChild(s);
} else {
    initESW('https://service.force.com');
}
`;

document.body.innerHTML += logoScript;
eval(mainScript);

const useChat = (): void => {
  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.appendChild(document.createTextNode(cssStyles));

    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
};

export { useChat };
