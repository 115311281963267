import { Ico } from "@/assets/icons";
import { InputGroup, InputLeftAddon, Select } from "@chakra-ui/react";
import { MediaDevice } from "@zoom/videosdk";

interface SpeakerProps {
  activeSpeaker: string;
  speakerList: MediaDevice[];
  onSpeakerChange: (deviceId: string) => void;
}

const SpeakerControl: React.FC<SpeakerProps> = (props) => {
  const { activeSpeaker, speakerList, onSpeakerChange } = props;

  return (
    <InputGroup
      border="1px solid"
      borderColor="neutral.300"
      borderRadius={"8px"}
    >
      <InputLeftAddon pointerEvents="none" bg={"transparent"}>
        <Ico.Headphones fontSize={"24px"} />
      </InputLeftAddon>
      <Select
        value={activeSpeaker}
        onChange={(e) => onSpeakerChange(e.target.value)}
        variant="outline"
        borderColor="neutral.300"
        color="secondary.300"
        placeholder="Audífono externo"
        focusBorderColor="neutral.300"
        borderLeftColor={"transparent"}
        borderLeftRadius={"0"}
        iconColor="primary.500"
        _selected={{ borderLeftColor: "transparent", borderLeftRadius: "0" }}
        _focus={{ borderLeftColor: "transparent", borderLeftRadius: "0" }}
        _focusWithin={{ borderLeftColor: "transparent", borderLeftRadius: "0" }}
      >
        {speakerList.map((speaker) => (
          <option key={speaker.deviceId} value={speaker.deviceId}>
            {speaker.label}
          </option>
        ))}
      </Select>
    </InputGroup>
  );
};

export default SpeakerControl;
