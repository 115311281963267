/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";
import { GraphQLError } from "graphql";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import { isPublicRoute } from "./AppRoute";
import { browserCacheLink } from "./browserCacheHttpLink";
import { config } from "./config";
import { refreshToken } from "./services/authService";
import { SBErrorPubSub } from "./utils/errors/SBError";
import { omitTypenameDeep } from "./utils/omitTypenameDeep";

const removeTypename = new ApolloLink((operation, forward) => {
  const newOperation = operation;
  newOperation.variables = omitTypenameDeep(newOperation.variables);
  return forward(newOperation);
});

const httpLink = createHttpLink({
  uri: config.GRAPHQL_URL,
  credentials: "include",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.GRAPHQL_URL,
    connectionParams: {
      authToken: localStorage.getItem(config.JWT_KEY),
    },
  })
);

const oneMinute = 1000 * 60;

const authHandler = setContext((_, { headers }) => {
  const token = localStorage.getItem(config.JWT_KEY);
  const tokenExpiration = parseInt(
    localStorage.getItem(config.JWT_EXPIRATION_KEY) ?? "-1",
    10
  );

  if (
    !isNaN(tokenExpiration) &&
    tokenExpiration > 0 &&
    tokenExpiration - oneMinute < Date.now()
  ) {
    // Refresh token preemptively
    refreshToken().catch((error) =>
      SBErrorPubSub.publish({
        component: "apollo",
        message: error.message,
        showInProd: true,
      })
    );
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const featureTogglesHandler = setContext((_, { headers }) => {
  const featureToggles = localStorage.getItem(config.FT_KEY);
  return {
    headers: {
      ...headers,
      "x-ada-feature-toggles":
        featureToggles && featureToggles.length > 0 ? featureToggles : "",
    },
  };
});

const checkJWTError = (
  errors: readonly GraphQLError[],
  keys: Array<string>,
  codes: Array<string> = []
) =>
  errors.some(
    (error) =>
      keys.some((key) =>
        error.message.toLowerCase().includes(key.toLowerCase())
      ) ||
      codes.some((code) =>
        String(error.extensions?.code)
          .toLowerCase()
          .includes(code.toLowerCase())
      )
  );
const checkNetworkError = (
  error: NetworkError | undefined,
  keys: Array<string>
) => keys.some((key) => error?.message.includes(key));

const errorHandler = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    if (
      operation.operationName === "getCurrentSchool" &&
      checkNetworkError(networkError, ["Failed to fetch"])
    ) {
      // eslint-disable-next-line no-console
      console.log("get current school error");
    }
    if (
      checkJWTError(
        graphQLErrors,
        ["Unauthorized", "Invalid token", "jwt expired", "Token expired"],
        ["UNAUTHENTICATED"]
      )
    ) {
      localStorage.removeItem(config.JWT_KEY);

      refreshToken()
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          if (
            window.location.pathname !== "/" &&
            !isPublicRoute(window.location.pathname)
          ) {
            window.location.assign(
              `${window.location.protocol}//${window.location.host}/?logoutReason=session-timeout&loginRedirect=`
            );
          }
        });
    } else if (
      checkJWTError(graphQLErrors, [
        "jwt malformed",
        "invalid signature",
        "Invalid refresh token",
        "School ID mismatch",
      ])
    ) {
      localStorage.removeItem(config.JWT_KEY);
      localStorage.removeItem(config.RT_KEY);
      if (
        window.location.pathname !== "/" &&
        !isPublicRoute(window.location.pathname)
      ) {
        window.location.assign(
          `${window.location.protocol}//${window.location.host}/?logoutReason=malformed-token&loginRedirect=`
        );
      }
    } else if (!checkJWTError(graphQLErrors, ["PersistedQueryNotFound"])) {
      const messages: Array<string> = [];
      graphQLErrors.forEach((error) => {
        const { message, path } = error;
        messages.push(message);
        // eslint-disable-next-line no-console
        console.error(
          `[GraphQL error]: Message: ${message}, Path: ${path}`,
          error
        );
      });
      SBErrorPubSub.publish({
        component: "apollo",
        message: messages.join(", "),
      });
    }
  } else if (networkError) {
    if (
      operation.operationName === "getCurrentSchool" &&
      checkNetworkError(networkError, ["Failed to fetch"])
    ) {
      SBErrorPubSub.publish({
        component: "apollo",
        message: "School not found",
      });
    } else {
      // eslint-disable-next-line no-console
      console.error("[Network error]", networkError);
      SBErrorPubSub.publish({
        component: "apollo",
        message: networkError.message,
      });
    }
  }
});

const persistedQuery = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
  link: ApolloLink.from([
    removeTypename,
    authHandler,
    featureTogglesHandler,
    browserCacheLink,
    persistedQuery,
    errorHandler,
    splitLink,
  ]),
  credentials: "include",
});
