import { Flex, Text } from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import { ValidateBootcampEnrrollments } from "@/schemaTypes";
import { useTranslation } from "react-i18next";
import {
  formatDate,
  formatTime12h,
} from "@/utils/dateTimeFormat/dateTimeFormats";
import { ModalBodyContentProps } from "./utils";

export const ModalBodyContent = ({ data }: ModalBodyContentProps) => {
  const { t } = useTranslation();

  if (!data) return null;

  const isSuccess = data.type === ValidateBootcampEnrrollments.Success;

  return (
    <Flex flexDirection="column" gap={4} alignItems="center">
      {isSuccess && data.bootcamp?.release?.title && (
        <Text
          fontSize={{ base: "14px", lg: "16px" }}
          color="secondary.300"
          fontWeight="600"
          textAlign="center"
        >
          {data.bootcamp.release.title}
        </Text>
      )}
      {data.message && (
        <Text
          fontSize={{ base: "14px", lg: "16px" }}
          color="secondary.300"
          fontWeight="400"
          textAlign="center"
        >
          {data.message}
        </Text>
      )}
      {isSuccess && data.bootcamp?.release?.startDate && (
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          gap={4}
          mt={4}
          alignItems="center"
        >
          <Flex gap={2} alignItems="center">
            <Ico.CalendarCheck fontSize="20px" color="#69696C" />
            <Text fontSize="14px" color="secondary.300" fontWeight="600">
              {t("Start bc")}{" "}
              <Text as="span">
                {formatDate(data.bootcamp.release.startDate.toString())}
              </Text>
            </Text>
          </Flex>
          <Flex gap={2} alignItems="center">
            <Ico.Clock fontSize="20px" color="#69696C" />
            <Text fontSize="14px" color="secondary.300" fontWeight="600">
              {formatTime12h(data.bootcamp.release.startDate.toString())}
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
