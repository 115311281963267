import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import ZoomMediaContext from "../context/media-context";
import ZoomClientContext from "../context/zoom-context";
import { useVideoState } from "../context/videoState/useVideoState";
import { DISPATCH_TYPE } from "../types/LiveBar.type";

enum STATUS_CAMERA {
  CONTENT = "CONTENT",
  NO_CONTENT = "NO-CONTENT",
}

export const useGetVideosUser = (
  setstatusCamera: Dispatch<SetStateAction<string>>
) => {
  const { zmClient } = useContext(ZoomClientContext);
  const zoomMediaContext = useContext(ZoomMediaContext);
  const { dispatch } = useVideoState();

  useEffect(() => {
    const stream = zoomMediaContext?.mediaContext.mediaStream;
    const screenSharedElement = document.querySelector<HTMLCanvasElement>(
      `#my-screen-share-content-video-canvas`
    );

    zmClient?.getAllUser().forEach((user) => {
      if (user.sharerOn && screenSharedElement && stream) {
        setstatusCamera(STATUS_CAMERA.CONTENT);
        dispatch({
          type: DISPATCH_TYPE.SET_SCREEN_SHARE_ACTIVE,
          payload: true,
        });
        stream.startShareView(screenSharedElement, user.userId);
      } else {
        setstatusCamera(STATUS_CAMERA.NO_CONTENT);
        dispatch({
          type: DISPATCH_TYPE.SET_SCREEN_SHARE_ACTIVE,
          payload: false,
        });
      }
    });
  }, [zmClient, zoomMediaContext?.mediaContext.mediaStream]);
  return {};
};
