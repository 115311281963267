import moment from "moment";
import DateTimeInterface from "@/utils/dateTimeFormat/dateTimeInteface";
import { BootcampClass } from "@/schemaTypes";

export const calculateStartTime = (
  dateMoment: moment.Moment,
  dateTime: DateTimeInterface,
  location: string
): string => {
  return dateTime.fortmatOnlyTime(dateMoment, location);
};

export const calculateStartDateFormatted = (
  dateMoment: moment.Moment,
  dateTime: DateTimeInterface,
  location: string
): string => {
  const convertLocalTime = dateTime.convertLocalTime(dateMoment, location);

  const format = dateTime.fortmatDate(convertLocalTime, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return format.charAt(0).toUpperCase() + format.slice(1).replace(/,/, "");
};

export const modulesCounter = (classes: BootcampClass[] = []) => {
  const modulesMap: { [key: string]: BootcampClass[] } = {};
  classes.forEach((bootcamp) => {
    const [moduleName] = bootcamp?.title
      ?.split("-")
      .map((item) => item.trim()) ?? ["", ""];
    if (moduleName) {
      if (!modulesMap[moduleName]) {
        modulesMap[moduleName] = [];
      }
      modulesMap[moduleName].push(bootcamp);
    }
  });

  return {
    modulesCount: Object.keys(modulesMap).length,
    sessionsCount: classes.length,
  };
};
