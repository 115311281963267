import React from "react";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import useGrowthBook from "../hooks/useGrowthBook";

interface GrowthBookWrapperProps {
  children: React.ReactNode;
}

const GrowthBookWrapper = ({
  children,
}: GrowthBookWrapperProps): JSX.Element => {
  const growthbook = useGrowthBook();

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

export default GrowthBookWrapper;
