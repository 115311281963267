import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PasswordInput } from "@/components/PasswordInput";
import { resetPassword } from "@/services/authService";
import { config } from "@/config";
import { Ico } from "@/assets/icons";
import { FormAlert } from "@/components/Forms";
import { Button } from "@/components/Button";
import { BackButton } from "@/components/PageContainer/BackButton";
import { AppRoute } from "@/AppRoute";

interface CreatePasswordCardProps {
  setCreatePasswordComplete: Dispatch<SetStateAction<boolean>>;
}

const CreatePasswordCard: React.FC<CreatePasswordCardProps> = ({
  setCreatePasswordComplete,
}) => {
  const { token } = useParams<"token">();
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [password, setPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState<undefined | boolean>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isCreatingPassword, setIsRessetingPassword] = useState(false);
  const [isPasswordAlphanumeric, setisPasswordAlphanumeric] = useState<
    boolean | undefined
  >(undefined);
  const [isPasswordLong, setisPasswordLong] = useState<boolean | undefined>();

  const handlePasswordChange = (passwordValue: string) => {
    setPassword(passwordValue);
    setisPasswordLong(passwordValue.length >= 8);
    setisPasswordAlphanumeric(/^(?=.*[!@#$%^&*()_+\d]).+$/.test(passwordValue));
  };

  const handleConfirmPasswordChange = (value: string) => {
    setPasswordsMatch(value === password);
    const newErrorMessage = "Las contraseñas no coinciden";
    setErrorMessage(password !== value ? newErrorMessage : "");
  };

  const handleResetPassword = async () => {
    if (recaptchaToken === null) {
      setErrorMessage(t("Invalid recaptcha check"));
      return;
    }

    if (!token) {
      setErrorMessage(t("Invalid token"));
      return;
    }

    setIsRessetingPassword(true);
    setErrorMessage("");

    resetPassword(token, password, recaptchaToken)
      .then(() => setCreatePasswordComplete(true))
      .catch((signUpError: unknown) => {
        setErrorMessage(
          signUpError && (signUpError as Error).message
            ? (signUpError as Error).message
            : t("Error resetting password, please try again")
        );
      })
      .finally(() => setIsRessetingPassword(false));
  };

  const onRecaptcha = (recaptchaResponse: string | null) => {
    setRecaptchaToken(recaptchaResponse);
  };

  const validationErrors: Record<string, Array<string>> = {
    password: password.length > 7 ? [] : [t("Invalid Password")],
    passwordsMatch: passwordsMatch ? [] : [t("Password check does not work")],
    recaptchaToken:
      recaptchaToken && recaptchaToken.length > 0
        ? []
        : [t("Invalid recaptcha check")],
  };

  const isFormValid = Object.keys(validationErrors).every(
    (key) => validationErrors[key].length === 0
  );

  const getIconColor = (isValid: boolean | undefined) => {
    if (isValid === undefined) return "neutral.200";

    return isValid ? "success.300" : "error.300";
  };

  return (
    <Flex flexDir={"column"} justify={"center"} align={"center"}>
      <Flex
        w={"100vw"}
        px={isMobile ? "16px" : "48px"}
        pt={isMobile ? "24px" : "48px"}
        mb={isMobile ? "0" : "100px"}
      >
        <BackButton showLabel={false} to={AppRoute.Base} />
      </Flex>

      <Flex
        flexDir={"column"}
        h={"100%"}
        py={"40px"}
        px={isMobile ? "16px" : "24px"}
        border={"1px solid"}
        borderColor={isMobile ? "transparent" : "neutral.200"}
        borderRadius={"8px"}
        align={"center"}
        justify={"center"}
        maxW={isMobile ? "100%" : "500px"}
      >
        <Image
          alt={`Smartbeemo Logo`}
          h={"100%"}
          w={isMobile ? "232px" : "287px"}
          mb={isMobile ? "48px" : "56px"}
          objectFit={"contain"}
          src={"/img/logo_smartbeemo.svg"}
        />

        <Flex justifyContent={"center"} flexDirection={"column"}>
          <Box mb={isMobile ? 6 : 8} textAlign={"start"}>
            <Heading as={isMobile ? "h5" : "h4"} mb={2}>
              <Trans>Create your password</Trans>
            </Heading>

            <Text color={"secondary.200"}>
              <Trans>
                Your new password must be different from the old one.
              </Trans>
            </Text>
          </Box>

          <Flex flexDir={"column"} justifyContent="center">
            <FormControl>
              <FormLabel color={"secondary.50"}>
                <Trans>Password</Trans>
              </FormLabel>
              <PasswordInput
                onChange={handlePasswordChange}
                borderColor={getIconColor(isPasswordLong)}
                _focus={{
                  border: `2px solid ${
                    isPasswordLong
                      ? "var(--primarios-main, #109B9D)"
                      : "error.200"
                  }`,
                }}
                _hover={{
                  border: `2px solid ${
                    isPasswordLong
                      ? "var(--primarios-main, #109B9D)"
                      : "error.200"
                  }`,
                }}
              />

              <Box mt={"12px"}>
                <Box display={"flex"} alignItems={"center"} gap={2}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    rounded={"full"}
                    h="16px"
                    w="16px"
                    backgroundColor={getIconColor(isPasswordLong)}
                  >
                    <Ico.Check color="#fff" />
                  </Box>
                  <Box>
                    <Text fontSize={"12px"}>{t("min8chars")}</Text>
                  </Box>
                </Box>

                <Box display={"flex"} alignItems={"center"} mt={"17px"} gap={2}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    rounded={"full"}
                    h="16px"
                    w="16px"
                    backgroundColor={getIconColor(isPasswordAlphanumeric)}
                  >
                    <Ico.Check color="#fff" />
                  </Box>
                  <Box>
                    <Text fontSize={"12px"}>
                      {t("At least an alphanumeric character")}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </FormControl>

            <FormControl>
              <FormLabel
                color={"secondary.50"}
                fontWeight={"semibold"}
                fontSize={"14px"}
                mt={6}
              >
                <Trans>Confirm password</Trans>
              </FormLabel>
              <PasswordInput
                borderColor={getIconColor(
                  passwordsMatch === undefined ? undefined : !!passwordsMatch
                )}
                _focus={{
                  border: "2px solid var(--primarios-main, #109B9D)",
                }}
                _hover={{
                  border: "1px solid var(--primarios-200, #37B3B4)",
                }}
                onChange={handleConfirmPasswordChange}
                onPaste={(e) => e.preventDefault()}
                name="password-check"
                placeholder={t("Repeat Password")}
              />

              {errorMessage && <FormAlert isTouched>{errorMessage}</FormAlert>}
            </FormControl>

            <Box display={"flex"} justifyContent={"center"} mt="5" w={"100%"}>
              <ReCAPTCHA
                sitekey={config.RECAPTCHA_SITE_KEY}
                onChange={onRecaptcha}
              />
            </Box>

            <Button
              _hover={{
                backgroundColor: "primary.600",
              }}
              w={"100%"}
              p={isMobile ? "12px 0" : "16px 32px"}
              mt={isMobile ? "40px" : "56px"}
              isLoading={isCreatingPassword}
              isDisabled={isCreatingPassword || !isFormValid}
              cursor={isFormValid ? "pointer" : "not-allowed"}
              onClick={handleResetPassword}
              backgroundColor={"primary.300"}
              title="Create password"
              variant="primary"
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CreatePasswordCard;
