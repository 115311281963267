/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Box } from "@chakra-ui/react";
import React from "react";
import ReactPlayer from "react-player";

interface EmbedVideoProps {
  videoURL: string;
  height?: number | string;
  autoPlay?: boolean;
}

export const EmbedVideo: React.FC<EmbedVideoProps> = ({
  videoURL,
  height,
  autoPlay = false,
}) => {
  return (
    <Box w="100%" mx="auto" h={height} style={{ aspectRatio: "16 / 9" }}>
      <ReactPlayer
        url={videoURL}
        width="100%"
        height="100%"
        controls
        playing={autoPlay}
      />
    </Box>
  );
};
