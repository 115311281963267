import { Image, Show, Flex, Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { PageContainer } from "@/components/PageContainer";
import { AppRoute, InitialRoute } from "../AppRoute";
import { SignUser } from "../components/SignUser";
import { useUser } from "../providers/useUser";
import { SBErrorPubSub } from "@/utils/errors/SBError";

export const HomePage: React.FC = () => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const logoutReason = queryParams.get("logoutReason");
  const { t } = useTranslation();
  const { user } = useUser();

  useEffect(() => {
    if (logoutReason && logoutReason === "session-timeout") {
      SBErrorPubSub.publish({
        component: "Home.tsx line 22",
        message: t("Your session timed out, please sign in again"),
        showInProd: true,
      });
    }
  }, [logoutReason]);

  const handleSignUp = () => {
    navigate(AppRoute.Base);
  };

  useEffect(() => {
    if (user) {
      const redirectLocationFromQuery = queryParams.get("loginRedirect");
      navigate(redirectLocationFromQuery || InitialRoute, { replace: true });
    }
  }, [user]);

  return (
    <Flex bgColor={"white"} h={"100vh"} w={"100%"}>
      <Show above={"md"}>
        <Box aspectRatio={1.025} overflow={"hidden"} pos={"relative"}>
          <Image
            boxSize={"100%"}
            fit={"cover"}
            src={
              "https://storage.googleapis.com/lms-stg-media/bienvenida/unnamed.jpg"
            }
          />
          <Box
            bgColor={"primary.500"}
            bottom={"50%"}
            boxSize={"65%"}
            filter={"blur(23vh)"}
            left={"50%"}
            pos={"absolute"}
          />
          <Box
            bgColor={"tertiary.300"}
            boxSize={"81%"}
            filter={"blur(14vh)"}
            pos={"absolute"}
            right={"75%"}
            top={"75%"}
          />
        </Box>
      </Show>
      <PageContainer maxW={"400px"} minW={"250px"}>
        <SignUser hasContainer={false} isLoginVisible onSignUp={handleSignUp} />
      </PageContainer>
    </Flex>
  );
};

export default HomePage;
