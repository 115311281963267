import { createContext } from "react";
import type { Category, MarketplaceService } from "@/schemaTypes";
type PresenterValue = {
  servicesForView: Array<MarketplaceService | null> | null | undefined;
  filteredServicesForView: (MarketplaceService | null)[] | null | undefined;
  categoriesForView: Category[];
  subCategoriesForView: Category[];
  selectedMainCategory: string;
  selectedSubCategories: string[];
  selectedIndex: number;
  searchTerm: string;
  currentServiceForView: MarketplaceService | null;
  selectMainCategory: (categoryId: string, index: number) => void;
  selectSubCategory: (categoryIds: Array<string>) => void;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchClick: () => void;
  handleCleanSearch: () => void;
  isMobile: boolean;
};
interface PresenterState {
  searchTerm?: string;
  servicesForView?: Array<MarketplaceService | null> | null;
  filteredServicesForView?: Array<MarketplaceService | null> | null;
  selectedMainCategory?: string;
  selectedSubCategories?: string[];
  subCategoriesForView?: Category[];
  currentServiceForView?: MarketplaceService | null;
  categoriesForView?: Category[];
  selectMainCategory: (categoryId: string, index: number) => void;
  selectSubCategory: (categoryIds: Array<string>) => void;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchClick: () => void;
  handleCleanSearch: () => void;
  selectedIndex?: number;
  isMobile?: boolean;
}
const PresenterContext = createContext<PresenterValue | null>(null);
export type { PresenterState, PresenterValue };
export { PresenterContext };
