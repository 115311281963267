import { Flex, Text } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { ChipsProps } from "./types";
import { Button } from "@/components/Button";

const Chips = ({
  onChange,
  options,
  values = [],
  data_cy,
  singleSelect = false,
  ...restProps
}: ChipsProps): JSX.Element => {
  const { t } = useTranslation();

  const valuesSet = useMemo(() => new Set(values), [values]);

  const handleChange = useCallback(
    (option: string) => {
      let newValues;
      if (singleSelect) {
        newValues = valuesSet.has(option) ? [] : [option];
      } else {
        const isSelected = valuesSet.has(option);
        newValues = isSelected
          ? values.filter((value) => value !== option)
          : values.concat(option);
      }
      onChange?.(newValues);
    },
    [values, valuesSet, onChange, singleSelect]
  );

  return (
    <Flex {...restProps} gap={"8px"} wrap={"wrap"}>
      {options.map((option) => {
        const isSelected = valuesSet.has(option.value);

        return (
          <Button
            border={"none"}
            data-cy={data_cy ? data_cy + "-" + option.name : undefined}
            bg={isSelected ? "secondary.400" : "neutral.50"}
            key={option.value}
            onClick={() => handleChange(option.value)}
            px={4}
            py={1}
            size={"sm"}
            variant={isSelected ? "primary" : undefined}
            color={isSelected ? "white" : "neutral.800"}
            _active={{
              outline: "none",
              boxShadow: "none",
              bgColor: "secondary.200",
            }}
            _hover={
              isSelected
                ? { bgColor: "secondary.200" }
                : { bgColor: "neutral.100" }
            }
            _focus={{ outline: "none", boxShadow: "none" }}
          >
            <Text
              mx={0}
              px={0}
              variant={"bold"}
              lineHeight={"19.2px"}
              fontSize={{ base: "12px", lg: "12px" }}
            >
              {t(option.name)}
            </Text>
          </Button>
        );
      })}
    </Flex>
  );
};

export { Chips };
