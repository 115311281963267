import { usePresenter } from "../presenter/usePresenter";
import { Box, Grid, Image } from "@chakra-ui/react";
import { useState } from "react";
import { BigSpinner } from "@/components/BigSpinner";

export const ServiceImageGallery: React.FC = () => {
  const { currentServiceForView: currentService, isMobile } = usePresenter();
  const [loadingStates, setLoadingStates] = useState(
    new Array(currentService?.imageGallery?.length).fill(true)
  );
  const handleImageLoad = (index: number) => {
    setLoadingStates((prevLoadingStates) => {
      const updatedLoadingStates = [...prevLoadingStates];
      updatedLoadingStates[index] = false;
      return updatedLoadingStates;
    });
  };
  return (
    currentService && (
      <Grid
        mt={"18px"}
        templateColumns="repeat(3, 1fr)"
        gap={isMobile ? "7px 8px" : "16px 18px"}
      >
        {currentService.imageGallery &&
          currentService.imageGallery.length > 0 &&
          currentService.imageGallery.map((img, index) => {
            return (
              <Box key={index} rounded={6} overflow={"hidden"}>
                {loadingStates[index] && <BigSpinner></BigSpinner>}
                <Image
                  onLoad={() => handleImageLoad(index)}
                  src={img ?? ""}
                ></Image>
              </Box>
            );
          })}
      </Grid>
    )
  );
};
