import { Avatar, Flex, Text } from "@chakra-ui/react";
import imagePlaceholder from "/img/imagePlaceholder.png";
import { ProfileProps } from "../utils";

export const ProfileCard = ({ profileData }: ProfileProps) => {
  const { name, lastName, profilePicture, jobtitle } = profileData;
  const fullName = `${name} ${lastName}`;

  return (
    <Flex gap={2}>
      <Avatar
        size={"md"}
        name={fullName}
        src={profilePicture || imagePlaceholder}
      />
      <Flex flexDir={"column"} gap={1}>
        <Text fontSize={"14px"} variant={"bold"}>
          {fullName}
        </Text>
        <Text fontSize={"14px"}>{jobtitle}</Text>
      </Flex>
    </Flex>
  );
};
