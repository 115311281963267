import { useLayoutEffect, useCallback, useState } from "react";

const useRect = (ref: React.RefObject<HTMLElement>): DOMRect => {
  const [rect, setRect] = useState<DOMRect>(new DOMRect());

  const handleResize = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    setRect(ref.current.getBoundingClientRect());
  }, [ref.current]);

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return rect;
};

export { useRect };
