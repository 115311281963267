import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Box, Flex, Grid, Heading, Text } from "@chakra-ui/layout";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Select,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import ButtonLiveSession from "@/components/Button/Button";
import { GetCoursesWithModulesDocument } from "@/components/Cohorts/graphql/getCoursesWithModules.generated";
import { DatePickerField } from "@/components/Forms/DatePickerField";
import { InputField } from "@/components/Forms/InputField";
import { UpdateLiveSessionDocument } from "@/components/LiveSessions/graphql/updateLiveSession.generated";
import { CreateLiveSessionsDocument } from "@/pages/academy/LiveClasses/Agora/graphql/createLiveSession.generated";
import { GetMyProgramCohortsDocument } from "@/pages/academy/Programs/graphql/getMyProgramCohorts.generated";
import { SearchProgramsDocument } from "@/pages/academy/Programs/graphql/searchPrograms.generated";
import { useUser } from "@/providers/useUser";
import {
  CreateLiveSessionsInput,
  EntityName,
  LiveSession,
  LiveSessionRecurrenceTypes,
  LiveSessionType,
  Module,
  Program,
  UserRole,
} from "@/schemaTypes";
import { InputStyled } from "@/components/Forms/InputStyled";
import { UserCardLiveProps } from "@/components/Users/UserCard";
import {
  SearchUserLiveDocument,
  SearchUserLiveQuery,
} from "./graphql/searchUserLive.generated";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { useCategoriesOptions } from "../utils";

moment.utc();

interface CreateLiveSessionsProps {
  fetchLiveSessions: () => void;
  editMode?: boolean;
  liveSession?: LiveSession | undefined;
  closeModal: () => void | undefined;
}

interface WeekdayCheckBoxProps {
  dayName: string;
  onChange: () => void;
  isChecked: boolean;
}

export const WeekdayCheckBox: React.FC<WeekdayCheckBoxProps> = ({
  dayName,
  onChange,
  isChecked,
}) => (
  <Checkbox
    border="solid 1px"
    borderColor={"black"}
    px="3"
    py="2"
    rounded="md"
    colorScheme="secondary"
    onChange={onChange}
    isChecked={isChecked}
  >
    <Trans>{dayName}</Trans>
  </Checkbox>
);

const CreateLiveSessions = ({
  fetchLiveSessions,
  editMode,
  liveSession,
  closeModal,
}: CreateLiveSessionsProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { user } = useUser();
  const { brandCategories, levelCategories, subCategories } =
    useCategoriesOptions();
  const [modules, setmodules] = useState<Array<Module>>([]);
  const [selectedModule, setselectedModule] = useState<string>(
    editMode && liveSession?.moduleId ? liveSession?.moduleId : ""
  );

  const [selectedMentor, setselectedMentor] = useState({
    id: "",
    name: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [userResults, setUserResults] = useState<
    SearchUserLiveQuery["searchUsers"] | undefined
  >();
  const [searchUsers, { data: dataSearchUser }] = useLazyQuery(
    SearchUserLiveDocument
  );

  const [allPrograms, setallPrograms] = useState<Program[]>([]);
  const [getModulesByCohort] = useLazyQuery(GetCoursesWithModulesDocument);
  const [createLiveSessions, { loading: createLiveSessionLoading }] =
    useMutation(CreateLiveSessionsDocument);
  const [updateLiveSessions, { loading: updateLiveSessionLoading }] =
    useMutation(UpdateLiveSessionDocument);
  const [getMyProgramCohorts] = useLazyQuery(GetMyProgramCohortsDocument);
  const [dataCreateLiveSessions, setdataCreateLiveSessions] =
    useState<CreateLiveSessionsInput>({
      endDate: editMode
        ? moment(liveSession?.endDate).toDate()
        : moment().add(1, "hour").toDate(),
      moduleId: editMode && liveSession?.moduleId ? liveSession?.moduleId : "",
      name: editMode && liveSession?.name ? liveSession?.name : "",
      programId:
        editMode && liveSession?.programId ? liveSession?.programId : "",
      startDate: editMode
        ? moment(liveSession?.startDate).toDate()
        : moment().toDate(),
      userId:
        editMode && liveSession?.userId
          ? liveSession?.userId
          : String(user?.id),
      liveSessionType: editMode
        ? liveSession?.liveSessionType
        : LiveSessionType.Livesession,
      recurrence: {
        type:
          editMode && liveSession?.recurrence?.daysOfWeek
            ? liveSession?.recurrence.type
            : LiveSessionRecurrenceTypes.Unique,
        interval: 1,
        daysOfWeek:
          editMode && liveSession?.recurrence
            ? liveSession?.recurrence.daysOfWeek
            : [],
      },
      mentorName:
        editMode && liveSession?.mentorName ? liveSession?.mentorName : "",
      mentorId: editMode && liveSession?.mentorId ? liveSession?.mentorId : "",
      imageUrlMentor:
        editMode && liveSession?.imageUrlMentor
          ? liveSession?.imageUrlMentor
          : "",
      brandId: editMode && liveSession?.brandId ? liveSession?.brandId : "",
      subCategoryId: liveSession?.subCategoryId || "",
      levelId: liveSession?.levelId || "",
    });

  const { data: programData } = useQuery(SearchProgramsDocument, {
    variables: {
      programSearchInput: {
        entityName: EntityName.Diplomat,
      },
    },
  });

  const { data: programDataSpecialization } = useQuery(SearchProgramsDocument, {
    variables: {
      programSearchInput: {
        entityName: EntityName.Specialization,
      },
    },
  });

  const liveSessionsTypesOptions = [
    { value: LiveSessionType.Livesession, label: "Clase en vivo" },
    {
      value: LiveSessionType.QuestionsAndAnswers,
      label: "Preguntas y respuestas",
    },
    { value: LiveSessionType.OnboardingSession, label: "Sesión de onboarding" },
    { value: LiveSessionType.SalesSession, label: "Reunión de ventas" },
  ];

  const toggleSelectedWeekday = (weekday: number) => () => {
    const updatedDaysOfWeek = [...dataCreateLiveSessions.recurrence.daysOfWeek];

    if (updatedDaysOfWeek.includes(weekday)) {
      const index = updatedDaysOfWeek.indexOf(weekday);
      updatedDaysOfWeek.splice(index, 1);
    } else {
      updatedDaysOfWeek.push(weekday);
    }

    setdataCreateLiveSessions({
      ...dataCreateLiveSessions,
      recurrence: {
        ...dataCreateLiveSessions.recurrence,
        daysOfWeek: updatedDaysOfWeek,
      },
    });
  };

  const isWeekdayChecked = (weekday: number) => {
    return dataCreateLiveSessions.recurrence.daysOfWeek.includes(weekday);
  };

  const handleProgramSelected = ({
    target,
  }: ChangeEvent<HTMLSelectElement>) => {
    if (target.value) {
      setdataCreateLiveSessions({
        ...dataCreateLiveSessions,
        programId: target?.value,
      });
    }
  };

  const handleChangeField = (value: string) => {
    setdataCreateLiveSessions({
      ...dataCreateLiveSessions,
      name: value,
    });
  };

  const handleChangeSelectRecurrency = ({
    target,
  }: ChangeEvent<HTMLSelectElement>) => {
    let value: LiveSessionRecurrenceTypes;

    switch (target.value) {
      case "WEEKLY":
        value = LiveSessionRecurrenceTypes.Weekly;
        break;
      case "MONTHLY":
        value = LiveSessionRecurrenceTypes.Monthly;
        break;
      case "YEARLY":
        value = LiveSessionRecurrenceTypes.Yearly;
        break;

      default:
        value = LiveSessionRecurrenceTypes.Unique;
        break;
    }

    setdataCreateLiveSessions({
      ...dataCreateLiveSessions,
      recurrence: {
        ...dataCreateLiveSessions.recurrence,
        type: value,
      },
    });
  };

  const handleStartDateChange = (start: Date | undefined | null) => {
    if (start !== null && start !== undefined) {
      const dateStart = moment(start);
      const formattedStartDate = dateStart.format("YYYY-MM-DDTHH:mm:ssZ");

      setdataCreateLiveSessions({
        ...dataCreateLiveSessions,
        startDate: new Date(formattedStartDate),
      });
    }
  };

  const handleSelectedModule = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    if (target.value) {
      setselectedModule(target.value);
      setdataCreateLiveSessions({
        ...dataCreateLiveSessions,
        moduleId: target.value,
      });
    }
  };

  const handleEndDateChange = (end: Date | undefined | null) => {
    if (end !== null && end !== undefined) {
      const dateStart = moment(end);
      const formattedEndDate = dateStart.format("YYYY-MM-DDTHH:mm:ssZ");

      setdataCreateLiveSessions({
        ...dataCreateLiveSessions,
        endDate: new Date(formattedEndDate),
      });
    }
  };

  const getModules = async () => {
    const dataCohort = await getMyProgramCohorts({
      variables: {
        programId: dataCreateLiveSessions.programId,
        limit: 1,
      },
    });

    if (dataCohort && dataCohort.data?.me?.cohorts) {
      const modulesData = await getModulesByCohort({
        variables: {
          cohortId: dataCohort.data.me.cohorts[0].id,
        },
      });

      if (modulesData.data?.cohort?.coursesWithModules.modules) {
        const modulesFetch =
          modulesData.data?.cohort?.coursesWithModules.modules.filter(
            (module) => module !== null
          ) as Module[];

        setmodules(modulesFetch || []);
      }
    }
  };

  const isValidForm = () => {
    const { name, programId, moduleId, startDate, endDate, recurrence } =
      dataCreateLiveSessions;
    return (
      name &&
      programId &&
      moduleId &&
      startDate &&
      endDate &&
      recurrence.type &&
      recurrence.daysOfWeek.length > 0
    );
  };

  const handleCreateLiveSession = async () => {
    if (isValidForm()) {
      const data = await createLiveSessions({
        variables: {
          createLiveSessionsInput: dataCreateLiveSessions,
        },
      });

      if (data.data?.createLiveSessions) {
        toast({
          title: t(`Live Session created`),
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        fetchLiveSessions();

        setdataCreateLiveSessions({
          moduleId: "",
          name: "",
          programId: "",
          startDate: moment().toDate(),
          endDate: moment().add(1, "hour").toDate(),
          userId: String(user?.id),
          recurrence: {
            type: LiveSessionRecurrenceTypes.Unique,
            interval: 1,
            daysOfWeek: [],
          },
          mentorId: "",
          mentorName: "",
          brandId: "",
          subCategoryId: "",
          levelId: "",
          liveSessionType: LiveSessionType.Livesession,
          imageUrlMentor: "",
        });
        closeModal();
        setselectedModule("");
      } else {
        SBErrorPubSub.publish({
          component: "CreateLiveSessions.tsx line 371",
          message: t(`Error creating live session, try again late`),
          showInProd: true,
        });
      }
    }
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSearchTerm = e.target.value;

    setSearchTerm(selectedSearchTerm);
    setselectedMentor({
      id: "",
      name: "",
    });
  };

  const handleUserSelect = (userMentor: UserCardLiveProps["userLive"]) => {
    setdataCreateLiveSessions({
      ...dataCreateLiveSessions,
      mentorId: userMentor.id,
      mentorName: `${userMentor.name} ${userMentor.lastName}`,
    });

    setselectedMentor({
      id: userMentor.id,
      name: `${userMentor.name} ${userMentor.lastName}`,
    });

    setSearchTerm("");
  };

  const handleUpdateLiveSession = async () => {
    if (isValidForm() && liveSession) {
      const data = await updateLiveSessions({
        variables: {
          updateLiveSessionOrMasterclassInput: {
            id: liveSession.id,
            ...dataCreateLiveSessions,
            liveSessionType:
              dataCreateLiveSessions.liveSessionType as LiveSessionType,
          },
        },
      });

      if (data.data?.updateLiveSession) {
        toast({
          title: t(`Live Session updated`),
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        fetchLiveSessions();

        closeModal();

        setdataCreateLiveSessions({
          moduleId: "",
          name: "",
          programId: "",
          startDate: moment().toDate(),
          endDate: moment().add(1, "hour").toDate(),
          userId: String(user?.id),
          recurrence: {
            type: LiveSessionRecurrenceTypes.Unique,
            interval: 1,
            daysOfWeek: [],
          },
          mentorId: "",
          mentorName: "",
          liveSessionType: LiveSessionType.Livesession,
          brandId: "",
          subCategoryId: "",
          levelId: "",
          imageUrlMentor: "",
        });

        setselectedModule("");
      } else {
        SBErrorPubSub.publish({
          component: "CreateLiveSessions.tsx line 449",
          message: t(`Error creating live session, try again late`),
          showInProd: true,
        });
      }
    }
  };

  useEffect(() => {
    const weekday = moment(dataCreateLiveSessions.startDate).toDate().getDay();
    setdataCreateLiveSessions({
      ...dataCreateLiveSessions,
      recurrence: {
        ...dataCreateLiveSessions.recurrence,
        daysOfWeek: [weekday],
      },
    });
    isWeekdayChecked(weekday);
  }, [dataCreateLiveSessions.startDate]);

  useEffect(() => {
    if (dataCreateLiveSessions.programId) {
      getModules();
    }
  }, [dataCreateLiveSessions.programId]);

  function capitalizeWords(str: string) {
    return str.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  }
  useEffect(() => {
    if (dataSearchUser) {
      setUserResults(
        dataSearchUser.searchUsers.filter((userData) =>
          userData.roles.includes(UserRole.Mentor)
        )
      );
    }
  }, [dataSearchUser]);

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      searchUsers({
        variables: {
          userSearchInput: {
            query: `${searchTerm}`,
            roles: [UserRole.Mentor],
            entityName: "user",
          },
        },
      });
    } else {
      setUserResults(undefined);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (programData && programDataSpecialization) {
      const combinedPrograms = [
        ...(programData?.searchPrograms || []),
        ...(programDataSpecialization?.searchPrograms || []),
      ] as Program[];

      setallPrograms(combinedPrograms);
    }
  }, [programDataSpecialization, programData]);

  return (
    <>
      <Flex w={"100%"} flexDirection={"column"} minW={"550px"}>
        <Box>
          <Grid
            mb="4"
            justifyContent="stretch"
            autoFlow="column"
            gap="4"
            w={{ base: "100%", md: "70%", lg: "70%" }}
          >
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Heading as={"h5"} size="md" mb="2" mt="4">
                  <Trans>Live class information</Trans>
                </Heading>

                <InputField
                  value={dataCreateLiveSessions.name}
                  name="name"
                  isRequired
                  label={"Nombre de la clase en vivo"}
                  validator={(value: string) =>
                    !value || value.length > 2
                      ? []
                      : [t("Invalid live class name")]
                  }
                  onChange={handleChangeField}
                  mb="3"
                />

                <DatePickerField
                  value={dataCreateLiveSessions.startDate}
                  isRequired
                  label={t("Start Date & Time")}
                  onChange={handleStartDateChange}
                  showTimeSelect
                />
                <DatePickerField
                  value={dataCreateLiveSessions.endDate}
                  isRequired
                  label={t("End Date & Time")}
                  onChange={handleEndDateChange}
                  showTimeSelect
                />
              </Box>

              <Flex mb={3}>
                <InputField
                  name="imageUrlMentor"
                  label={t("Mentor avatar")}
                  value={dataCreateLiveSessions.imageUrlMentor}
                  onChange={(value) =>
                    setdataCreateLiveSessions({
                      ...dataCreateLiveSessions,
                      imageUrlMentor: value,
                    })
                  }
                />
              </Flex>

              <FormControl isRequired>
                <FormLabel>{t("Livesession type")}</FormLabel>
                <Select
                  onChange={(value) =>
                    setdataCreateLiveSessions({
                      ...dataCreateLiveSessions,
                      liveSessionType: value.target.value as LiveSessionType,
                    })
                  }
                  value={
                    dataCreateLiveSessions.liveSessionType as LiveSessionType
                  }
                >
                  <option value="" disabled>
                    {t("Select an option")}
                  </option>
                  {liveSessionsTypesOptions?.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>{t("Session brand")}</FormLabel>
                <Select
                  onChange={(value) =>
                    setdataCreateLiveSessions({
                      ...dataCreateLiveSessions,
                      brandId: value.target.value,
                    })
                  }
                  value={dataCreateLiveSessions.brandId}
                >
                  <option value="" disabled>
                    {t("Select an option")}
                  </option>
                  {brandCategories?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>{t("Session subcategory")}</FormLabel>
                <Select
                  onChange={(value) =>
                    setdataCreateLiveSessions({
                      ...dataCreateLiveSessions,
                      subCategoryId: value.target.value,
                    })
                  }
                  value={dataCreateLiveSessions.subCategoryId}
                >
                  <option value="" disabled>
                    {t("Select an option")}
                  </option>
                  {subCategories?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>{t("Session level")}</FormLabel>
                <Select
                  onChange={(value) =>
                    setdataCreateLiveSessions({
                      ...dataCreateLiveSessions,
                      levelId: value.target.value,
                    })
                  }
                  value={dataCreateLiveSessions.levelId}
                >
                  <option value="" disabled>
                    {t("Select an option")}
                  </option>
                  {levelCategories?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Box display={"flex"} flexDir={"column"}>
                <FormLabel>
                  {t("Seleccione un diplomado o especializacion")}
                </FormLabel>
                <Box w={"100%"} mb={2}>
                  <Select
                    rounded={"8px"}
                    value={dataCreateLiveSessions.programId}
                    backgroundColor={"white"}
                    onChange={handleProgramSelected}
                    placeholder="Seleccione un diplomado o especializacion"
                  >
                    {allPrograms?.map((diplomat) => (
                      <option key={diplomat?.id} value={diplomat?.id}>
                        {diplomat?.name}
                      </option>
                    ))}
                  </Select>
                </Box>

                <Box w={"100%"}>
                  <Select
                    isDisabled={!dataCreateLiveSessions.programId}
                    rounded={"8px"}
                    value={selectedModule}
                    backgroundColor={"white"}
                    onChange={handleSelectedModule}
                    placeholder="Seleccione una fase del diplomado o especializacion"
                  >
                    {modules.map((module: Module) => (
                      <option key={module?.id} value={module?.id}>
                        {module.name && module?.name[0]?.value}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Box>

              <Box>
                <Heading as={"h5"} size="md" mb="2" mt="4">
                  <Trans>Recurrence information</Trans>
                </Heading>
              </Box>

              {!editMode && (
                <Box>
                  <Select
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      handleChangeSelectRecurrency(e)
                    }
                    rounded={"8px"}
                    backgroundColor={"white"}
                    defaultValue={LiveSessionRecurrenceTypes.Unique}
                    placeholder="Seleccione tipo de recurrencia"
                  >
                    <option value={LiveSessionRecurrenceTypes.Unique}>
                      <Trans>Unica</Trans>
                    </option>

                    {/* ESTO POR EL MOMENTO NO SE ESTA USANDO PERO ES UNA FUNCIONALIDAD QUE VOLVERAS MAS FUERTE Xd */}

                    {/* <option value={LiveSessionRecurrenceTypes.Weekly}>
                    <Trans>It will be repeated for a week</Trans>
                  </option>
                  <option value={LiveSessionRecurrenceTypes.Monthly}>
                    <Trans>It will be repeated for a month</Trans>
                  </option>
                  <option value={LiveSessionRecurrenceTypes.Yearly}>
                    <Trans>It will be repeated for a year</Trans>
                  </option> */}
                  </Select>
                </Box>
              )}

              {dataCreateLiveSessions.recurrence.type !==
                LiveSessionRecurrenceTypes.Unique && (
                <Box w={"100%"}>
                  <Heading as={"h6"} size="sm" mb="2" mt="4">
                    <Trans>Events per week</Trans>
                  </Heading>

                  <Grid
                    w={"100%"}
                    templateColumns="repeat(5, min-content)"
                    gap="3"
                    mb="4"
                  >
                    <WeekdayCheckBox
                      onChange={toggleSelectedWeekday(1)}
                      isChecked={isWeekdayChecked(1)}
                      dayName="Mon"
                    />
                    <WeekdayCheckBox
                      onChange={toggleSelectedWeekday(2)}
                      isChecked={isWeekdayChecked(2)}
                      dayName="Tue"
                    />
                    <WeekdayCheckBox
                      onChange={toggleSelectedWeekday(3)}
                      isChecked={isWeekdayChecked(3)}
                      dayName="Wed"
                    />
                    <WeekdayCheckBox
                      onChange={toggleSelectedWeekday(4)}
                      isChecked={isWeekdayChecked(4)}
                      dayName="Thu"
                    />
                    <WeekdayCheckBox
                      onChange={toggleSelectedWeekday(5)}
                      isChecked={isWeekdayChecked(5)}
                      dayName="Fri"
                    />
                    <WeekdayCheckBox
                      onChange={toggleSelectedWeekday(6)}
                      isChecked={isWeekdayChecked(6)}
                      dayName="Sat"
                    />
                    <WeekdayCheckBox
                      onChange={toggleSelectedWeekday(7)}
                      isChecked={isWeekdayChecked(7)}
                      dayName="Sun"
                    />
                  </Grid>
                </Box>
              )}

              <Box>
                <Box>
                  <Text
                    fontWeight={"semibold"}
                    fontSize={"14px"}
                    mb="4px"
                    mt={"4px"}
                  >
                    <Trans>Professor</Trans>
                  </Text>
                  <InputStyled
                    pl="8"
                    value={
                      selectedMentor.name ||
                      searchTerm ||
                      dataCreateLiveSessions.mentorName
                    }
                    onChange={handleSearchTermChange}
                    placeholder={t("User Search")}
                  />
                </Box>
                <Box>
                  {userResults
                    ?.filter((userMentor) =>
                      `${userMentor.name.toLowerCase()} ${
                        userMentor.lastName?.toLowerCase() || ""
                      }`.includes(searchTerm.toLowerCase())
                    )
                    .map((userMentor) => (
                      <Box
                        key={userMentor.id}
                        onClick={() => handleUserSelect(userMentor)}
                      >
                        {capitalizeWords(userMentor.name.toLowerCase() ?? "")}{" "}
                        {capitalizeWords(
                          userMentor.lastName?.toLowerCase() ?? ""
                        )}
                      </Box>
                    ))}
                </Box>
              </Box>

              <Box mt={"5"}>
                <ButtonLiveSession
                  isLoading={
                    createLiveSessionLoading || updateLiveSessionLoading
                  }
                  isDisabled={isValidForm() ? false : true}
                  title={editMode ? "Edit" : "Generate Live Sessions"}
                  variant="primary"
                  onClick={() => {
                    if (editMode) {
                      handleUpdateLiveSession();
                    } else {
                      handleCreateLiveSession();
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Flex>
    </>
  );
};

export default CreateLiveSessions;
