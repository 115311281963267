import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { GatewayLayerContext, GatewayLayerValue } from "./context";
import { GetServicesForDisplayDocument } from "./graphql/getServicesMarkteplace.generated";
import { MarketplaceService } from "@/schemaTypes";
import { noCacheHeaders } from "@/utils/headers";
import { GetServiceForIdDocument } from "./graphql/getSingleServiceById.generated";
import { useParams } from "react-router-dom";
interface GatewayProps {
  children: React.ReactNode;
}
const GatewayLayerProvider = ({ children }: GatewayProps): JSX.Element => {
  const [allServices, setServices] = useState<
    Array<MarketplaceService | null> | undefined | null
  >([]);
  const [currentService, setCurrentService] = useState<
    MarketplaceService | null | undefined
  >(undefined);
  const { serviceId } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [getServicesMarketplace, { error, refetch }] = useLazyQuery(
    GetServicesForDisplayDocument,
    {
      onCompleted: (data) => {
        setLoading(false);
        setServices(data.getServicesForDisplay);
      },
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );
  const [getServiceDetail] = useLazyQuery(GetServiceForIdDocument, {
    variables: {
      serviceId: serviceId ?? "",
    },
    onCompleted: (data) => {
      setLoading(false);
      setCurrentService(data.getServiceForId);
    },
    fetchPolicy: "no-cache",
    context: { headers: noCacheHeaders },
  });
  useEffect(() => {
    if (!serviceId) {
      getServicesMarketplace();
    }
  }, [getServicesMarketplace]);
  useEffect(() => {
    if (serviceId) {
      getServiceDetail();
    }
  }, [getServiceDetail, serviceId]);

  const reloadData = useCallback(() => {
    refetch();
  }, [getServicesMarketplace]);

  const value: GatewayLayerValue = {
    allServices,
    currentService,
    loading,
    error,
    reloadData,
  };
  return (
    <GatewayLayerContext.Provider value={value}>
      {children}
    </GatewayLayerContext.Provider>
  );
};
export { GatewayLayerProvider };
