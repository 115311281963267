import { colors } from "@/components/Theme/colors";
import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const blackVariant = definePartsStyle(() => {
  return {
    tab: {
      color: colors.neutral[100],
      fontSize: "14px",
      lineHeight: "22.4px",
      fontWeight: "600",
      w: "fit-content",
      _selected: {
        color: colors.secondary[300],
      },
      _hover: {
        color: colors.secondary[100],
      },
    },
    tablist: {
      display: "flex",
      overflow: "auto",
      w: "100%",
      maxW: "100%",
      position: "relative",
      whiteSpace: "nowrap",
      borderBottom: `2px solid ${colors.neutral[100]}`,
      "&::-webkit-scrollbar": {
        width: "0px",
        height: "0px",
      },
    },
    tabpanels: {
      px: { base: 4, lg: 0 },
      pt: 6,
    },
    tabpanel: {
      p: 0,
    },

    indicator: {
      bottom: 0,
      overflow: "auto",
      height: "2px",
      bg: colors.secondary[200],
      borderRadius: "8px",
    },
  };
});

const variants = {
  black: blackVariant,
};

export const tabsTheme = defineMultiStyleConfig({ variants });
