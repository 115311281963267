import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { ChatMessage } from "@zoom/videosdk";
import { Avatar, Box, Flex, TabPanel, Text } from "@chakra-ui/react";
import InputChat from "../../Inputs/InputChat";
import { t } from "i18next";
import { Trans } from "react-i18next";
import ZoomClientContext from "../../context/zoom-context";

interface QuestionsProps {
  isHostOrCoHost: boolean;
  questionChatMessages: ChatMessage[];
  sendMessage: (isPublic: boolean, message: string) => void;
}

const Questions: React.FC<QuestionsProps> = (props) => {
  const { zmClient } = useContext(ZoomClientContext);
  const { isHostOrCoHost, questionChatMessages, sendMessage } = props;
  const scrollRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState("");
  const [viewSuccessMessage, setviewSuccessMessage] = useState(false);
  const [filterByUserId, setfilterByUserId] = useState<ChatMessage[]>([]);

  const addUniqueMessage = useCallback(
    (messages: ChatMessage[], newMessage: ChatMessage) => {
      if (!messages.some((msg) => msg.id === newMessage.id)) {
        return [...messages, newMessage];
      }
      return messages;
    },
    []
  );

  const filteredQuestionMessages = questionChatMessages.filter((message) =>
    message.message?.startsWith("Question:")
  );

  const handleSendMessage = (message: string) => {
    sendMessage(false, `Question: ${message}`);
    setviewSuccessMessage(true);

    setTimeout(() => {
      setviewSuccessMessage(false);
    }, 4000);
    setInputValue("");
  };

  useEffect(() => {
    if (!isHostOrCoHost) {
      setfilterByUserId((prevMessages) =>
        filteredQuestionMessages
          .filter(
            (message) =>
              message.sender.userGuid ===
              zmClient?.getCurrentUserInfo()?.userGuid
          )
          .reduce(
            (acc, message) => addUniqueMessage(acc, message),
            [...prevMessages]
          )
      );
    } else {
      setfilterByUserId((prevMessages) =>
        filteredQuestionMessages.reduce(
          (acc, message) => addUniqueMessage(acc, message),
          [...prevMessages]
        )
      );
    }
  }, [questionChatMessages, isHostOrCoHost, zmClient, addUniqueMessage]);

  return (
    <TabPanel id="LiveChat-TabPanel" height={"100%"}>
      <Flex direction="column" h="100%">
        <Box mb={5}>
          <Box>
            <Text fontSize={"14px"} color={"neutral.200"}>
              {t(
                "Write your question and the tutor will answer it at the end of the class."
              )}
            </Text>
          </Box>
        </Box>
        <Box
          ref={scrollRef}
          css={{
            "&::-webkit-scrollbar": {
              width: "0",
            },
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&:hover": {
              "&::-webkit-scrollbar": {
                width: "3px",
              },
              scrollbarWidth: "auto",
            },
            "&::-webkit-scrollbar-thumb": {
              opacity: 0,
              transition: "opacity 0.3s",
            },
            "&:hover::-webkit-scrollbar-thumb": {
              opacity: 0.5,
            },
          }}
          overflowY="auto"
          h={"100%"}
        >
          {filterByUserId?.map((message) => (
            <Flex alignItems={"flex-start"} key={message.id} mb={"16px"}>
              <Box mr={"8px"}>
                <Avatar size={"sm"} name={message.sender.name.split(" ")[0]} />
              </Box>
              <Box>
                <Box>
                  <Text
                    variant={"caption"}
                    fontWeight={"semibold"}
                    color={"shades.white"}
                    textTransform={"capitalize"}
                  >
                    {message.sender.name}
                  </Text>
                </Box>
                <Box>
                  <Text
                    color={"neutral.300"}
                    fontWeight={"400"}
                    style={{
                      overflowWrap: "break-word",
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                    variant={"caption"}
                  >
                    {message.message
                      ? message.message.replace("Question:", "").trim()
                      : ""}
                  </Text>
                </Box>
              </Box>
            </Flex>
          ))}
        </Box>

        <Box>
          {!isHostOrCoHost && (
            <InputChat
              onSendMessage={handleSendMessage}
              placeholder="Envía una pregunta"
              value={inputValue}
              onChange={(value) => setInputValue(value)}
            />
          )}

          {viewSuccessMessage && (
            <Box w={"100%"} height={"auto"} mt={10}>
              <Box
                padding={"8px"}
                backgroundColor={"success.50"}
                border={"2px"}
                borderColor={"success.300"}
                display={"flex"}
                borderRadius={"8px"}
                gap={3}
                alignItems={"center"}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 1.88679C5.5192 1.88679 1.88679 5.5192 1.88679 10C1.88679 14.4808 5.5192 18.1132 10 18.1132C14.4808 18.1132 18.1132 14.4808 18.1132 10C18.1132 5.5192 14.4808 1.88679 10 1.88679ZM13.8288 7.30323C14.1973 7.67165 14.1973 8.26897 13.8288 8.63739L9.53501 12.9312C9.16659 13.2997 8.56926 13.2997 8.20084 12.9312L6.73719 11.4676C6.36877 11.0992 6.36877 10.5018 6.73719 10.1334C7.10561 9.765 7.70293 9.765 8.07135 10.1334L8.86792 10.93L12.4947 7.30323C12.8631 6.93481 13.4604 6.93481 13.8288 7.30323Z"
                    fill="#12C971"
                  />
                </svg>

                <Text
                  color={"secondary.300"}
                  fontWeight={"semibold"}
                  fontSize={{ base: "13px", lg: "14px" }}
                >
                  <Trans>Your question has been sent successfully</Trans>
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Flex>
    </TabPanel>
  );
};

export default Questions;
