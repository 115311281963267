import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const secondary = definePartsStyle({
  control: {
    border: "2px solid",
    borderColor: "gray.300",
    _hover: {
      borderColor: "primary.300",
      bgColor: "white",
      color: "primary.300",
    },

    _checked: {
      borderColor: "primary.300",
      bgColor: "white",
      color: "primary.300",
    },
    _dark: {
      borderColor: "gray.600",
    },
  },
});

const radioTheme = defineMultiStyleConfig({
  baseStyle: {
    container: {
      mr: "24px",
    },
  },
  defaultProps: {
    colorScheme: "primary",
  },
  variants: {
    secondary,
  },
});

export { radioTheme };
