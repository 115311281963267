import {
  Box,
  Flex,
  IconButton,
  Img,
  Show,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import { GamificationStats } from "@/components/Gamification/GamificationStats";
import { AlertSubscription } from "@/components/SuscriptionExpired/AlertSubscription";
import { useUser } from "@/providers/useUser";
import { HeaderMenu } from "./Menu";
import { HeaderProps } from "./types";
import mainLogo from "/img/logo_smartbeemo.svg";
import { useEffect, useState } from "react";
import { AlertProgramsToDeprecate } from "@/components/ProgramsToDeprecate/AlertProgramsToDeprecate";
import { NotificationsMenu } from "./Notifications";

const NewHeader = ({
  onClickMenuButton,
  sideBar,
}: HeaderProps): JSX.Element => {
  const [isBlurred, setIsBlurred] = useState(false);
  const {
    user,
    paymentMethodLink,
    lastPaymentDeclined,
    programsToDeprecateTitles,
    deprecatedProgramsUntil,
  } = useUser();

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const color = useColorModeValue("secondary.300", "white");
  const hoverColor = useColorModeValue("secondary.400", "white");

  const px = { base: "16px", md: "24px" };
  const subscriptionStatus = lastPaymentDeclined;
  useEffect(() => {
    setIsBlurred(isBlurred);
  }, [isBlurred]);

  return (
    <>
      {subscriptionStatus ? (
        <AlertSubscription updatePaymentMethodLink={paymentMethodLink} />
      ) : null}
      {programsToDeprecateTitles.length > 0 &&
      deprecatedProgramsUntil &&
      !isMobile ? (
        <AlertProgramsToDeprecate
          programTitles={programsToDeprecateTitles}
          availableUntil={deprecatedProgramsUntil}
        />
      ) : null}
      <Flex
        align={"center"}
        as={"header"}
        bgColor={"#fff"}
        color={color}
        h="var(--header-height)"
        justify="space-between"
        overflow="none"
        pos="sticky"
        top={0}
        w={"100%"}
        zIndex={isBlurred ? 1020 : 11}
        boxShadow={"1px 3px 20px 0px rgba(180, 180, 190, 0.08)"}
      >
        <Flex pl={px} w="270px">
          {sideBar !== "hide" && (
            <>
              <IconButton
                p={0}
                px={"0 !important"}
                mr={"12px"}
                _hover={{ color: hoverColor }}
                aria-label="Menu"
                bgColor="transparent"
                color="inherit"
                display="flex"
                hideFrom="lg"
                icon={
                  <Box
                    as={sideBar === "open" ? Ico.Times : Ico.Bars}
                    fontSize="24px"
                  />
                }
                onClick={onClickMenuButton}
              />

              <Img
                display={isMobile ? "block" : "none"}
                src={mainLogo}
                alt="logo"
                minH={"32px"}
                maxH={"32px"}
              />
            </>
          )}
        </Flex>
        <Flex align={"center"} gap={4} pr={px}>
          <Show above="xl">{user && <GamificationStats />}</Show>
          <NotificationsMenu onToggleBlur={setIsBlurred} />
          <HeaderMenu onToggleBlur={setIsBlurred} />
        </Flex>
      </Flex>
      {isBlurred && (
        <Box
          position="fixed"
          transition="ease-in-out"
          top={0}
          left={0}
          right={0}
          bottom={0}
          background="neutral.50"
          opacity={0.8}
          zIndex={1010}
        />
      )}
    </>
  );
};

export { NewHeader };
