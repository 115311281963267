/* eslint-disable no-console */
import { useEffect, useRef, useState } from "react";
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import ZoomVideo, { MediaDevice, TestSpeakerReturn } from "@zoom/videosdk";
import MicrophoneControl from "@/components/LiveSessionsZoom/PreSessions/Settings/Microphone/Microphone";
import CameraControl from "./Settings/Camera/Camera";
import SpeakerControl from "./Settings/Audio/Speaker";
import { t } from "i18next";
import {
  IconControlsCamera,
  IconControlsMicrophone,
  TypeIcon,
} from "../LiveVideo/Controls/IconsControls";
import { AppRoute } from "@/AppRoute";
import { useParams, useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { StartLiveSessionDocument } from "@/pages/academy/LiveClasses/graphql/startLiveSession.generated";

let localAudio = ZoomVideo.createLocalAudioTrack();
let localVideo = ZoomVideo.createLocalVideoTrack();
let PREVIEW_VIDEO: HTMLVideoElement | null = null;

const SettingsDevices = () => {
  const [searchParams] = useSearchParams();
  const urlRedirect = searchParams.get("redirect");
  const { liveSessionId } = useParams<{ liveSessionId: string }>();

  const toast = useToast();

  const [startLiveSession, { loading: loadingStartLiveSession }] = useMutation(
    StartLiveSessionDocument
  );

  const [isLoading, setIsLoading] = useState(true);

  const [isMicrophoneOn, setIsMicrophoneOn] = useState(false);
  const [isSpeakerOn, setIsSpeakerOn] = useState(false);
  const [activeMicrophone, setActiveMicrophone] = useState("");
  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [isStartedVideo, setIsStartedVideo] = useState(false);
  const [activeCamera, setActiveCamera] = useState("");
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [activeSpeaker, setActiveSpeaker] = useState("");
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [messageText, setMessageText] = useState("");
  const speakerTesterRef = useRef<TestSpeakerReturn | null>(null);

  useEffect(() => {
    setIsLoading(true);
    ZoomVideo.getDevices().then((devices) => {
      const mics = devices.filter((device) => device.kind === "audioinput");
      const cameras = devices.filter((device) => device.kind === "videoinput");
      const speakers = devices.filter(
        (device) => device.kind === "audiooutput"
      );

      const defaultMic = mics.find((mic) => mic.deviceId === "default");

      const defaultSpeaker = speakers.find(
        (speaker) => speaker.deviceId === "default"
      );

      setMicList(mics);
      setCameraList(cameras);
      setSpeakerList(speakers);

      if (defaultMic) {
        setActiveMicrophone(defaultMic.deviceId);
      }
      if (cameras.length > 0) {
        setActiveCamera(cameras[0].deviceId);
      }
      if (defaultSpeaker) {
        setActiveSpeaker(defaultSpeaker.deviceId);
      }

      setIsLoading(false);
    });
  }, []);

  const handleMicrophoneChange = async (deviceId: string) => {
    if (deviceId !== activeMicrophone) {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
        setAudioStream(null);
      }
      await localAudio.stop();
      localAudio = ZoomVideo.createLocalAudioTrack(deviceId);
      if (isMicrophoneOn) {
        await localAudio.start();
        const constraints = {
          audio: {
            deviceId: { exact: deviceId },
          },
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        setAudioStream(stream);
      }
      setActiveMicrophone(deviceId);
    }
  };

  const handleCameraChange = async (deviceId: string) => {
    if (localVideo && activeCamera !== deviceId) {
      try {
        await localVideo.stop();
      } catch (error) {
        console.error("Error stopping local video:", error);
        toast({
          description: t(
            "We couldn't stop your video. Please try again or check your camera settings"
          ),
          duration: 5000,
          variant: "solid",
          status: "error",
          isClosable: true,
        });
      }
      localVideo = ZoomVideo.createLocalVideoTrack(deviceId);
      if (isStartedVideo && PREVIEW_VIDEO) {
        try {
          await localVideo.start(PREVIEW_VIDEO);
        } catch (error) {
          console.error("Error starting local video:", error);
          toast({
            description: t(
              "We couldn't activate your camera. Please check your video connection or try another camera"
            ),
            duration: 5000,
            variant: "solid",
            status: "error",
            isClosable: true,
          });
        }
      }
      setActiveCamera(deviceId);
    }
  };

  const handleSpeakerChange = (deviceId: string) => {
    setActiveSpeaker(deviceId);
    if (speakerTesterRef.current) {
      speakerTesterRef.current.stop();
      speakerTesterRef.current.destroy();
      speakerTesterRef.current = null;
    }
    if (isSpeakerOn) {
      const speakerTester = localAudio.testSpeaker({ speakerId: deviceId });
      if (speakerTester) {
        speakerTesterRef.current = speakerTester;
      } else {
        speakerTesterRef.current = null;
      }
    }
  };

  const handleMicrophoneToggle = async () => {
    if (isMicrophoneOn) {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
        setAudioStream(null);
      }
      await localAudio.stop();
      setIsMicrophoneOn(false);
    } else {
      await localAudio.start();
      const constraints = {
        audio: {
          deviceId: activeMicrophone ? { exact: activeMicrophone } : undefined,
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setAudioStream(stream);
      setIsMicrophoneOn(true);
    }
  };

  const handleSpeakerToggle = async () => {
    if (isSpeakerOn) {
      if (speakerTesterRef.current) {
        speakerTesterRef.current.stop();
        speakerTesterRef.current.destroy();
        speakerTesterRef.current = null;
      }
      setIsSpeakerOn(false);
    } else {
      const speakerTester = localAudio.testSpeaker();
      if (speakerTester) {
        speakerTesterRef.current = speakerTester;
      }
      setIsSpeakerOn(true);
    }
  };

  const onCameraClick = async () => {
    if (isStartedVideo) {
      try {
        await localVideo?.stop();
        setIsStartedVideo(false);
      } catch (error) {
        console.error("Error stopping local video:", error);
        toast({
          description: "Error stopping local video: " + error,
          duration: 5000,
          variant: "solid",
          status: "error",
          isClosable: true,
        });
      }
    } else if (PREVIEW_VIDEO) {
      try {
        await localVideo?.start(PREVIEW_VIDEO);
        setIsStartedVideo(true);
      } catch (error) {
        console.error("Error starting local video:", error);
        toast({
          description: "Error starting local video: " + error,
          duration: 5000,
          variant: "solid",
          status: "error",
          isClosable: true,
        });
      }
    }
  };

  const handleEnterButtonClick = async () => {
    try {
      if (!liveSessionId) return;

      const selectedDevicesData = {
        camera: activeCamera,
        microphone: activeMicrophone,
        speaker: activeSpeaker,
        // isActiveCamera: isStartedVideo,
        // isActiveMicrophone: isMicrophoneOn,
        isActiveCamera: false,
        isActiveMicrophone: false,
        isActiveCameraShared: false,
        isActiveCameraVideo: false,
      };
      localStorage.setItem(
        "selectedDevices",
        JSON.stringify(selectedDevicesData)
      );

      await startLiveSession({
        variables: {
          liveSessionsId: liveSessionId,
        },
      });

      window.location.replace(
        `${AppRoute.LiveSessionBroadcast}/${liveSessionId}?redirect=${urlRedirect}`
      );
    } catch (error) {
      console.error("Error al iniciar la sesión en vivo:", error);
    }
  };

  useEffect(() => {
    const element = document.getElementById("js-preview-video");
    if (element) {
      PREVIEW_VIDEO = element as HTMLVideoElement;
    }
  }, []);

  useEffect(() => {
    let audioElement: HTMLAudioElement | null = null;

    const setupAudioStream = async () => {
      if (isMicrophoneOn) {
        const constraints = {
          audio: {
            deviceId: activeMicrophone
              ? { exact: activeMicrophone }
              : undefined,
          },
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        setAudioStream(stream);
      }
    };

    if (isMicrophoneOn) {
      setupAudioStream();
    }

    if (audioStream) {
      audioElement = new Audio();
      audioElement.srcObject = audioStream;
      audioElement.setSinkId(activeSpeaker).then(() => {
        audioElement?.play();
      });
    }

    return () => {
      if (audioElement) {
        audioElement.pause();
        audioElement.srcObject = null;
      }
    };
  }, [activeMicrophone, activeSpeaker, isMicrophoneOn]);

  useEffect(() => {
    if (!isStartedVideo && !isMicrophoneOn) {
      setMessageText("Tu cámara y micrófono están desactivados");
    } else if (!isStartedVideo) {
      setMessageText("Tu cámara está desactivada");
    } else {
      setMessageText("");
    }
  }, [isStartedVideo, isMicrophoneOn]);

  return (
    <Box
      p={8}
      bg={"shades.white"}
      borderRadius={"8px"}
      boxShadow={"0px 20px 50px 0px rgba(217, 217, 217, 0.20);"}
    >
      <Grid templateColumns="2fr 1fr" gap={16}>
        <Flex flexDirection="column" alignItems="center">
          <AspectRatio ratio={16 / 9} width="100%">
            <Box position="relative">
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px 8px 0 0",
                  background: "rgba(0, 0, 0, 1)",
                }}
                id="js-preview-video"
                className="preview-video"
                muted={true}
                data-video="0"
              ></video>
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                zIndex={1}
              >
                <Heading variant="h5" color="white" textAlign="center">
                  {messageText}
                </Heading>
              </Box>
            </Box>
          </AspectRatio>
          <Flex
            height="60px"
            alignItems="center"
            justifyContent="center"
            backgroundColor="secondary.300"
            width="100%"
            borderRadius={"0 0 8px 8px"}
          >
            <Flex flexDir={"column"} align={"center"} mr={4}>
              <IconButton
                onClick={handleMicrophoneToggle}
                aria-label={"IconControlsMicrophone"}
                bgColor={"transparent"}
                _hover={{ bgColor: "transparent" }}
              >
                <IconControlsMicrophone
                  type={
                    isMicrophoneOn
                      ? TypeIcon.MICROFONE_ON
                      : TypeIcon.MICROFONE_OFF
                  }
                />
              </IconButton>
              <Text
                textAlign={"center"}
                fontSize={"10px"}
                fontWeight={"600"}
                color={!isMicrophoneOn ? "#FF4848" : "#00E324"}
              >
                {isMicrophoneOn ? "Desactivar Micrófono" : "Activar Micrófono"}
              </Text>
            </Flex>

            <Flex flexDir={"column"} align={"center"} mr={4}>
              <IconButton
                onClick={onCameraClick}
                aria-label={"IconControlsCamera"}
                bgColor={"transparent"}
                _hover={{ bgColor: "transparent" }}
              >
                <IconControlsCamera
                  type={
                    isStartedVideo ? TypeIcon.CAMERA_ON : TypeIcon.CAMERA_OFF
                  }
                />
              </IconButton>
              <Text
                textAlign={"center"}
                fontSize={"10px"}
                fontWeight={"600"}
                color={!isStartedVideo ? "#FF4848" : "#00E324"}
              >
                {isStartedVideo ? "Desactivar Camara" : "Activar Camara"}
              </Text>
            </Flex>

            <Button onClick={handleSpeakerToggle} hidden>
              {isSpeakerOn ? "Turn Off Speaker" : "Turn On Speaker"}
            </Button>
          </Flex>
        </Flex>
        <Flex flexDirection="column" justifyContent="center">
          <Box mb={"16px"}>
            <Heading
              variant={"h6"}
              textAlign={"center"}
              mb={"16px"}
              color={"secondary.300"}
            >
              ¿Estás listo para ingresar?
            </Heading>
            <Text textAlign={"center"} color={"secondary.300"}>
              Recuerda probar y configurar tu video, micrófono y audio antes de
              ingresar a la sala.
            </Text>
          </Box>
          {isLoading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" color="primary.500" />
            </Flex>
          ) : (
            <>
              <CameraControl
                activeCamera={activeCamera}
                cameraList={cameraList}
                onCameraChange={handleCameraChange}
              />
              <MicrophoneControl
                activeMicrophone={activeMicrophone}
                micList={micList}
                onMicrophoneChange={handleMicrophoneChange}
              />
              <SpeakerControl
                activeSpeaker={activeSpeaker}
                speakerList={speakerList}
                onSpeakerChange={handleSpeakerChange}
              />
              <Flex justify={"center"} mt={"24px"}>
                <Button
                  color={"shades.white"}
                  bg={"primary.500"}
                  fontSize={"14px"}
                  w={"280px"}
                  borderRadius={"6px"}
                  onClick={handleEnterButtonClick}
                  isLoading={loadingStartLiveSession}
                >
                  {t("Enter")}
                </Button>
              </Flex>
            </>
          )}
        </Flex>
      </Grid>
    </Box>
  );
};

export default SettingsDevices;
