import { Button } from "@/components/Button";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Stream } from "@zoom/videosdk";
import { t } from "i18next";
import { useState, useCallback, useEffect } from "react";

interface modalActiveAudioProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  stream: typeof Stream | undefined | null;
}

export const ModalActiveAudio = ({
  isOpen,
  onClose,
  stream,
}: modalActiveAudioProps) => {
  const { hasRoles } = useUser();
  const isMentor = hasRoles([UserRole.Mentor]);
  const [loading, setLoading] = useState(false);
  const selectedDevices = localStorage.getItem("selectedDevices");

  const activeAudio = useCallback(async () => {
    setLoading(true);
    try {
      if (stream) {
        const devices = JSON.parse(selectedDevices || "{}");
        if (isMentor) {
          await stream.startAudio({
            autoStartAudioInSafari: false,
            syncButtonsOnHeadset: false,
            mute: true,
            microphoneId: devices.microphone || "default",
            speakerId: devices.speaker || "default",
          });
        } else {
          await stream.startAudio({
            autoStartAudioInSafari: false,
            speakerOnly: true,
            speakerId: devices.speaker || "default",
          });
        }

        if (isMentor) {
          await stream.muteAudio();
        }
      }
      setLoading(false);
      onClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error activating audio:", error);
      setLoading(false);
    }
  }, [stream, isMentor, selectedDevices, onClose]);

  useEffect(() => {
    if (isMentor && stream && stream.isAudioMuted && !stream.isAudioMuted()) {
      stream.muteAudio();
    }
  }, [isMentor, stream]);

  return (
    <Modal
      size={{ base: "sm", lg: "lg" }}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        w={{ base: "90%", lg: "45%" }}
        backgroundColor={"shades.white"}
        borderRadius={"8px"}
        height="auto"
        display={"flex"}
      >
        <ModalBody>
          <Box
            display={"flex"}
            flexDirection={{ base: "column", lg: "row" }}
            alignItems={{ base: "flex-start", lg: "center" }}
            justifyContent={"center"}
            gap={2}
            h={"100%"}
          >
            <Box display={"flex"} flexDirection={"column"} gap={2} w={"100%"}>
              <Text
                color={"secundary.300"}
                fontSize={"14px"}
                fontWeight={"bold"}
              >
                👋 {t("Welcome to the Live Session!")}
              </Text>
              <Text
                color={"secundary.300"}
                fontWeight={"400"}
                fontSize={"14px"}
              >
                {t("Click to start and enjoy the session to the fullest.")}
              </Text>
            </Box>
            <Box
              w={{ base: "100%", lg: "auto" }}
              display={"flex"}
              justifyContent={{ base: "stretch", lg: "center" }}
            >
              <Button
                title={t("Entrar")}
                onClick={activeAudio}
                variant="primary"
                borderRadius={"6px"}
                fontSize={"14px"}
                isLoading={loading}
                w={{ base: "100%", lg: "auto" }}
              />
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
