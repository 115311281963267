import { useLazyQuery } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect, useState } from "react";

import { config } from "@/config";
import { GetUserDocument } from "@/graphql/getUser.generated";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { refreshToken, signOut } from "@/services/authService";

import { UserContext, UserHook } from "./UserProvider";

const useUser = (): UserHook => {
  const context = React.useContext(UserContext);
  if (context === null) {
    throw new Error(`useUser must be used within a UserContext`);
  }
  return context;
};

interface UserAuth {
  isAuthenticated: boolean;
  isLoading: boolean;
}

const useUserAuth = (): UserAuth => {
  const { setUser, user } = useUser();
  const [isCheckingSession, setIsCheckingSession] = useState(true);
  const [userToken] = useLocalStorage(config.JWT_KEY, null);
  const [storedRefreshToken] = useLocalStorage(config.RT_KEY, null);
  const [requestUserData, { data, loading: isLoadingUser, client }] =
    useLazyQuery(GetUserDocument);
  const [loadingUser, setLoadingUser] = useState(true);

  const handleSignOut = async (): Promise<void> => {
    await signOut();
    setUser(null);
    if (!client) return;
    await client.resetStore();
    client.stop();
  };

  useEffect(() => {
    if (!isLoadingUser) {
      setTimeout(() => {
        setLoadingUser(false);
      }, 400);
    }
  }, [isLoadingUser]);

  useEffect(() => {
    if (data) {
      if (data.me) {
        setUser(data.me);

        if (config.VITE_APP_ENV === "production") {
          datadogRum.startSessionReplayRecording();
        }
      } else {
        handleSignOut();
      }
      setIsCheckingSession(false);
    }
  }, [data?.me]);

  useEffect(() => {
    if (userToken) {
      requestUserData();
    } else if (storedRefreshToken) {
      refreshToken(true)
        .then(() => requestUserData())
        .catch(() => {
          setIsCheckingSession(false);
        });
    } else {
      setIsCheckingSession(false);
    }
  }, [userToken, storedRefreshToken]);

  return {
    isAuthenticated: !!user,
    isLoading: isCheckingSession || isLoadingUser || loadingUser,
  };
};

export { useUser, useUserAuth };
