import { Heading } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { PageContainer } from "@/components/PageContainer";
import { CategoryPills } from "./CategoryTabs";
import { SearchBar } from "./SearchBar";
import { SubcategoryPills } from "./SubcategoryPills";
import { ServicesGrid } from "./ServicesGrid";
import { useGateway } from "../gatewayLayer/useGateway";
import { BigSpinner } from "@/components/BigSpinner";

const ServicesMarketplaceComponent: React.FC = () => {
  const { loading } = useGateway();

  return (
    <PageContainer title={"Agencias"}>
      <Heading as={"h4"} mb={"24px"}>
        <Trans>Servicios profesionales para tu Ecommerce.</Trans>
      </Heading>
      {loading ? (
        <BigSpinner></BigSpinner>
      ) : (
        <>
          <SearchBar></SearchBar>
          <CategoryPills></CategoryPills>
          <SubcategoryPills></SubcategoryPills>
          <ServicesGrid></ServicesGrid>
        </>
      )}
    </PageContainer>
  );
};

export default ServicesMarketplaceComponent;
