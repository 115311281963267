import { useMemo, useEffect } from "react";
import { GrowthBook } from "@growthbook/growthbook-react";
import { config } from "@/config";
import { useUser } from "@/providers/useUser";

const useGrowthBook = () => {
  const { user } = useUser();
  const growthbook = useMemo(() => {
    const gb = new GrowthBook({
      apiHost: config.VITE_GROWTHBOOK_API_HOST,
      clientKey: config.VITE_GROWTHBOOK_CLIENT_KEY,
      enableDevMode: true,
      attributes: {
        id: user?.id,
      },
      trackingCallback: (experiment, result) => {
        // eslint-disable-next-line no-console
        console.log("Experiment Viewed", {
          experimentId: experiment.key,
          variationId: result.key,
          result: result,
          experiment: experiment,
          user: user?.id,
        });
      },
    });
    gb.init({
      streaming: true,
    });
    return gb;
  }, [user?.id]);

  useEffect(() => {
    let unixTimestamp;
    if (user?.createdAt) {
      unixTimestamp = Math.floor(new Date(user?.createdAt).getTime() / 1000);
    }
    if (user) {
      growthbook.setAttributes({
        id: user?.id,
        memberSince: unixTimestamp ?? null,
      });
      growthbook.refreshFeatures();
    }
  }, [user, growthbook]);

  return growthbook;
};

export default useGrowthBook;
