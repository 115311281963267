import { GatewayLayerProvider } from "./gatewayLayer/provider";
import { Presenter } from "./presenter/provider";
import { DataLayerProvider } from "./dataLayer/provider";
import ServicesMarketplaceComponent from "./components/ServicesMarketplace";

const ServicesMarketplacePage: React.FC = () => {
  return (
    <GatewayLayerProvider>
      <DataLayerProvider>
        <Presenter>
          <ServicesMarketplaceComponent></ServicesMarketplaceComponent>
        </Presenter>
      </DataLayerProvider>
    </GatewayLayerProvider>
  );
};

export default ServicesMarketplacePage;
