import { BootcampClass, BootcampMentor, Faq } from "@/schemaTypes";
import { ModulesAndSessions } from "../Tabs/utils";

export interface AccordionCardProps {
  item: BootcampClass;
  isRegistered?: boolean;
}

export interface AccordionFaqs {
  data: Faq[];
}

export interface FaqsAccordionProps {
  accordionContent: AccordionFaqs;
}

export interface AccordionModulesProps {
  accordionContent: AccordionModulesData;
  isRegistered?: boolean;
  onClickEnroll?: () => void;
}

export interface AccordionModulesData {
  data?: ModulesAndSessions[];
  component?: (item: BootcampClass) => JSX.Element;
}

export const formatMentors = (mentors: BootcampMentor[]): string => {
  if (!mentors || mentors.length === 0) return "";

  const mentorTitle = mentors.length >= 2 ? "Profesores: " : "Profesor: ";
  const mentorNames = `${mentors.slice(0, -1).join(", ")}${
    mentors.length >= 2 ? " y " : ""
  }${mentors.slice(-1)}`;

  return `${mentorTitle}${mentorNames}`;
};

export const formatAccordionTitle = (title?: string): string => {
  if (!title) return "";
  return title.includes("-") ? title.split("-")[1].trim() : title;
};
