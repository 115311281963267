import {
  Flex,
  Heading,
  Img,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { Button } from "@/components/Button";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";

const PasswordAlreadyCreatedModal = () => {
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const warnSparkle =
    "https://storage.googleapis.com/lms-stg-media/warn-sparkle.svg";

  return (
    <Modal
      isOpen
      onClose={() => {}}
      size={"lg"}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay bg={"rgba(68, 68, 72, 0.8)"} />
      <ModalContent mx={isMobile ? "16px" : 0} h={"404px"}>
        <ModalBody px={"24px"} py={"48px"}>
          <Flex
            w={"100%"}
            h={"100%"}
            align={"center"}
            justify={"center"}
            flexDir={"column"}
            textAlign={"center"}
          >
            <Img w={"125px"} src={warnSparkle} alt="Warning" mb={"16px"} />

            <Heading as={"h6"} mb={"16px"}>
              <Trans>Password is already set</Trans>
            </Heading>

            <Text mb={"32px"} variant={"placeholder"}>
              <Trans>Click the button to login</Trans>
            </Text>

            <Button
              title="Login"
              variant="primary"
              w={"100%"}
              gap={3}
              onClick={() => navigate(AppRoute.Base)}
              rightIcon={<Ico.ArrowRight />}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PasswordAlreadyCreatedModal;
