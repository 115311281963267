/* eslint-disable @typescript-eslint/no-explicit-any */

import { DISPATCH_TYPE } from "../../types/LiveBar.type";

export const clientStore = {
  isAuth: false,
  password: "",
  token: "",
  topic: "",
  userName: "",
  zmClient: undefined,
  error: undefined,
  dataSessionAuth: undefined,
};

export const clientReducer = (
  state: any,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;

  switch (type) {
    case DISPATCH_TYPE.CONNECT_CLIENT:
      return {
        ...state,
        isAuth: payload.isAuth,
        password: payload.password,
        token: payload.token,
        topic: payload.topic,
        userName: payload.userName,
        zmClient: payload.zmClient,
        error: false,
        dataSessionAuth: payload.dataSessionAuth,
      };

    case DISPATCH_TYPE.DISCONNECT_CLIENT:
      return {
        ...state,
        isAuth: false,
        error: payload.error,
        password: undefined,
        token: undefined,
        topic: undefined,
        userName: undefined,
        zmClient: undefined,
        dataSessionAuth: undefined,
      };

    default:
      break;
  }
};
