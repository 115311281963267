import { extendTheme } from "@chakra-ui/react";
import type { Dict } from "@chakra-ui/utils";

import { breadcrumbTheme } from "../Navigation/BreadCrumb/theme";
import { buttonTheme } from "../Button/theme";
import { inputTheme } from "../Forms/Input/theme";
import { radioTheme } from "../Forms/Radio/theme";
import { textareaTheme } from "../Forms/TextArea/theme";

import { colors } from "./colors";
import { fontWeight, textTheme } from "./Text";
import { tabsTheme } from "../Navigation/Tabs/tabsTheme";

const theme = extendTheme({
  breakpoints: {
    "2xl": "1440px",
  },
  initialColorMode: "light",
  useSystemColorMode: true,
  fonts: {
    body: "Inter, sans-serif",
    heading: "Poppins, sans-serif",
  },
  colors,
  components: {
    Breadcrumb: breadcrumbTheme,
    Button: buttonTheme,
    Chips: {
      baseStyle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: fontWeight.bold,
        lineHeight: "18.6px",
        color: "black",
      },
    },
    Divider: {
      baseStyle: {
        borderColor: "neutral.200",
        borderWidth: 2,
      },
    },
    Heading: {
      baseStyle: ({ as }: { as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" }) => {
        const base = {
          color: "inherit",
          fontFamily: "Poppins, sans-serif",
          fontSize: "24px",
          fontWeight: fontWeight.bold,
          lineHeight: "1.5",
        };
        const fontSize = {
          h1: "56px",
          h2: "48px",
          h3: "40px",
          h4: "24px",
          h5: "20px",
          h6: "18px",
        };
        return Object.assign({}, base, { fontSize: fontSize[as] });
      },
      defaultProps: {
        size: "dynamic",
      },
      sizes: {
        dynamic: {},
      },
    },
    Input: inputTheme,
    Text: textTheme,
    Link: {
      baseStyle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: fontWeight.bold,
        lineHeight: "18.6px",
        textDecoration: "underline",
      },
    },
    FormLabel: {
      baseStyle: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: fontWeight.bold,
      },
    },
    Radio: radioTheme,
    Textarea: textareaTheme,
    Tabs: tabsTheme,
  },
});

const mergeThemeWithDefaults = (): Dict => extendTheme(theme);

export { mergeThemeWithDefaults, theme };
