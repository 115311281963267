/* eslint-disable react-hooks/rules-of-hooks */
import { AppRoute } from "@/AppRoute";
import { Button } from "@/components/Button";
import { Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { activeStyles, useIsSubPageActive } from "./utils";
import { Ico } from "@/assets/icons";
import { colors } from "@/components/Theme/colors";

interface ProdictsTabsProps {
  showPaddingLeft?: boolean;
  validateRole?: boolean;
  darkHover?: boolean;
}

const ProductsTabs: React.FC<ProdictsTabsProps> = ({
  showPaddingLeft = true,
  darkHover = false,
  validateRole,
}) => {
  const navigate = useNavigate();
  const arrayTabs = [
    {
      label: "Recomendados",
      route: validateRole
        ? AppRoute.Productselection
        : `${AppRoute.Products}/true`,
    },
    {
      label: "Agente en China",
      route: validateRole ? AppRoute.ChinaAgent : `${AppRoute.Products}/true`,
    },
  ];

  const handleEventNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <Flex w="100%" flexDir={"column"}>
      {arrayTabs.map((button) => (
        <Button
          key={`${button.label}-button`}
          title={button.label}
          variant="link"
          px={"16px"}
          pl={showPaddingLeft ? "36px !important" : 0}
          py={"8px"}
          textAlign={"left"}
          display={"block"}
          color="secondary.300"
          borderRadius={"8px"}
          onClick={() => handleEventNavigate(button.route)}
          {...(useIsSubPageActive([button.route]) &&
            validateRole &&
            activeStyles.subPageButton)}
          _hover={{ bg: darkHover ? "neutral.100" : "neutral.50" }}
        >
          <Flex as={"span"} display={"flex"} alignItems={"center"} gap={"6px"}>
            <Text variant={"placehoder"} fontSize={"14px"}>
              {button.label}
            </Text>
            {!validateRole ? (
              <Ico.Lock02 fontSize={"16px"} color={colors.secondary[100]} />
            ) : undefined}
          </Flex>
        </Button>
      ))}
    </Flex>
  );
};

export default ProductsTabs;
