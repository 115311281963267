/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {
  Select,
  SelectProps,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";
import React from "react";

export const SelectStyled: React.FC<Omit<SelectProps, "as">> = (props) => {
  const {
    colors: { secondary },
  } = useTheme();

  const borderColor = useColorModeValue("gray.300", "gray.600");
  const bg = useColorModeValue("gray.50", "gray.700");

  return (
    <Select
      borderColor={borderColor}
      bg={bg}
      _hover={{ borderColor: "primary.400" }}
      _focus={{
        borderColor: "secondary.400",
        boxShadow: `0 0 0 1px ${secondary}`,
      }}
      {...props}
    />
  );
};
