/* eslint-disable no-constant-condition */
/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {
  Box,
  Flex,
  FormLabel,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { FlexCard } from "../components/Cards/FlexCard";
import { InputStyled } from "../components/Forms/InputStyled";
import { PasswordInput } from "../components/PasswordInput";
import { resetPassword } from "../services/authService";
import { ReviewEmailForgotPassword } from "../components/ReviewEmailForgotPassword";
import { config } from "../config";
import { PublicRoute } from "@/AppRoute";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { Button } from "@/components/Button";

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams<"token">();
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [passwordCheck, setPasswordCheck] = useState("");
  const [isResettingPassword, setIsRessetingPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordValidLength, setisPasswordValidLength] = useState(false);
  const [isPasswordValidAlphanumeric, setisPasswordValidAlphanumeric] =
    useState(false);
  const [isSuccessResetPassword, setisSuccessResetPassword] = useState(false);

  const handlePasswordChange = (passwordValue: string) => {
    setPassword(passwordValue);
    setisPasswordValidLength(passwordValue.length >= 8);
    setisPasswordValidAlphanumeric(
      /^(?=.*[!@#$%^&*()_+\d]).+$/.test(passwordValue)
    );
  };

  const handleFieldChange =
    (setFn: Dispatch<SetStateAction<string>>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setFn(value);
      if (password !== value) {
        setErrorMessage("Las contraseñas no coinciden");
      } else {
        setErrorMessage("");
      }
    };

  const handleResetPassword = async () => {
    if (recaptchaToken === null) {
      setErrorMessage(t("Invalid recaptcha check"));
      return;
    }

    if (!token) {
      setErrorMessage(t("Invalid token"));
      return;
    }

    setIsRessetingPassword(true);
    try {
      setErrorMessage("");
      await resetPassword(token, password, recaptchaToken);
      setisSuccessResetPassword(true);
    } catch (signUpError: unknown) {
      setisSuccessResetPassword(false);

      const message =
        signUpError && (signUpError as Error).message
          ? (signUpError as Error).message
          : t("Error resetting password, please try again");
      setErrorMessage(message);

      SBErrorPubSub.publish({
        component: "ResetPasswordPage.tsx line 97",
        message: message,
        showInProd: true,
      });
    } finally {
      setIsRessetingPassword(false);
    }
  };

  const onRecaptcha = (recaptchaResponse: string | null) => {
    setRecaptchaToken(recaptchaResponse);
  };

  const validationErrors: Record<string, Array<string>> = {
    password: password.length > 7 ? [] : [t("Invalid Password")],
    passwordCheck:
      password === passwordCheck ? [] : [t("Password check does not work")],
    recaptchaToken:
      recaptchaToken && recaptchaToken.length > 0
        ? []
        : [t("Invalid recaptcha check")],
  };

  const isFormValid = Object.keys(validationErrors).every(
    (key) => validationErrors[key].length === 0
  );

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <FlexCard
      rounded="md"
      shadow="lg"
      p="10"
      direction="column"
      alignItems="center"
      h={"100vh"}
      backgroundColor={"shades.white"}
      margin={0}
      padding={0}
      px={{ base: 5, lg: 10 }}
      py={{ base: 5, lg: 0 }}
    >
      {errorMessage === "Error Resetting password: Invalid Token" && (
        <Modal
          isOpen={true}
          onClose={() => navigate(`${PublicRoute.ForgotPasssword}`)}
          isCentered
        >
          <Flex mb={5}>
            <ModalOverlay />
            <ModalContent
              mx={{ base: 4, sm: 8, md: 10 }}
              my={{ base: 8, md: 12, lg: 20 }}
              p={4}
            >
              <ModalHeader>
                <Heading textAlign={"center"} color={"primary.300"}>
                  {t("InvalidToken")}
                </Heading>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box mx={"10px"}>
                  <Text textAlign={"justify"}>{t("invalidTokenText")}</Text>
                </Box>
              </ModalBody>
            </ModalContent>
          </Flex>
        </Modal>
      )}
      <Box w={"100%"}>
        <Image
          src={"/img/logo_smartbeemo.svg"}
          alt={`Smartbeemo Logo`}
          h="80px"
          w="144px"
          htmlHeight="70"
          htmlWidth="132"
          objectFit="contain"
          opacity={"/img/logo_smartbeemo.svg" ? 1 : 0}
          display={{ base: "none", sm: "none", md: "block", lg: "block" }}
        />

        <Image
          src={"/img/beemo.svg"}
          alt={`Smartbeemo Logo`}
          h="36px"
          w="26px"
          htmlHeight="70"
          htmlWidth="132"
          objectFit="contain"
          opacity={"/img/beemo.svg" ? 1 : 0}
          display={{ base: "block", sm: "block", md: "none", lg: "none" }}
        />
      </Box>

      {!isSuccessResetPassword ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          maxW={{ base: "100%", lg: "443px" }}
          minW={!isMobile ? "480px" : undefined}
          flexDirection={"column"}
          height={"100%"}
          mb={20}
        >
          <Heading as={isMobile ? "h5" : "h4"} textAlign={"start"} mb="4">
            <Trans>Update your password</Trans>
          </Heading>

          <Box mb={8}>
            <Text color={"secondary.200"}>
              <Trans>
                Your new password must be different from the old one.
              </Trans>
            </Text>
          </Box>

          <Box justifyContent="center" mb="4">
            <FormLabel
              color={"secondary.50"}
              fontWeight={"semibold"}
              fontSize={"14px"}
            >
              <Trans>Password</Trans>
            </FormLabel>
            <PasswordInput onChange={handlePasswordChange} />

            <Box mt={"12px"}>
              <Box display={"flex"} alignItems={"center"} gap={2}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  rounded={"full"}
                  h="16px"
                  w="16px"
                  backgroundColor={
                    !isPasswordValidLength ? "neutral.200" : "success.300"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.76854 2.64629C7.96547 2.84212 7.96637 3.16052 7.77054 3.35745L4.17021 6.97814C4.07582 7.07307 3.94749 7.12644 3.81362 7.12644C3.67976 7.12644 3.55142 7.07307 3.45704 6.97814L2.22978 5.74395C2.03395 5.54701 2.03485 5.22861 2.23179 5.03278C2.42873 4.83695 2.74713 4.83785 2.94296 5.03479L3.81362 5.91038L7.05737 2.64829C7.2532 2.45135 7.5716 2.45046 7.76854 2.64629Z"
                      fill="#F7F8F9"
                    />
                  </svg>
                </Box>
                <Box>
                  <Text fontSize={"12px"}>{t("min8chars")}</Text>
                </Box>
              </Box>

              <Box display={"flex"} alignItems={"center"} mt={"17px"} gap={2}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  rounded={"full"}
                  h="16px"
                  w="16px"
                  backgroundColor={
                    !isPasswordValidAlphanumeric ? "neutral.200" : "success.300"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.76854 2.64629C7.96547 2.84212 7.96637 3.16052 7.77054 3.35745L4.17021 6.97814C4.07582 7.07307 3.94749 7.12644 3.81362 7.12644C3.67976 7.12644 3.55142 7.07307 3.45704 6.97814L2.22978 5.74395C2.03395 5.54701 2.03485 5.22861 2.23179 5.03278C2.42873 4.83695 2.74713 4.83785 2.94296 5.03479L3.81362 5.91038L7.05737 2.64829C7.2532 2.45135 7.5716 2.45046 7.76854 2.64629Z"
                      fill="#F7F8F9"
                    />
                  </svg>
                </Box>
                <Box>
                  <Text fontSize={"12px"}>{t("almost1SpecialChart")}</Text>
                </Box>
              </Box>
            </Box>

            <FormLabel
              color={"secondary.50"}
              fontWeight={"semibold"}
              fontSize={"14px"}
              mt={5}
            >
              <Trans>Confirm password</Trans>
            </FormLabel>
            <InputStyled
              borderColor={errorMessage ? "error.200" : "neutral.300"}
              _focus={{
                border: "2px solid var(--primarios-main, #109B9D)",
              }}
              _hover={{
                border: "1px solid var(--primarios-200, #37B3B4)",
              }}
              mb="2"
              onChange={handleFieldChange(setPasswordCheck)}
              type="password"
              name="password-check"
              placeholder={t("Repeat Password")}
            />

            {errorMessage &&
              errorMessage !== "Error Resetting password: Invalid Token" && (
                <Box
                  display={"flex"}
                  gap={"10px"}
                  color={"secondary.100"}
                  fontSize={"12px"}
                >
                  <Box>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M0.666626 14.0002H15.3333L7.99996 1.3335L0.666626 14.0002ZM8.66663 12.0002H7.33329V10.6668H8.66663V12.0002ZM8.66663 9.3335H7.33329V6.66683H8.66663V9.3335Z"
                        fill="#FF5858"
                      />
                    </svg>
                  </Box>
                  <p>{errorMessage}</p>
                </Box>
              )}

            <Box
              display={"flex"}
              justifyContent={"center"}
              mb="10"
              mt="5"
              w={"100%"}
            >
              <ReCAPTCHA
                sitekey={config.RECAPTCHA_SITE_KEY}
                onChange={onRecaptcha}
              />
            </Box>

            <Button
              isLoading={isResettingPassword}
              isDisabled={isResettingPassword || !isFormValid}
              onClick={handleResetPassword}
              variant={"primary"}
            >
              <Text variant={"bold"} fontSize={16}>
                <Trans>Update password</Trans>
              </Text>
            </Button>
          </Box>
        </Box>
      ) : (
        <ReviewEmailForgotPassword email="" isSuccess={true} />
      )}
    </FlexCard>
  );
};

export default ResetPasswordPage;
