import React, { useState } from "react";
import { EntityName } from "../../../schemaTypes";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Text,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { InputField } from "../../../components/Forms/InputField";
import { useFormik } from "formik";
import { SelectStyled } from "../../../components/Forms/SelectStyled";
import { useMutation, useQuery } from "@apollo/client";
import BadgeRegisterSuccesAlert from "../../../components/Badges/BadgeRegisterSuccessAlert";
import { CreateBadgeDocument } from "../graphql/createBadge.generated";
import { GetBadgeTriggersDocument } from "../graphql/getBadgesTriggers.generated";
import { PageContainer } from "@/components/PageContainer";
// import useValidation from "./useValidation";

enum BadgeTypes {
  phase1 = "DIPLOMA_STARTED",
  phase3 = "COMPLETED_OPERATING_PLATFORM",
  phase5 = "COMPLETED_MEASUREMENT_ANALYSIS",
  diplomatCompleted = "COMPLETED_DIPLOMAT",
  advancedCourseCompleted = "COMPLETED_ADVANCED_COURSE",
  specialiationCompleted = "COMPLETED_SPECIALIZATION",
}

export const CreateBadgeForm: React.FC = () => {
  const { t } = useTranslation();
  const [selectedBadgeType, setSelectedBadgeType] = useState<string>("");

  const [createBadge, { loading, data, error }] =
    useMutation(CreateBadgeDocument);

  // const badgeRegisterValidationSchema = useValidation();

  const badgesTriggersResult = useQuery(GetBadgeTriggersDocument, {
    variables: {
      diplomatSearchInput: {
        entityName: EntityName.Diplomat,
      },
      specializationSearchInput: {
        entityName: EntityName.Specialization,
      },
      courseType: EntityName.AdvancedCourse,
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      color: "",
      imageUrl: {
        mini: "",
        complete: "",
      },
      trigger: {
        type: "",
        id: "",
      },
    },
    onSubmit: (values) => {
      createBadge({
        variables: {
          createBadgeInput: values,
        },
      });
    },
    // validationSchema: badgeRegisterValidationSchema,
    validateOnChange: true,
  });

  const badgeTriggers = {
    [BadgeTypes.diplomatCompleted]: badgesTriggersResult.data?.diplomats,
    [BadgeTypes.advancedCourseCompleted]:
      badgesTriggersResult.data?.courses.map((course) => ({
        id: course.id,
        name: course.code,
      })),
    [BadgeTypes.specialiationCompleted]:
      badgesTriggersResult.data?.specializations,
  };

  const calculateTriggerIdIsDisabled = () => {
    return (
      [BadgeTypes.phase1, BadgeTypes.phase3, BadgeTypes.phase5].includes(
        selectedBadgeType as BadgeTypes
      ) || !selectedBadgeType
    );
  };

  return (
    <PageContainer>
      <Flex
        as={"form"}
        w={"80%"}
        mx={"auto"}
        gap={4}
        flexDir={"column"}
        onSubmit={(event) => {
          event.preventDefault();
          formik.handleSubmit();
        }}
      >
        <InputField
          isRequired
          name="name"
          label={t("Name")}
          onChange={formik.handleChange("name")}
          onPaste={formik.handleChange("name")}
          value={formik.values.name}
          validator={() => (formik.errors.name ? [formik.errors.name] : [])}
        />

        <InputField
          isRequired
          name="color"
          label="Color"
          onChange={formik.handleChange("color")}
          value={formik.values.color}
          validator={() => (formik.errors.color ? [formik.errors.color] : [])}
        />

        {/* badge urls */}
        <Grid templateColumns="repeat(2, 1fr)" gap={6} pb={5}>
          <InputField
            isRequired
            label={t("Mini badge url")}
            name="imageUrl.mini"
            value={formik.values.imageUrl.mini}
            onChange={formik.handleChange("imageUrl.mini")}
            onPaste={formik.handleChange("imageUrl.mini")}
            validator={() =>
              formik.errors.imageUrl?.mini ? [formik.errors.imageUrl.mini] : []
            }
          />
          <InputField
            isRequired
            label={t("Complete badge url")}
            name="imageUrl.complete"
            value={formik.values.imageUrl.complete}
            onChange={formik.handleChange("imageUrl.complete")}
            validator={() =>
              formik.errors.imageUrl?.complete
                ? [formik.errors.imageUrl.complete]
                : []
            }
          />
        </Grid>

        {/* badge trigger */}
        <Grid templateColumns="repeat(2, 1fr)" gap={6} pb={5}>
          <FormControl isRequired>
            <Text as={FormLabel} variant={"bold"} htmlFor={"trigger.type"}>
              <Trans>Badge type</Trans>
            </Text>

            <SelectStyled
              id="trigger.type"
              name="trigger.type"
              defaultValue={formik.values.trigger.type}
              onChange={(e) => {
                formik.handleChange("trigger.type")(e);
                formik.setFieldValue("trigger.id", "");
                setSelectedBadgeType(e.target.value);
              }}
            >
              <option value="">{t("Select an option")}</option>
              {Object.values(BadgeTypes).map((key) => (
                <option key={key} value={key}>
                  {t(key)}
                </option>
              ))}
            </SelectStyled>
          </FormControl>

          <FormControl
            isRequired={!calculateTriggerIdIsDisabled()}
            isDisabled={calculateTriggerIdIsDisabled()}
          >
            <Text as={FormLabel} variant={"bold"} htmlFor={"trigger.id"}>
              <Trans>Badge trigger</Trans>
            </Text>
            <SelectStyled
              id="trigger.id"
              name="trigger.id"
              onChange={formik.handleChange("trigger.id")}
              defaultValue={formik.values.trigger.id}
            >
              <option value="">{t("Select an option")}</option>
              {badgeTriggers[
                selectedBadgeType as keyof typeof badgeTriggers
              ]?.map((key) => (
                <option key={key.id} value={key.id}>
                  {t(key.name)}
                </option>
              ))}
            </SelectStyled>
          </FormControl>
        </Grid>

        <Button
          type="submit"
          colorScheme="primary"
          isLoading={loading}
          isDisabled={!formik.isValid}
        >
          {t("Save")}
        </Button>

        <Flex>
          {data && <BadgeRegisterSuccesAlert />}
          {error && <Text>{error.message}</Text>}
        </Flex>
      </Flex>
    </PageContainer>
  );
};
