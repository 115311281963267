import { Box, Flex, TabPanel, Text, Spinner, Divider } from "@chakra-ui/react";
import { useContext, useEffect, useState, useCallback } from "react";
import ZoomClientContext from "../../context/zoom-context";
import ZoomMediaContext from "../../context/media-context";
import { Participant } from "@zoom/videosdk";
import { Button } from "@/components/Button";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { t } from "i18next";

interface GroupedParticipants {
  hostsAndManagers: Participant[];
  regularUsers: Participant[];
}

export const Participants = () => {
  const { zmClient } = useContext(ZoomClientContext);
  const zoomMediaContext = useContext(ZoomMediaContext);
  const [groupedParticipants, setGroupedParticipants] =
    useState<GroupedParticipants>({
      hostsAndManagers: [],
      regularUsers: [],
    });
  const [isLoading, setIsLoading] = useState(false);

  const isParticipantWithActiveMicrophone = useCallback(
    (participant: Participant) => {
      return (
        (participant.audio === "computer" || participant.audio === "phone") &&
        participant.muted === false &&
        !participant.isSpeakerOnly
      );
    },
    []
  );

  const updateParticipantsList = useCallback(() => {
    setIsLoading(true);
    const users = zmClient?.getAllUser();
    if (users) {
      const activeUsers = users.filter(isParticipantWithActiveMicrophone);
      const grouped = activeUsers.reduce<GroupedParticipants>(
        (acc, user) => {
          if (user.isHost || user.isManager) {
            acc.hostsAndManagers.push(user);
          } else {
            acc.regularUsers.push(user);
          }
          return acc;
        },
        { hostsAndManagers: [], regularUsers: [] }
      );
      setGroupedParticipants(grouped);
    }
    setIsLoading(false);
  }, [zmClient, isParticipantWithActiveMicrophone]);

  const muteUser = async (userId: number) => {
    if (!userId || userId === 0) return;
    try {
      await zoomMediaContext?.mediaContext.mediaStream?.muteAudio(userId);
      updateParticipantsList();
    } catch (error) {
      SBErrorPubSub.publish({
        component: "Participants.tsx muteUser",
        message: t("An error occurred while muting the user"),
        showInProd: true,
      });
    }
  };

  useEffect(() => {
    const handleUserUpdated = () => {
      updateParticipantsList();
    };

    zmClient?.on("user-added", handleUserUpdated);
    zmClient?.on("user-removed", handleUserUpdated);
    zmClient?.on("user-updated", handleUserUpdated);
    zmClient?.on("audio-change", handleUserUpdated);

    updateParticipantsList();

    return () => {
      zmClient?.off("user-added", handleUserUpdated);
      zmClient?.off("user-removed", handleUserUpdated);
      zmClient?.off("user-updated", handleUserUpdated);
      zmClient?.off("audio-change", handleUserUpdated);
    };
  }, [zmClient, updateParticipantsList]);

  const renderParticipantList = (participants: Participant[]) =>
    participants.map((participant, index) => (
      <Flex
        key={participant.userId}
        w="100%"
        alignItems="center"
        mb={index < participants.length - 1 ? 4 : 0}
      >
        <Box w="100%">
          <Text color="white" fontWeight="bold">
            {participant.displayName}
            {participant.isHost && " (Host)"}
            {participant.isManager && !participant.isHost && " (Co-host)"}
          </Text>
          <Text color="white" fontSize="sm">
            {participant.audio === "computer"
              ? t("Audio por ordenador")
              : t("Audio por teléfono")}
          </Text>
        </Box>
        <Box>
          <Button onClick={() => muteUser(participant.userId)}>
            {t("Silenciar")}
          </Button>
        </Box>
      </Flex>
    ));

  if (isLoading) {
    return (
      <TabPanel id="LiveChat-Participants" height="100%">
        <Flex justify="center" align="center" height="100%">
          <Spinner />
        </Flex>
      </TabPanel>
    );
  }

  return (
    <TabPanel id="LiveChat-Participants" height="100%">
      <Box w="100%" flexDirection="column" display="flex" gap={5}>
        {groupedParticipants.hostsAndManagers.length === 0 &&
        groupedParticipants.regularUsers.length === 0 ? (
          <Text color="white">
            {t("No hay participantes con micrófono activo")}
          </Text>
        ) : (
          <>
            {groupedParticipants.hostsAndManagers.length > 0 && (
              <Box>
                <Text color="white" fontWeight="bold" mb={2}>
                  {t("Hosts y Co-hosts")}
                </Text>
                {renderParticipantList(groupedParticipants.hostsAndManagers)}
              </Box>
            )}

            {groupedParticipants.hostsAndManagers.length > 0 &&
              groupedParticipants.regularUsers.length > 0 && (
                <Divider my={4} borderColor="gray.500" />
              )}

            {groupedParticipants.regularUsers.length > 0 && (
              <Box>
                <Text color="white" fontWeight="bold" mb={2}>
                  {t("Participantes")}
                </Text>
                {renderParticipantList(groupedParticipants.regularUsers)}
              </Box>
            )}
          </>
        )}
      </Box>
    </TabPanel>
  );
};
