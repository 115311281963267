import { Ico } from "@/assets/icons";
import { colors } from "@/components/Theme/colors";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Text,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { FaqsAccordionProps, formatAccordionTitle } from "../utils";

export const FaqsAccordion = ({ accordionContent }: FaqsAccordionProps) => {
  return (
    <Flex
      borderRadius={"6px"}
      flexDir={"column"}
      border={`1px solid ${colors.neutral[100]}`}
    >
      {accordionContent.data.map((item, index) => (
        <Fragment key={index}>
          <Accordion allowMultiple>
            <AccordionItem border={"none"}>
              {({ isExpanded }) => (
                <Fragment>
                  <AccordionButton
                    _hover={{ backgroundColor: "white" }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    p={4}
                    borderRadius={"6px"}
                  >
                    <Text
                      fontSize={"14px"}
                      textAlign={"start"}
                      variant={"bold"}
                    >
                      {formatAccordionTitle(item.question as string)}
                    </Text>
                    <Box
                      w={"24px"}
                      h={"24px"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      {isExpanded ? (
                        <Ico.Minus
                          fontSize={"24px"}
                          color={colors.primary[300]}
                        />
                      ) : (
                        <Ico.Plus
                          fontSize={"24px"}
                          color={colors.primary[300]}
                        />
                      )}
                    </Box>
                  </AccordionButton>

                  <AccordionPanel pt={0} pb={4} px={4}>
                    <Text fontSize={"12px"}>{item.answer}</Text>
                  </AccordionPanel>
                </Fragment>
              )}
            </AccordionItem>
          </Accordion>
          {index + 1 < accordionContent.data.length && (
            <Flex px={4}>
              <Divider
                h={"auto"}
                borderColor={colors.neutral[100]}
                color={colors.neutral[100]}
                borderBottom={"1px"}
                w={"full"}
              />
            </Flex>
          )}
        </Fragment>
      ))}
    </Flex>
  );
};
