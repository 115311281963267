/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {
  Box,
  Checkbox,
  Divider,
  Grid,
  Heading,
  useToast,
} from "@chakra-ui/react";
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { config } from "../config";
import { useUser } from "../providers/useUser";
import { signUpWithCredentials } from "../services/authService";
import { TestIds } from "../utils/testIds";
import { FlexCard } from "./Cards/FlexCard";
import { FormError } from "./Forms/FormError";
import { InputStyled } from "./Forms/InputStyled";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { Button } from "./Button";

interface SignUpCardProps {
  onSignInClick?: () => void;
  onSignUp?: () => void;
  hasContainer?: boolean;
}

export const SignUpCard: React.FC<SignUpCardProps> = ({
  onSignInClick,
  hasContainer,
  onSignUp,
}) => {
  const { currentSchool } = useUser();
  const schoolName = currentSchool?.name ?? "";
  const isProd = config.VITE_APP_ENV === "production";
  const { t } = useTranslation();
  const [queryParams] = useSearchParams();
  const token = queryParams.get("t") ?? "";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password] = useState("");
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [passwordScore] = useState<number | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(
    !isProd ? "test-recaptcha-token" : null
  );
  const [passwordCheck, setPasswordCheck] = useState("");
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const toast = useToast();

  const handleFieldChange =
    (setFn: Dispatch<SetStateAction<string>>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setFn(value);
    };

  const handleSignUp = async () => {
    if (recaptchaToken === null) {
      setErrorMessage(t("Invalid recaptcha check"));
      return;
    }

    const url = new URL(window.location.href);

    setIsCreatingUser(true);
    try {
      setErrorMessage("");
      await signUpWithCredentials({
        name,
        email,
        password,
        clientToken: recaptchaToken,
        domain: url.origin,
        returnURL: url.toString(),
        token,
      });

      toast({
        title: t("Account Created successfully"),
        status: "success",
        isClosable: true,
        duration: null,
        description: t("Check your email to activate your account"),
      });

      onSignUp?.();
    } catch (signUpError: unknown) {
      const message =
        signUpError && (signUpError as Error).message
          ? (signUpError as Error).message
          : t("Error Signing you up, please try again");

      setErrorMessage(message);

      SBErrorPubSub.publish({
        component: "SignUpCard.tsx",
        message: message,
        showInProd: true,
      });
    } finally {
      setIsCreatingUser(false);
    }
  };

  const onRecaptcha = (recaptchaResponse: string | null) => {
    setRecaptchaToken(recaptchaResponse);
  };

  const validationErrors: Record<string, Array<string>> = {
    email: isEmail(email) ? [] : [t("Invalid email")],
    name: name.length > 0 ? [] : [t("Invalid name")],
    password:
      password.length > 3 && passwordScore && passwordScore > 3
        ? []
        : [t("Invalid password")],
    passwordCheck:
      passwordCheck.length > 3 && password === passwordCheck
        ? []
        : [t("Password check does not match")],
    recaptchaToken:
      recaptchaToken && recaptchaToken.length > 0
        ? []
        : [t("Invalid recaptcha check")],
  };

  const isFormValid = Object.keys(validationErrors).every(
    (key) => validationErrors[key].length === 0
  );

  const onCheckTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTerms(e.target.checked);
  };

  const content = (
    <>
      <Heading as={"h5"} fontSize="xl" mb="4">
        <Trans t={t}>Sign Up</Trans>
      </Heading>
      <Box as="p" mb="6">
        {t("Welcome to the learning platform", { schoolName })}
      </Box>
      <Divider orientation="horizontal" my="6" />

      <Grid justifyContent="center" templateColumns="300px" mb="4" gap="3">
        <Box textAlign="left">
          <InputStyled
            position="relative"
            onChange={handleFieldChange(setName)}
            type="text"
            name="name"
            placeholder={t("Name")}
            borderColor={
              validationErrors.name.length > 0 ? "red.400" : undefined
            }
          />
          {validationErrors.name.length > 0 && (
            <FormError>{t(validationErrors.name[0])}</FormError>
          )}
        </Box>

        <Box textAlign="left">
          <InputStyled
            position="relative"
            onChange={handleFieldChange(setEmail)}
            type="email"
            name="email"
            placeholder={t("Email")}
            borderColor={
              validationErrors.email.length > 0 ? "red.400" : undefined
            }
          />
          {validationErrors.email.length > 0 && (
            <FormError>{t(validationErrors.email[0])}</FormError>
          )}
        </Box>

        <Box textAlign="left">
          <InputStyled
            position="relative"
            onChange={handleFieldChange(setPasswordCheck)}
            type="password"
            name="password-check"
            placeholder={t("Repeat Password")}
            borderColor={
              validationErrors.passwordCheck.length > 0 ? "red.400" : undefined
            }
          />
          {validationErrors.passwordCheck.length > 0 && (
            <FormError>{t(validationErrors.passwordCheck[0])}</FormError>
          )}
        </Box>

        {isProd ? (
          <Box mb="2" minH="70px">
            <ReCAPTCHA
              sitekey={config.RECAPTCHA_SITE_KEY}
              onChange={onRecaptcha}
            />
          </Box>
        ) : null}

        <Checkbox
          colorScheme="secondary"
          my="5"
          size="md"
          textAlign="left"
          isInvalid={!checkedTerms}
          onChange={onCheckTerms}
          spacing="3"
          data-cy={TestIds.TermsCheck}
        >
          <Box as="span">
            <Trans>
              By checking this box, you are confirming that you have read,
              understood and agree with the
            </Trans>{" "}
            <Box color="primary.400" as="span">
              <a target="_blank" href="/service-terms">
                <Trans>Service Terms</Trans>
              </a>
            </Box>
          </Box>
        </Checkbox>
        <Button
          isLoading={isCreatingUser}
          isDisabled={isCreatingUser || !isFormValid || !checkedTerms}
          onClick={handleSignUp}
          colorScheme="secondary"
          data-cy={TestIds.SignUpButton}
          variant="secondary"
        >
          <Trans>Sign Up</Trans>
        </Button>
        <Button
          colorScheme="primary"
          variant="primary"
          onClick={onSignInClick}
          mt="4"
        >
          <Trans>Sign In</Trans>
        </Button>
        {errorMessage && <FormError>{errorMessage}</FormError>}
      </Grid>
    </>
  );

  return hasContainer ? (
    <FlexCard
      rounded="md"
      shadow="lg"
      h="100%"
      mx="auto"
      p="10"
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      {content}
    </FlexCard>
  ) : (
    content
  );
};
