import { useUser } from "@/providers/useUser";
import { useEffect } from "react";
import { config } from "@/config";
import * as Sentry from "@sentry/react";

const useSentry = () => {
  const { user } = useUser();

  useEffect(() => {
    if (Sentry.getCurrentHub().getClient()) return;
    Sentry.init({
      dsn: config.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }, []);

  useEffect(() => {
    if (!user) {
      Sentry.setUser(null);
      return;
    }
    Sentry.setUser({ email: user.email, id: user.id, username: user.name });
  }, [user]);
};

export default useSentry;
