import { BootcampBannerEntry, EntityName } from "@/schemaTypes";
import { Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Logobeemo from "/img/sb-bootcamp-Beemo-clr2.svg";

export interface DetailBannerProps {
  bootcamp: BootcampBannerEntry;
  startDateFormatted: string;
  isRegistered?: boolean;
  onClickEnroll?: () => void;
}

export const DetailBanner = ({
  bootcamp,
  startDateFormatted,
  isRegistered,
  onClickEnroll,
}: DetailBannerProps) => {
  const { t } = useTranslation();
  const logoMastermind = "https://media.smartbeemo.com/mastermind.svg";

  return (
    <Fragment>
      <Flex flexDir={"column"} maxW={{ base: "full", lg: "65%" }} gap={4}>
        <Flex flexDir={"column"} gap={2}>
          <Image
            src={
              bootcamp.parent?.bootcampType !== EntityName.MastermindBootcamp
                ? Logobeemo
                : logoMastermind
            }
            w={"104px"}
          />
          <Heading as={"h5"}>{bootcamp?.release?.title}</Heading>
          <Text fontSize={"14px"}>
            {bootcamp?.release?.description}
            <Text as={"span"} fontSize={"14px"} variant={"bold"}>
              {t(" Iniciamos el")} {startDateFormatted}.
            </Text>
          </Text>
        </Flex>
        {!isRegistered && (
          <Button
            onClick={onClickEnroll}
            variant={"primary"}
            fontSize={"14px"}
            w={"fit-content"}
            px={4}
            width={{ base: "full", md: "153px" }}
            h={"36px"}
          >
            {t("¡Regístrate ahora!")}
          </Button>
        )}
      </Flex>
    </Fragment>
  );
};
