function validateComponentVisibility(createdAt: string, isBanner: boolean) {
  const creationDate = new Date(createdAt);
  const startDate = new Date(creationDate);
  const endDate = new Date(creationDate);
  const currentDate = new Date();

  if (isBanner) {
    {
      startDate.setDate(startDate.getDate() + 3);
    }
  }

  endDate.setDate(endDate.getDate() + 8);

  return currentDate >= startDate && currentDate <= endDate;
}
export default validateComponentVisibility;
