import { BootcampBannerEntry, BootcampClass } from "@/schemaTypes";
export interface BootcampTabsProps {
  bootcamp: BootcampBannerEntry;
  isRegistered?: boolean;
  onClickEnroll?: () => void;
}

export interface ModulesAndSessions {
  moduleName: string;
  classes?: BootcampClass[];
}

export const createModulesFromClasses = (
  classes: BootcampClass[] = []
): { [key: string]: BootcampClass[] } => {
  const modulesMap: { [key: string]: BootcampClass[] } = {};

  classes.forEach((bootcamp) => {
    const [moduleName] = bootcamp?.title
      ?.split("-")
      .map((item) => item.trim()) ?? ["", ""];

    if (moduleName) {
      if (!modulesMap[moduleName]) {
        modulesMap[moduleName] = [];
      }
      modulesMap[moduleName].push(bootcamp);
    }
  });

  return modulesMap;
};
