import { Flex } from "@chakra-ui/react";
import { PageContainer } from "@/components/PageContainer";
import { AppRoute } from "@/AppRoute";
import { ContactMeWidget } from "./ContactMeWidget";
import { ServiceDetailBody } from "./ServiceDetailBody";
import { useGateway } from "../gatewayLayer/useGateway";
import { BigSpinner } from "@/components/BigSpinner";

export const ServicesMarketplaceDetailComponent: React.FC = () => {
  const { loading } = useGateway();
  return (
    <PageContainer
      goBackTo={AppRoute.ServicesMarketplace}
      textBackTo={"Regresar"}
    >
      {loading ? (
        <BigSpinner></BigSpinner>
      ) : (
        <>
          <Flex direction={"row"} gap={"48px"}>
            <ServiceDetailBody></ServiceDetailBody>
            <ContactMeWidget></ContactMeWidget>
          </Flex>
        </>
      )}
    </PageContainer>
  );
};
