import { Ico } from "@/assets/icons";
import { BigSpinner } from "@/components/BigSpinner";
import { colors } from "@/components/Theme/colors";
import { config } from "@/config";
import { PlaylistObject } from "@/schemaTypes";
import {
  Button,
  Divider,
  Flex,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import RecordedSessionModal from "./RecordedSessionModal";
import { SessionCard } from "./SessionCard/sessionCard";
import {
  extractPlaylistId,
  useYoutubePlaylist,
  VideoItem,
} from "./useYoutubePlaylist";

interface PreviousSessionsProps {
  playlists: PlaylistObject;
  linkForResources: string;
}

export const PreviousSessions = ({
  playlists,
  linkForResources,
}: PreviousSessionsProps) => {
  const { t } = useTranslation();
  const [video, setVideo] = useState<VideoItem | null>(null);
  const { isOpen: openModal, onOpen, onClose: handleReset } = useDisclosure();
  const prevSessionsPlaylist = playlists.prevSessionsPlaylist as string[];
  const playlistId = extractPlaylistId(prevSessionsPlaylist[0]);
  const apiKey = config.YOUTUBE_API_KEY || "";

  const { videos, loading } = useYoutubePlaylist(playlistId, apiKey);

  if (loading) {
    return <BigSpinner />;
  }
  const onClickSession = (sessionVideo: VideoItem) => {
    setVideo(sessionVideo);
    onOpen();
  };

  return (
    <Flex flexDir={"column"} gap={4}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading>Sesiones</Heading>
        <Button
          gap={2}
          w="fit-content"
          rightIcon={<Ico.FileDown fontSize="20px" />}
          onClick={() => {
            if (linkForResources) {
              window.open(linkForResources, "_blank");
            }
          }}
          variant="link"
          fontSize="14px"
          px={0}
          py={2}
          display={"flex"}
        >
          {t("Descargar material de apoyo")}
        </Button>
      </Flex>
      {videos.length > 0 && (
        <Flex
          gap={6}
          flexDirection="column"
          border={`1px solid ${colors.neutral[100]}`}
          borderRadius="6px"
          p={4}
        >
          <Flex gap={4} flexDirection="column">
            <Flex gap={4} flexDirection="column">
              {videos.map((session, index) => (
                <Fragment key={session.id}>
                  <SessionCard
                    video={session}
                    onClickSession={onClickSession}
                  />
                  {index + 1 < videos.length && (
                    <Divider
                      h={"auto"}
                      borderColor={colors.neutral[100]}
                      color={colors.neutral[100]}
                      borderBottom={"1px"}
                      w={"full"}
                    />
                  )}
                </Fragment>
              ))}
            </Flex>
          </Flex>
          {video && (
            <RecordedSessionModal
              isOpen={openModal}
              onClose={handleReset}
              video={video}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};
