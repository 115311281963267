import { Moment } from "moment-timezone";
import moment from "moment-timezone";
import Ipbase from "@everapi/ipbase-js";
import { config } from "@/config";

import DateTimeInterface from "./dateTimeInteface";
import { SBErrorPubSub } from "../errors/SBError";

interface GeoLocation {
  country: string;
  nameTranslated: string;
  location: string;
}

class DateTimeMoment implements DateTimeInterface {
  static ipBase = new Ipbase(config.IPBASE_KEY);

  getCurrentTime(): Moment {
    return moment.utc();
  }

  convertLocalTime(time: Moment, timeZone: string): Moment {
    return time.clone().tz(timeZone);
  }

  static async getGeoLocation(): Promise<GeoLocation> {
    const locationData = {
      country: "US",
      nameTranslated: "United States",
      location: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    try {
      const { data } = await DateTimeMoment.ipBase.info();
      locationData.country = data.location.country.alpha2;
      locationData.nameTranslated = data.location.country.name_translated;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error getting location", e);

      SBErrorPubSub.publish({
        component: "dateTimeMoment.tsx line 41",
        message: (e as Error)?.message || "Error getting location",
        showInProd: true,
      });

      return locationData;
    }

    return locationData;
  }

  async getGeoLocation(): Promise<GeoLocation> {
    return DateTimeMoment.getGeoLocation();
  }

  fortmatDate(
    date: Moment,
    dateProps: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
    locale = "es"
  ): string {
    const dateJS = date.toDate();
    const dateFormatted = dateJS.toLocaleDateString(locale, dateProps);
    return dateFormatted;
  }

  fortmatOnlyDate(
    date: Moment,
    timeZone: string,
    dateProps: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour12: true,
      timeZone: timeZone,
    },
    locale = "es"
  ): string {
    const dateJS = date.toDate();
    const dateFormatted = dateJS.toLocaleDateString(locale, dateProps);
    return dateFormatted;
  }

  fortmatOnlyTime(
    date: Moment,
    timeZone: string,
    dateProps: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: timeZone,
    }
  ): string {
    const dateJS = date.toDate();
    const dateFormatted = dateJS.toLocaleTimeString("en", dateProps);
    return dateFormatted;
  }

  fortmatOnlyDateAndDay(
    date: Moment,
    timeZone: string,
    dateProps: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour12: true,
      timeZone: timeZone,
    }
  ): string {
    const dateJS = date.toDate();
    const dateFormatted = dateJS.toLocaleDateString("es-ES", dateProps);
    return dateFormatted;
  }

  formatOnlyTimeAmPm(date: Moment, timeZone: string): string {
    const newFormatDate = date.toDate();
    const localTime = moment(newFormatDate).tz(timeZone);
    const hours = localTime.hours();
    const minutes = localTime.minutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedTime;
  }
}

export default DateTimeMoment;
