import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Box, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { Button } from "./Button";

const CookieBanner = (): JSX.Element | null => {
  const [cookies, setCookie] = useCookies(["cookieAccepted"]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(!cookies.cookieAccepted);
  }, [cookies.cookieAccepted]);

  const acceptCookies = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    setCookie("cookieAccepted", true, { path: "/", expires: expirationDate });
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Box
      position="fixed"
      bottom="20px"
      left="20px"
      width="calc(100% - 40px)"
      backgroundColor="white"
      p="4"
      textAlign="center"
      zIndex="999"
      border="1px solid #1E1E23"
      borderRadius="8px"
      display={"none"}
      flexDirection={{ base: "column", md: "row" }}
      justifyContent="space-between"
      alignItems={{ base: "center", md: "flex-start", lg: "center" }}
    >
      <Box
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "flex-start" }}
        textAlign={{ base: "left", md: "left" }}
        mb={{ base: "4", md: "0" }}
        bg={""}
        width={{ base: "100%", md: "78%" }}
        fontSize={{ base: "24px", md: "24px" }}
        mt={{ base: "2", md: "0" }}
      >
        🍪
        <Box ml={{ base: "0", md: "2" }} mt={{ base: "2", md: "0" }} bg={""}>
          <Text
            fontSize="16px"
            fontWeight="bold"
            mb="2"
            textAlign={{ base: "left", md: "left" }}
          >
            <Trans>cookie title banner</Trans>
          </Text>
          <Text
            fontSize="15px"
            width="100%"
            textAlign={{ base: "left", md: "inherit" }}
          >
            <Trans>cookie text</Trans>
          </Text>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        mt={{ base: "4", md: "0" }}
        width={{ base: "100%", md: "22%" }}
        margin={{ base: "auto", md: "unset" }}
      >
        <Button colorScheme="teal" w="200px" onClick={acceptCookies}>
          <Trans>AcceptButtonCoockies</Trans>
        </Button>
      </Box>
    </Box>
  );
};

export default CookieBanner;
