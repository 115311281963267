import { useEffect, useState } from "react";
import { PresenterContext, PresenterValue } from "./context";
import { Category, MarketplaceService } from "@/schemaTypes";
import { useDataLayer } from "../dataLayer/useData";
import { useBreakpointValue } from "@chakra-ui/react";
import { handleAllSubcategoriesSelected } from "../utils/filter";

interface PresenterProps {
  children: React.ReactNode;
}

const Presenter = ({ children }: PresenterProps): JSX.Element => {
  const [selectedMainCategory, setSelectedMainCategory] = useState<string>("");
  const [selectedSubCategories, setSelectedSubCategories] = useState<
    Array<string>
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [servicesForView, setServicesForView] = useState<
    Array<MarketplaceService | null> | null | undefined
  >();
  const [filteredServicesForView, setFilteredServicesForView] = useState<
    Array<MarketplaceService | null> | null | undefined
  >();
  const [categoriesForView, setCategoriesForView] = useState<Array<Category>>(
    []
  );
  const [subCategoriesForView, setSubCategoriesForView] = useState<
    Array<Category>
  >([]);
  const [currentServiceForView, setCurrentServiceForView] =
    useState<MarketplaceService | null>(null);

  const {
    servicesForPresenter,
    searchServicesForPresenter,
    currentServiceForPresenter,
    filteredServicesForPresenter,
    subCategoriesForPresenter,
    categoriesForPresenter,
    searchServicesCallback,
    filterData,
  } = useDataLayer();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const selectMainCategory = (categoryId: string, index: number) => {
    setSelectedMainCategory(categoryId);
    setSelectedIndex(index);
  };
  const selectSubCategory = (categoryIds: Array<string>) => {
    if (categoryIds.indexOf("ALL") > 0) {
      categoryIds = ["ALL"];
    } else {
      if (subCategoriesForPresenter && categoryIds)
        categoryIds = handleAllSubcategoriesSelected(
          subCategoriesForPresenter,
          categoryIds
        );
    }
    setSelectedSubCategories(categoryIds);
  };
  const resetCategoriesAndSubSelections = () => {
    setSelectedMainCategory("ALL");
    setSelectedSubCategories(["ALL"]);
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const handleSearchClick = () => {
    if (searchTerm && searchTerm !== "") {
      searchServicesCallback(searchTerm);
      resetCategoriesAndSubSelections();
    }
  };
  const handleCleanSearch = () => {
    searchServicesCallback(null);
    resetCategoriesAndSubSelections();
    setSearchTerm("");
  };

  useEffect(() => {
    if (
      (selectedMainCategory && selectedMainCategory !== "") ||
      (searchServicesForPresenter && searchServicesForPresenter.length > 0)
    ) {
      filterData(selectedMainCategory, selectedSubCategories);
    }
  }, [selectedMainCategory, selectedSubCategories, searchServicesForPresenter]);
  useEffect(() => {
    setServicesForView(servicesForPresenter);
    resetCategoriesAndSubSelections();
  }, [servicesForPresenter]);
  useEffect(() => {
    setFilteredServicesForView(filteredServicesForPresenter);
  }, [filteredServicesForPresenter]);
  useEffect(() => {
    if (categoriesForPresenter) setCategoriesForView(categoriesForPresenter);
  }, [categoriesForPresenter]);
  useEffect(() => {
    if (subCategoriesForPresenter)
      setSubCategoriesForView(subCategoriesForPresenter);
  }, [subCategoriesForPresenter]);
  useEffect(() => {
    if (currentServiceForPresenter)
      setCurrentServiceForView(currentServiceForPresenter);
  });

  const value: PresenterValue = {
    selectMainCategory,
    selectSubCategory,
    handleSearchChange,
    handleSearchClick,
    handleCleanSearch,
    servicesForView,
    filteredServicesForView,
    subCategoriesForView,
    categoriesForView,
    selectedSubCategories,
    selectedMainCategory,
    searchTerm,
    selectedIndex,
    isMobile: isMobile ?? false,
    currentServiceForView,
  };

  return (
    <PresenterContext.Provider value={value}>
      {children}
    </PresenterContext.Provider>
  );
};

export { Presenter };
