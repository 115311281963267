import * as Types from '../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ValidateUserForEnrollmentQueryVariables = Types.Exact<{
  validateUserEnrollmentInput: Types.ValidateUserEnrollmentInput;
}>;


export type ValidateUserForEnrollmentQuery = { __typename?: 'Query', validateUserForEnrollment?: { __typename?: 'ValidateUserEnrollmentOutput', validate?: boolean | null, type?: Types.ValidateBootcampEnrrollments | null } | null };


export const ValidateUserForEnrollmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ValidateUserForEnrollment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"validateUserEnrollmentInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ValidateUserEnrollmentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"validateUserForEnrollment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"validateUserEnrollmentInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"validateUserEnrollmentInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"validate"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode<ValidateUserForEnrollmentQuery, ValidateUserForEnrollmentQueryVariables>;