import * as Types from '../../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RegisterAttendanceMutationVariables = Types.Exact<{
  liveSessionsId: Types.Scalars['String'];
  isPortal1: Types.Scalars['Boolean'];
  dataPortal1Input: Types.DataPortal1Input;
}>;


export type RegisterAttendanceMutation = { __typename?: 'Mutation', registerAttendance: string };


export const RegisterAttendanceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RegisterAttendance"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"liveSessionsId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"isPortal1"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dataPortal1Input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DataPortal1Input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"registerAttendance"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"liveSessionsId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"liveSessionsId"}}},{"kind":"Argument","name":{"kind":"Name","value":"isPortal1"},"value":{"kind":"Variable","name":{"kind":"Name","value":"isPortal1"}}},{"kind":"Argument","name":{"kind":"Name","value":"dataPortal1Input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dataPortal1Input"}}}]}]}}]} as unknown as DocumentNode<RegisterAttendanceMutation, RegisterAttendanceMutationVariables>;