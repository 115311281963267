import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useContext } from "react";
import { IconControlsEnd, TypeIcon } from "../IconsControls";
import ModalEndLive from "@/pages/academy/LiveClasses/Streaming/Video/ModalEndLive";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { StopLiveSessionDocument } from "@/components/LiveSessionsZoom/graphql/stopLiveSession.generated";
import ZoomClientContext from "@/components/LiveSessionsZoom/context/zoom-context";
import { Trans } from "react-i18next";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { t } from "i18next";

export const LeaveSession = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { zmClient, clienteRecording } = useContext(ZoomClientContext);
  const { liveSessionId } = useParams<{ liveSessionId: string }>();
  const [stopLiveSession, { loading: loadingStopLiveSession }] = useMutation(
    StopLiveSessionDocument
  );

  const stopStreaming = async () => {
    try {
      await stopLiveSession({
        variables: {
          liveSessionsId: liveSessionId || "",
        },
      });

      if (clienteRecording) {
        await clienteRecording.stopCloudRecording();
      }

      if (zmClient) {
        zmClient.leave(true);
      }
    } catch (error) {
      SBErrorPubSub.publish({
        component: "Leave.tsx line 39",
        message: t("Error ending session or pausing recording"),
        showInProd: false,
      });
    }
  };

  const handleOpenModal = () => {
    onOpen();
  };

  return (
    <Box>
      <Flex
        onClick={handleOpenModal}
        cursor={"pointer"}
        w={"115px"}
        maxW={"115px"}
        justify={"center"}
        align={"center"}
        userSelect={"none"}
        flexDir={"column"}
      >
        <IconControlsEnd type={TypeIcon.EndLive_ON} />
        <Text color={"shades.white"} fontSize={"10px"} lineHeight={"19.2px"}>
          <Trans>Finish</Trans>
        </Text>
      </Flex>
      <ModalEndLive
        isOpen={isOpen}
        onClose={onClose}
        stopStreaming={stopStreaming}
        loadingStopLiveSession={loadingStopLiveSession}
        loadingStopRecording={false}
        sessionId={liveSessionId}
      />
    </Box>
  );
};
