/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { Trans } from "react-i18next";
import { TestIds } from "../utils/testIds";
import { Button } from "./Button";

interface ConfirmDialogProps {
  title: string;
  message?: string;
  confirmText?: string;
  onConfirm: (e?: React.MouseEvent) => void;
  onCancel: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  showCancel?: boolean;
  children?: React.ReactNode;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  message,
  confirmText = "OK",
  onConfirm,
  onCancel,
  children,
  isLoading = false,
  isDisabled = false,
  showCancel = true,
}) => {
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog isOpen leastDestructiveRef={cancelRef} onClose={onCancel}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {message && <Box mb="4">{message}</Box>}
            {children}
          </AlertDialogBody>

          <AlertDialogFooter>
            {showCancel && (
              <Button ref={cancelRef} onClick={onCancel}>
                <Trans>Cancel</Trans>
              </Button>
            )}
            <Button
              isLoading={isLoading}
              isDisabled={isLoading || isDisabled}
              colorScheme="primary"
              onClick={onConfirm}
              ml={3}
              data-cy={TestIds.ConfirmDialogOk}
            >
              {confirmText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
