import * as Types from '../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetUserPersonalInfoQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type GetUserPersonalInfoQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, birthDate?: string | null, lastName?: string | null, name: string, phone?: string | null, profile?: { __typename?: 'UserProfile', id: string, countryOfResidence?: string | null, regionOfResidence?: string | null, cityOfResidence?: string | null, gender?: string | null, mainAddress?: string | null, zipCode?: string | null, occupation?: string | null } | null } | null };


export const GetUserPersonalInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getUserPersonalInfo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"birthDate"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"countryOfResidence"}},{"kind":"Field","name":{"kind":"Name","value":"regionOfResidence"}},{"kind":"Field","name":{"kind":"Name","value":"cityOfResidence"}},{"kind":"Field","name":{"kind":"Name","value":"gender"}},{"kind":"Field","name":{"kind":"Name","value":"mainAddress"}},{"kind":"Field","name":{"kind":"Name","value":"zipCode"}},{"kind":"Field","name":{"kind":"Name","value":"occupation"}}]}}]}}]}}]} as unknown as DocumentNode<GetUserPersonalInfoQuery, GetUserPersonalInfoQueryVariables>;