import { differenceInDays, addDays, format } from "date-fns";
import moment from "moment";
import DateTimeInterface from "@/utils/dateTimeFormat/dateTimeInteface";
import DateTimeMoment from "@/utils/dateTimeFormat/dateTimeMoment";

const dateTime: DateTimeInterface = new DateTimeMoment();
import { es } from "date-fns/locale";

export const formatDate = (dateString: string | undefined): string => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const formattedDate = format(date, "EEEE, d 'de' MMMM", { locale: es });
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};
export const formatTime12h = (dateString: string | undefined): string => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return format(date, "hh:mm a");
};

export const resultingDate = (dateString: string | undefined): string => {
  if (!dateString) return "Fecha inválida";

  const startDate = new Date(dateString);
  const currentDate = new Date();

  const days = Number(differenceInDays(startDate, currentDate)) - 21;
  const resultDate = addDays(currentDate, days);
  const formattedDate = format(resultDate, "d 'de' MMMM", { locale: es });

  return formattedDate;
};

export const getFormattedDate = (date: Date, location: string) => {
  const dateMoment = moment.utc(date);
  const convertLocalTime = dateTime.convertLocalTime(
    dateMoment.clone(),
    location
  );

  const formattedDate = dateTime.fortmatDate(convertLocalTime, {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

export const getFormattedTime = (date: Date, location: string) => {
  const dateMoment = moment(date);
  return dateTime.fortmatOnlyTime(dateMoment, location);
};
