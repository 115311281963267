/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, createContext } from "react";
import { InitZoomClient } from "../types/zoom";
import { RecordingClient, VideoClient } from "@zoom/videosdk";
import { LiveSessionAuthJwtZoom } from "@/schemaTypes";

export interface ClientStoreInterface {
  topic: string;
  token: string;
  userName: string;
  password: string;
  isAuth: boolean;
  zmClient: typeof VideoClient | undefined;
  dataSessionAuth: LiveSessionAuthJwtZoom | undefined;
}

export interface RefVideosElements {
  screenSharedElementAudience: HTMLCanvasElement | null;
  screenSharedElementVideo: HTMLCanvasElement | null;
  videoCamerasElement: HTMLVideoElement | null;
}

interface ZoomContextValue {
  clientState: ClientStoreInterface | undefined;
  initZoomClient: InitZoomClient | undefined;
  dispatch: Dispatch<any>;
  zmClient: typeof VideoClient | undefined;
  refVideosElements: RefVideosElements;
  videoCamerasElement: string;
  dataSessionAuth: LiveSessionAuthJwtZoom | undefined;
  clienteRecording: typeof RecordingClient | undefined;
}

const ZoomClientContext = createContext<ZoomContextValue>({
  clientState: undefined,
  dispatch: () => {},
  initZoomClient: undefined,
  zmClient: undefined,
  refVideosElements: {
    screenSharedElementAudience: null,
    screenSharedElementVideo: null,
    videoCamerasElement: null,
  },
  videoCamerasElement: "video-player-container",
  dataSessionAuth: undefined,
  clienteRecording: undefined,
});
export default ZoomClientContext;
