import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const primary = defineStyle({
  bgColor: "primary.500",
  borderColor: "transparent",
  color: "white",
  rounded: "6px",
  _active: {
    bgColor: "primary.400",
  },
  _disabled: {
    bgColor: "neutral.100 !important",
    color: "neutral.400 !important",
  },
  _hover: {
    bgColor: "primary.200",
  },
  _loading: {
    bgColor: "primary.50",
  },
  _pressed: {
    bgColor: "primary.400",
  },
  _focus: {
    outline: "none",
    boxShadow: "none",
  },
});

const secondary = defineStyle({
  bgColor: "transparent",
  borderColor: "primary.300",
  color: "primary.300",
  rounded: "6px",
  _active: {
    borderColor: "primary.400",
    color: "primary.400",
  },
  _disabled: {
    borderColor: "neutral.200 !important",
    color: "neutral.400 !important",
  },
  _hover: {
    borderColor: "primary.100",
    color: "primary.100",
  },
  _loading: {
    borderColor: "primary.50",
    color: "primary.300",
  },
  _focus: {
    outline: "none",
    boxShadow: "none",
  },
});

const ghost = defineStyle({
  bgColor: "transparent",
  borderColor: "transparent",
  color: "primary.300",
});

const link = defineStyle({
  bgColor: "transparent",
  borderColor: "transparent",
  color: "primary.300",
  "& span": {
    margin: 0,
  },
  _active: {
    bgColor: "transparent",
    borderColor: "transparent",
    color: "primary.400",
  },
  _disabled: {
    bgColor: "transparent !important",
    borderColor: "transparent !important",
    color: "neutral.400 !important",
  },
  _hover: {
    bgColor: "transparent",
    borderColor: "transparent",
    color: "primary.100",
    textDecoration: "none",
  },
  _loading: {
    bgColor: "transparent",
    borderColor: "transparent",
    color: "primary.300",
  },
  _focus: {
    outline: "none",
    boxShadow: "none",
  },
});

const buttonTheme = defineStyleConfig({
  baseStyle: {
    border: "2px",
    borderColor: "transparent",
    borderRadius: "50px",
    fontFamily: "Inter",
    fontWeight: "600",
    justifyContent: "center",
    lineHeight: 1.6,
  },
  sizes: {
    lg: {
      fontSize: "18px",
      h: "auto",
      minW: 0,
      p: "12px 32px",
    },
    md: {
      fontSize: "16px",
      h: "auto",
      minW: 0,
      p: "8px 24px",
    },
    sm: {
      fontSize: "14px",
      h: "auto",
      minW: 0,
      p: "6px 16px",
    },
  },
  variants: {
    ghost,
    link,
    primary,
    secondary,
  },
});

export { buttonTheme };
