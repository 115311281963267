import {
  Box,
  Heading,
  Image,
  Text,
  FormLabel,
  useDisclosure,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Trans, useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";

import { PageContainer } from "@/components/PageContainer";
import { BackButton } from "@/components/PageContainer/BackButton";
import { InputStyled } from "../components/Forms/InputStyled";
import { forgotPassword } from "../services/authService";
import { ForgotPassswordModal } from "../components/ForgotPasswordModal";
import { ReviewEmailForgotPassword } from "../components/ReviewEmailForgotPassword";
import { config } from "../config";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { Button } from "@/components/Button";

const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [isResettingPassword, setIsRessetingPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSendEmail, setIsSendEmail] = useState(false);

  const handleFieldChange =
    (setFn: Dispatch<SetStateAction<string>>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setFn(e.target.value);
    };

  const handleResetPassword = async () => {
    if (recaptchaToken === null) {
      setErrorMessage(t("Invalid recaptcha check"));
      onOpen();
      return;
    }

    setIsRessetingPassword(true);

    const url = new URL(window.location.href);

    try {
      setErrorMessage("");
      await forgotPassword(email, recaptchaToken, url.origin);

      setIsSendEmail(true);
    } catch (signUpError: unknown) {
      setIsSendEmail(false);
      onOpen();

      const message =
        signUpError && (signUpError as Error).message
          ? (signUpError as Error).message
          : t("Error resetting password, please try again");

      setErrorMessage(message);

      SBErrorPubSub.publish({
        component: "ForgotPasswordPage.tsx line 68",
        message: message,
        showInProd: true,
      });
    } finally {
      setIsRessetingPassword(false);
    }
  };

  const validationErrors: Record<string, Array<string>> = {
    email: email.length > 3 && isEmail(email) ? [] : [t("Invalid Email")],
    recaptchaToken:
      recaptchaToken && recaptchaToken.length > 0
        ? []
        : [t("Invalid recaptcha check")],
  };

  const isFormValid = Object.keys(validationErrors).every(
    (key) => validationErrors[key].length === 0
  );

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Flex flexDir={"column"} minH={"100vh"}>
      <ForgotPassswordModal isOpen={isOpen} onClose={onClose} />
      <Flex bgColor={"white"} h={"70px"} p={"16px 24px"}>
        <Image
          alt={`Smartbeemo Logo`}
          h={"100%"}
          objectFit={"contain"}
          src={useBreakpointValue({
            base: "/img/beemo.svg",
            lg: "/img/logo_smartbeemo.svg",
          })}
          w={"auto"}
        />
      </Flex>
      <Box m={{ base: "16px 0 0 16px", lg: "24px 0 0 24px" }}>
        <BackButton to={-1} />
      </Box>
      <PageContainer
        display={"flex"}
        flexGrow={isMobile ? 1 : undefined}
        maxW={"440px"}
      >
        {!isSendEmail ? (
          <Box mb={20}>
            <Heading as={isMobile ? "h5" : "h4"}>
              <Trans>Update your password</Trans>
            </Heading>
            <Text color={"secondary.200"} mb={"32px"} mt={"16px"}>
              <Trans>
                Enter the email associated with your account and you will
                receive instructions to update it.
              </Trans>
            </Text>
            <FormLabel
              color={"secondary.50"}
              fontWeight={"semibold"}
              fontSize={"14px"}
            >
              <Trans>Email</Trans>
            </FormLabel>
            <InputStyled
              _focus={{ border: "2px solid", borderColor: "primary.300" }}
              _hover={{ border: "1px solid", borderColor: "primary.200" }}
              onChange={handleFieldChange(setEmail)}
              type="email"
              name="email"
              borderColor={errorMessage ? "error.200" : "neutral.300"}
              placeholder={t("Email Placeholder")}
              _placeholder={{ fontSize: "14px" }}
              fontSize={12}
            />
            <Flex justifyContent={"center"} my={"50px"}>
              <ReCAPTCHA
                sitekey={config.RECAPTCHA_SITE_KEY}
                onChange={setRecaptchaToken}
              />
            </Flex>
            <Button
              isLoading={isResettingPassword}
              isDisabled={isResettingPassword || !isFormValid}
              onClick={handleResetPassword}
              variant={"primary"}
            >
              <Text variant={"bold"}>
                <Trans>Update password</Trans>
              </Text>
            </Button>
          </Box>
        ) : (
          <Box px={5} pb={5}>
            <ReviewEmailForgotPassword email={email} isSuccess={false} />
          </Box>
        )}
      </PageContainer>
    </Flex>
  );
};

export default ForgotPasswordPage;
