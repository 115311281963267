import { Box, Flex } from "@chakra-ui/react";
import React, { useContext } from "react";
import ZoomClientContext from "../../context/zoom-context";
import { useUser } from "@/providers/useUser";
import { useMutation } from "@apollo/client";
import { StartLiveSessionDocument } from "../../graphql/startLiveSession.generated";
import { Button } from "@/components/Button";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { DISPATCH_TYPE } from "../../types/LiveBar.type";

export const Start = () => {
  const { user } = useUser();

  const { clientState, initZoomClient, zmClient, dispatch, dataSessionAuth } =
    useContext(ZoomClientContext);
  const { liveSessionId, name: userName } = useParams<{
    liveSessionId: string;
    name: string;
  }>();
  const [startLiveSession, { loading: loadingStartLiveSession }] = useMutation(
    StartLiveSessionDocument
  );

  const startSession = async () => {
    if (!initZoomClient || !dataSessionAuth || !liveSessionId) return;

    await initZoomClient({
      topic: dataSessionAuth?.session.channelName,
      token: dataSessionAuth?.jwt,
      username:
        userName ??
        (user?.name && `${user?.name} ${user?.lastName}`) ??
        dataSessionAuth.session.userId,
    });

    await startLiveSession({
      variables: {
        liveSessionsId: liveSessionId,
      },
    });

    dispatch({
      type: DISPATCH_TYPE.CONNECT_CLIENT,
      payload: {
        ...clientState,
        isAuth: true,
        token: dataSessionAuth?.jwt,
        topic: dataSessionAuth?.session.channelName,
        userName: "",
        zmClient: zmClient,
        error: undefined,
        dataSessionAuth: dataSessionAuth,
      },
    });
  };

  return (
    <Box>
      <Flex>
        <Button
          isLoading={loadingStartLiveSession}
          variant="primary"
          onClick={startSession}
          title={t("Start")}
        />
      </Flex>
    </Box>
  );
};
