import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { VideoItem } from "./useYoutubePlaylist";
import { EmbedVideo } from "@/components/InteractiveContent/EmbedVideo";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  video: VideoItem;
}

export default function RecordedSessionModal({
  onClose,
  isOpen,
  video,
}: Props) {
  const embedUrl = `https://www.youtube.com/embed/${video.id}`;
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <EmbedVideo autoPlay videoURL={embedUrl} />
      </ModalContent>
    </Modal>
  );
}
