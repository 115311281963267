import { FlexProps } from "@chakra-ui/layout";
import React from "react";
import { ServiceMarketplaceCardFragment } from "../gatewayLayer/graphql/serviceMarketplaceFragment.generated";
import {
  Badge,
  Button,
  Flex,
  Heading,
  Text,
  Box,
  Image,
} from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { Card } from "@/components/Card";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/AppRoute";

interface ServiceMarketplaceCardProps {
  service: ServiceMarketplaceCardFragment;
  onClick?: (
    program: ServiceMarketplaceCardFragment,
    e: React.MouseEvent
  ) => void;
  onEditClick?: (
    program: ServiceMarketplaceCardFragment,
    e: React.MouseEvent
  ) => void;
}

export const ServiceCard: React.FC<
  ServiceMarketplaceCardProps & Omit<FlexProps, "onClick">
> = ({ service }) => {
  const navigate = useNavigate();
  const handleClick = (_e: React.MouseEvent) => {
    navigate(`${AppRoute.ServicesMarketplace}/${service.id}`);
  };
  return (
    <Card
      key={service.id}
      userSelect={"none"}
      onClick={handleClick}
      showDivider={false}
      isNewUi={true}
      w={"100%"}
      maxW={"unset"}
      actionButtons={
        <Button
          display={"block"}
          onClick={handleClick}
          variant={"link"}
          fontSize={"14px"}
        >
          <Trans>Ver más</Trans>
        </Button>
      }
    >
      <Box
        border={"1px #E2E4E7 solid"}
        w={"100%"}
        textAlign={"center"}
        borderRadius={"5px"}
      >
        <Image
          w={"100%"}
          maxW={"88px"}
          h={"80px"}
          my={"40px"}
          mx={"auto"}
          src={
            service.agencyImage && service.agencyImage !== ""
              ? service.agencyImage
              : "/img/logo_smartbeemo.svg"
          }
        ></Image>
      </Box>
      <Flex w={"100%"} flexGrow={1} mt={"4px"} flexDir={"column"}>
        <Flex>
          <Heading as="p" fontSize={"14px"} w={"100%"}>
            {service.agency}
          </Heading>
        </Flex>
        <Flex align={"flex-start"} mt={"8px"}>
          {[service.category, service.subcategory].map((category) => {
            return (
              <Badge
                key={service.id + "-" + category?.id}
                px={"8px"}
                py={"2px"}
                bg={"neutral.50"}
                textTransform={"capitalize"}
                w={"fit-content"}
                color={"neutral.500"}
                borderRadius={"6px"}
                mr={"8px"}
              >
                <Text fontSize={"12px"}>{category?.name}</Text>
              </Badge>
            );
          })}
        </Flex>
      </Flex>
    </Card>
  );
};
