import { useEffect } from "react";
import Hotjar from "@hotjar/browser";
import { useUser } from "@/providers/useUser";

export const useHotjar = () => {
  const { user } = useUser();
  useEffect(() => {
    Hotjar.identify(user?.id ?? "", {
      email: user?.email ?? "",
      name: user?.name ?? "",
    });
  }, [user]);
};
