import { RemimberLiveSession } from "@/components/LiveSessionsZoom/PreSessions/RemimberLiveSession";
import SettingsDevices from "@/components/LiveSessionsZoom/PreSessions/SettingsDevices";
import { PageContainer } from "@/components/PageContainer";
import { Box } from "@chakra-ui/react";
import { useState } from "react";

export const SettingDevicesPage = () => {
  const [viewRemimder, setviewRemimder] = useState(true);

  return (
    <PageContainer goBackTo={-1}>
      <Box>
        {viewRemimder ? (
          <RemimberLiveSession setviewRemimder={setviewRemimder} />
        ) : (
          <SettingsDevices />
        )}
      </Box>
    </PageContainer>
  );
};
