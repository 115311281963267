/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export const noCacheHeaders = {
  "cache-control": "no-cache",
  pragma: "no-cache",
};
