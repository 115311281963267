import { Flex, Text } from "@chakra-ui/react";
import { InfoCardData } from "../utils";

export const InfoCard = ({ icon, text, bgColor }: InfoCardData) => {
  return (
    <Flex flexDir={"column"} alignItems={"center"} gap={3}>
      {icon && (
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          backgroundColor={bgColor}
          w={8}
          h={8}
          borderRadius={"50%"}
        >
          {icon}
        </Flex>
      )}
      <Text fontSize={"12px"} textAlign={"center"} lineHeight={"19.2px"}>
        {text}
      </Text>
    </Flex>
  );
};
