import * as Types from '../../../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateOrUpdateCommentMutationVariables = Types.Exact<{
  commentInput: Types.CommentInput;
}>;


export type CreateOrUpdateCommentMutation = { __typename?: 'Mutation', createOrUpdateComment?: { __typename?: 'Comment', _id?: string | null, postId: string, comment: string, commentId?: string | null, createdAt?: Date | null, author: { __typename?: 'Author', id: string, name: string, role?: string | null } } | null };


export const CreateOrUpdateCommentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateOrUpdateComment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"commentInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CommentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createOrUpdateComment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"commentInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"commentInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"postId"}},{"kind":"Field","name":{"kind":"Name","value":"author"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","name":{"kind":"Name","value":"commentId"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<CreateOrUpdateCommentMutation, CreateOrUpdateCommentMutationVariables>;