import { defineStyleConfig } from "@chakra-ui/react";

const textareaTheme = defineStyleConfig({
  baseStyle: {
    _focus: {
      boxShadow: "none",
    },
    _focusVisible: {
      boxShadow: "none",
    },
    _placeholder: {
      color: "secondary.50",
      fontSize: "14px",
    },
    resize: "none",
    outline: "none",
  },
  variants: {
    outline: {
      _disabled: {
        bgColor: "neutral.50",
        borderColor: "neutral.100",
      },
      _focus: {
        borderColor: "primary.500",
      },
      _focusVisible: {
        borderColor: "primary.500",
        boxShadow: "none",
      },
      _hover: {
        borderColor: "primary.200",
      },
      borderColor: "neutral.300",
      colorScheme: "primary",
    },
  },
});

export { textareaTheme };
