import * as Types from '../../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ValidateUserEnrollmentQueryVariables = Types.Exact<{
  validateUserEnrollmentInput: Types.ValidateUserEnrollmentInput;
}>;


export type ValidateUserEnrollmentQuery = { __typename?: 'Query', validateUserEnrollment?: boolean | null };


export const ValidateUserEnrollmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"validateUserEnrollment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"validateUserEnrollmentInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ValidateUserEnrollmentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"validateUserEnrollment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"validateUserEnrollmentInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"validateUserEnrollmentInput"}}}]}]}}]} as unknown as DocumentNode<ValidateUserEnrollmentQuery, ValidateUserEnrollmentQueryVariables>;