const colors = {
  info: {
    50: "#B8E0FF",
  },
  primary: {
    50: "#9EC0FF",
    100: "#6F9FFF",
    200: "#427AFF",
    300: "#1F58F3",
    400: "#0F43DE",
    500: "#1F58F3",
    600: "#065858",
    700: "#4D4D4D",
    800: "#333333",
    900: "#1A1A1A",
  },
  secondary: {
    50: "#8F8F91",
    100: "#69696C",
    200: "#444448",
    300: "#1E1E23",
    400: "#050606",
    500: "#444448",
    600: "#050606",
    700: "#094590",
    800: "#062E60",
    900: "#031730",
  },
  error: {
    50: "#FFD7D8",
    100: "#F79A9F",
    200: "#E75769",
    300: "#D90545",
    400: "#A10331",
    500: "#109B9D",
    600: "#666666",
    700: "#4D4D4D",
    800: "#333333",
    900: "#1A1A1A",
  },
  shades: {
    white: "#FFFFFF",
    100: "#F9F4F3",
    200: "#FBF8F8",
    300: "#EDF6FF",
    400: "#E1F2E9",
    500: "#BAE9E8",
  },
  neutral: {
    50: "#F7F8F9",
    100: "#E2E4E7",
    200: "#C6C9D0",
    300: "#9EA3AE",
    400: "#777E8D",
    500: "#575C67",
  },
  success: {
    bgToast: "#FAFEF4",
    bcModal: "#12C971",
    50: "#D8EFDC",
    100: "#87CE98",
    200: "#59BD76",
    300: "#00AB53",
    400: "#007E3B",
  },
  warning: {
    50: "#FEEEDB",
    100: "#F9CC91",
    200: "#F6BA68",
    300: "#F2A832",
    400: "#D2922A",
  },
  tertiary: {
    50: "#FFF580",
    100: "#FFED55",
    200: "#FFE32B",
    300: "#FFD700",
    400: "#DEBB00",
  },
  light: {
    50: "#F2F2F2",
    100: "#DBDBDB",
    200: "#C4C4C4",
    300: "#ADADAD",
    400: "#969696",
    500: "#808080",
    600: "#666666",
    700: "#4D4D4D",
    800: "#333333",
    900: "#1A1A1A",
  },
  dark: {
    50: "#F2F2F2",
    100: "#DBDBDB",
    200: "#C4C4C4",
    300: "#ADADAD",
    400: "#969696",
    500: "#444448",
    600: "#666666",
    700: "#4D4D4D",
    800: "#333333",
    900: "#1A1A1A",
  },
};

export { colors };
