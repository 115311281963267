import { useCallback, useContext, useEffect, useState } from "react";
import ZoomClientContext from "../context/zoom-context";
import ZoomMediaContext from "../context/media-context";
import { useVideoState } from "../context/videoState/useVideoState";
import { useToast } from "@chakra-ui/react";

const useCamera = () => {
  const toast = useToast();
  const { zmClient } = useContext(ZoomClientContext);
  const zoomMediaContext = useContext(ZoomMediaContext);
  const { dispatch } = useVideoState();
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [isOtherUserVideoActive, setIsOtherUserVideoActive] = useState(false);
  const [maxCamerasAllowed] = useState(true);

  const toggleVideo = useCallback(async () => {
    try {
      if (zmClient) {
        if (isVideoActive) {
          await zmClient.getMediaStream().stopVideo();
          setIsVideoActive(false);
          dispatch({ type: "SET_IS_VIDEO_ACTIVE", payload: false });
        } else {
          const participants = zmClient.getAllUser();
          const activeCameras = participants.filter(
            (user) => user.bVideoOn
          ).length;

          if (maxCamerasAllowed && activeCameras >= 3) {
            toast({
              title: "Límite de cámaras",
              description: "Máximo de 3 cámaras activas alcanzado.",
              status: "info",
              duration: 9000,
              isClosable: true,
            });
            return;
          }

          setIsVideoActive(true);
          const isSupportHDVideo = zmClient.getMediaStream().isSupportHDVideo();

          // Obtener el ID de la cámara desde el almacenamiento local
          const selectedDevices = JSON.parse(
            localStorage.getItem("selectedDevices") || "{}"
          );
          const cameraId = selectedDevices.camera;

          if (isSupportHDVideo) {
            await zmClient.getMediaStream().startVideo({ hd: true, cameraId });
          } else {
            await zmClient.getMediaStream().startVideo({ cameraId });
          }

          dispatch({ type: "SET_IS_VIDEO_ACTIVE", payload: true });
        }
      }
    } catch (error) {
      setIsVideoActive(false);
      dispatch({ type: "SET_IS_VIDEO_ACTIVE", payload: false });
      throw error;
    }
  }, [
    isVideoActive,
    zmClient,
    zoomMediaContext?.loadingZoom,
    maxCamerasAllowed,
  ]);

  useEffect(() => {
    const checkOtherUserVideoStatus = () => {
      if (zmClient) {
        const participants = zmClient.getAllUser();
        const otherUserVideoActive = participants.some(
          (user) =>
            user.bVideoOn &&
            user.userId !== zmClient.getCurrentUserInfo().userId
        );
        setIsOtherUserVideoActive(otherUserVideoActive);
        dispatch({
          type: "SET_IS_OTHER_USER_VIDEO_ACTIVE",
          payload: otherUserVideoActive,
        });
      }
    };

    zmClient?.on("user-updated", checkOtherUserVideoStatus);
    zmClient?.on("user-added", checkOtherUserVideoStatus);
    zmClient?.on("user-removed", checkOtherUserVideoStatus);

    return () => {
      zmClient?.off("user-updated", checkOtherUserVideoStatus);
      zmClient?.off("user-added", checkOtherUserVideoStatus);
      zmClient?.off("user-removed", checkOtherUserVideoStatus);
    };
  }, [zmClient, zoomMediaContext?.loadingZoom]);

  useEffect(() => {
    const checkMyVideoStatus = async () => {
      if (zmClient && !zoomMediaContext?.loadingZoom) {
        const stream = zoomMediaContext?.mediaContext.mediaStream;
        const videoActive = stream && stream.isCapturingVideo();
        if (videoActive && videoActive !== isVideoActive) {
          setIsVideoActive(videoActive);
          dispatch({
            type: "SET_IS_VIDEO_ACTIVE",
            payload: videoActive,
          });
          dispatch({
            type: "SET_GRID_CAMERA",
            payload: videoActive ? 1 : 2,
          });
        }
      }
    };

    checkMyVideoStatus();
  }, [zoomMediaContext?.loadingZoom, isVideoActive]);

  return {
    isVideoActive,
    isOtherUserVideoActive,
    toggleVideo,
  };
};

export default useCamera;
