import * as Types from '../../../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateLiveSessionsMutationVariables = Types.Exact<{
  createLiveSessionsInput: Types.CreateLiveSessionsInput;
}>;


export type CreateLiveSessionsMutation = { __typename?: 'Mutation', createLiveSessions: string };


export const CreateLiveSessionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateLiveSessions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createLiveSessionsInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateLiveSessionsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createLiveSessions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createLiveSessionsInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createLiveSessionsInput"}}}]}]}}]} as unknown as DocumentNode<CreateLiveSessionsMutation, CreateLiveSessionsMutationVariables>;