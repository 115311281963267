import { Button } from "@chakra-ui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Ico } from "@/assets/icons";

import type { BackButtonProps } from "./types";
import { TestIds } from "@/utils/testIds";

const BackButton = ({
  to = -1,
  dark = false,
  showLabel = true,
  text,
  onClick = () => {},
  onGoBack = () => {},
}: BackButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = useCallback(() => {
    onGoBack();

    if (typeof to === "number") {
      navigate(to);
    } else {
      navigate(to, { replace: true });
    }

    onClick();
  }, [to]);

  return (
    <Button
      alignSelf="start"
      color={dark ? "neutral.50" : "primary.500"}
      leftIcon={<Ico.ArrowLeft style={{ marginRight: "8px" }} />}
      onClick={handleGoBack}
      pos="relative"
      variant="link"
      zIndex={10}
      data-cy={TestIds.goBack}
      sx={{
        "& .chakra-button__icon": {
          marginRight: "10px",
        },
      }}
    >
      {t(text ?? "") || (showLabel && t("Exit"))}
    </Button>
  );
};

export { BackButton };
