import { Flex } from "@chakra-ui/react";
import PasswordAlreadyCreatedModal from "./PasswordAlreadyCreatedModal";
import { useEffect, useState } from "react";
import CreatePasswordCard from "./CreatePasswordCard";
import PasswordCreatedCard from "./PasswordCreatedCard";
import { useParams } from "react-router-dom";
import { validatePasswordToken } from "@/services/authService";

const CreatePasswordPage: React.FC = () => {
  const { token } = useParams<"token">();
  const [isCreatePasswordComplete, setCreatePasswordComplete] = useState(false);
  const [isPasswordCreatedModalOpen, setIsPasswordCreatedModalOpen] =
    useState(false);

  useEffect(() => {
    if (!token) return;
    const passwordCreatedErrorMessage = "Invalid or alredy used Token";
    validatePasswordToken(token, "").catch((error) => {
      if (error.message === passwordCreatedErrorMessage)
        setIsPasswordCreatedModalOpen(true);
    });
  }, []);

  return (
    <Flex bgColor={"white"} align={"center"} flexDir={"column"} minH={"100vh"}>
      {isPasswordCreatedModalOpen && <PasswordAlreadyCreatedModal />}

      {isCreatePasswordComplete ? (
        <PasswordCreatedCard />
      ) : (
        <CreatePasswordCard
          setCreatePasswordComplete={setCreatePasswordComplete}
        />
      )}
    </Flex>
  );
};

export default CreatePasswordPage;
