import { AppRoute } from "@/AppRoute";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function BadgeRegisterSuccesAlert() {
  const { isOpen: isVisible } = useDisclosure({ defaultIsOpen: true });
  const navigate = useNavigate();

  return isVisible ? (
    <Alert status="success">
      <AlertIcon />
      <Box>
        <AlertTitle>Exito!</AlertTitle>
        <AlertDescription>
          La insignia ha sido creada, ahora puedes asignarla a los usuarios.
          Cierra esta alerta para continuar.
        </AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={() => navigate(AppRoute.Badges)}
      />
    </Alert>
  ) : null;
}
