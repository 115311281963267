import { AppRoute } from "@/AppRoute";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

interface ModalEndLiveProps {
  isOpen: boolean;
  onClose(): void;
  stopStreaming: () => Promise<void>;
  loadingStopRecording: boolean;
  loadingStopLiveSession: boolean;
  sessionId?: string;
}

const ModalEndLive: React.FC<ModalEndLiveProps> = ({
  isOpen,
  onClose,
  stopStreaming,
  loadingStopRecording,
  loadingStopLiveSession,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlRedirect = searchParams.get("redirect");

  const discount = 0;
  const [timeLeft, setTimeLeft] = useState<number>(discount);
  const [isCountdownStarted, setIsCountdownStarted] = useState<boolean>(false);

  const handleEndLive = async () => {
    await stopStreaming();
    onClose();
    if (urlRedirect) {
      location.replace(urlRedirect);
    } else {
      navigate(AppRoute.Programs);
    }
  };

  const cancelCountdown = () => {
    setTimeLeft(discount);
    setIsCountdownStarted(false);
    onClose();
  };

  const handleModalClose = () => {
    setIsCountdownStarted(false);
    setTimeLeft(discount);
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          mb={10}
          as={"h5"}
          textAlign={"center"}
          marginBottom={"0px"}
        >
          {t("Completion of the Live")}
        </ModalHeader>
        <ModalBody>
          <Text textAlign={"center"}>
            {t("Are you sure you want to finish this Live?")}
          </Text>
        </ModalBody>

        <ModalFooter gap={3}>
          <Button
            isLoading={loadingStopRecording || loadingStopLiveSession}
            width={"full"}
            height={"48px"}
            backgroundColor={"shades.white"}
            color={"primary.400"}
            border={"2px solid"}
            borderRadius={"100px"}
            onClick={cancelCountdown}
          >
            {!isCountdownStarted ? t("Not") : t("Cancel")}
          </Button>
          <Button
            hidden={isCountdownStarted}
            width={"full"}
            height={"48px"}
            backgroundColor={"primary.300"}
            color={"shades.white"}
            isLoading={loadingStopRecording || loadingStopLiveSession}
            borderRadius={"100px"}
            onClick={handleEndLive}
            isDisabled={isCountdownStarted && timeLeft !== discount}
            disabled={isCountdownStarted && timeLeft !== discount}
            _hover={{ backgroundColor: "primary.400" }}
          >
            {t("Yes, finish")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEndLive;
