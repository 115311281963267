import { Ico } from "@/assets/icons";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { VideoItem } from "../useYoutubePlaylist";

interface SessionCardProps {
  video: VideoItem;
  onClickSession: (video: VideoItem) => void;
}

export const SessionCard = ({ video, onClickSession }: SessionCardProps) => {
  return (
    <Flex gap={2} alignItems={"center"} justifyContent={"space-between"}>
      <Text
        fontSize={"14px"}
        variant={"bold"}
        w={{ base: "256px", sm: "fit-content" }}
      >
        {video.title}
      </Text>
      <IconButton
        onClick={() => {
          onClickSession(video);
        }}
        aria-label="play-button"
        variant={"solid"}
        isRound={true}
        colorScheme="primary"
        minW={6}
        minH={6}
        pl="2px"
        _hover={{ bg: "primary.200" }}
        _active={{ bg: "primary.400" }}
        icon={<Ico.PlayFill fontSize={"12px"} />}
      />
    </Flex>
  );
};
