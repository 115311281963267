import { useToast, Flex, Text } from "@chakra-ui/react";
import { Ico } from "@/assets/icons";

export const useToastMessage = () => {
  const toast = useToast();

  const showSuccessToast = (message: string) => {
    toast({
      position: "bottom",
      status: "success",
      duration: 4000,
      render: () => (
        <Flex
          gap={2}
          border="2px solid"
          borderRadius="8px"
          borderColor="success.300"
          p={3}
          bg="success.bgToast"
          px={4}
          py={2}
        >
          <Flex color="success.400" fontSize="24px">
            <Ico.CircleCheck />
          </Flex>
          <Text color="secondary.300" fontWeight="400" fontSize="14px">
            {message}
          </Text>
        </Flex>
      ),
    });
  };

  return { showSuccessToast };
};
