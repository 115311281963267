import { useLocation } from "react-router-dom";

export const useIsCurrentPathActive = (routesWhenActive: string[]) => {
  const { pathname } = useLocation();
  const cleanCurrentPathname = pathname === "/" ? "/" : pathname.slice(1);

  return routesWhenActive.some((route) => {
    const cleanRoute = route === "/" ? route : route.slice(1);
    return cleanCurrentPathname.startsWith(cleanRoute);
  });
};

export const useIsSubPageActive = (routes: string[]) => {
  const { pathname } = useLocation();
  return routes.some((route) => pathname === route);
};

export const activeStyles = {
  icon: { fill: "#0F43DE" },
  button: { color: "primary.400", bg: "#E9F1FF", borderRadius: "8px" },
  subPageButton: { color: "primary.400" },
};
