import { Flex, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Ico } from "@/assets/icons";

interface AlertSubscriptionProps {
  updatePaymentMethodLink: string;
}

const AlertSubscription = ({
  updatePaymentMethodLink,
}: AlertSubscriptionProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Flex
      align={{ lg: "center" }}
      bgColor={"error.50"}
      gap={"8px"}
      justify={"center"}
      p={{ base: "16px", lg: "8px" }}
    >
      <Text
        as={Ico.TriangleWarning}
        boxSize={"24px"}
        color={"error.400"}
        flexShrink={0}
      />
      <Text>
        {`${t("Your payment could not be processed")} ${t(
          "Please verify with your bank or contact us"
        )}`}
        {updatePaymentMethodLink ? (
          <Link
            display={{ base: "block", lg: "inline" }}
            fontWeight={"bold"}
            m={{ base: "8px 0 0 0", lg: "0 0 0 8px" }}
            textDecor={"underline"}
            href={updatePaymentMethodLink}
            isExternal
            rel="noopener noreferrer"
          >
            {t("Try again")}
          </Link>
        ) : null}
      </Text>
    </Flex>
  );
};

export { AlertSubscription };
