import { Suspense, useEffect } from "react";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { AppRoute, isPublicRoute } from "./AppRoute";
import { Router } from "./Router";
import { RouterLogin } from "./RouterLogin";
import { BigSpinner } from "./components/BigSpinner";
import { Layout } from "./components/Layout";
import { Navigation } from "./components/Navigation";
import { useChat } from "./hooks/chatScript";
import useGA4 from "./hooks/useGA4";
import { useHotjar } from "./hooks/useHotjar";
import useSentry from "./hooks/useSentry";
import useTrackPageViews from "./hooks/useTrackPageViews";
import { useUserAuth } from "./providers/useUser";
// import { useServiceWorker } from "./service-worker";
import { useIdentifyUser } from "./services/heap";
import { initializeServices } from "./services/initialize";
import { useSBErrorPubSub } from "./utils/errors/SBError";

initializeServices();

const Routing = (): JSX.Element => {
  useGA4();
  useSentry();
  useTrackPageViews();

  const auth = useUserAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !auth.isAuthenticated &&
      !auth.isLoading &&
      !isPublicRoute(location.pathname)
    ) {
      navigate(AppRoute.Base);
    }
  }, [auth, location, navigate]);

  return (
    <Layout>
      {auth.isLoading ? (
        <Suspense fallback={<BigSpinner />} />
      ) : auth.isAuthenticated ? (
        <Navigation>
          <Router />
        </Navigation>
      ) : (
        <RouterLogin />
      )}
    </Layout>
  );
};

const App = (): JSX.Element => {
  useChat();
  useIdentifyUser();
  useHotjar();
  useSBErrorPubSub();
  // useServiceWorker();

  return (
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  );
};

export default App;
