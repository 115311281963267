import { useLazyQuery } from "@apollo/client";
import { noCacheHeaders } from "@/utils/headers";
import { ValidateUserForEnrollmentDocument } from "@/pages/GuideTraining/graphql/validateUserForEnrollment.generated";
import { ValidateUserEnrollmentDocument } from "@/pages/academy/Bootcamps/graphql/validateUserEnrollment.generated";

export const useEnrollmentValidations = () => {
  const [validateUserForEnrollment] = useLazyQuery(
    ValidateUserForEnrollmentDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );

  const [checkUserEnrollmentStatus] = useLazyQuery(
    ValidateUserEnrollmentDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );

  return {
    validateUserForEnrollment,
    checkUserEnrollmentStatus,
  };
};
