import { useTranslation } from "react-i18next";

import { asArray } from "@/utils/array";
import { PageTitle } from "./types";

const responsive = {
  gap: "clamp(16px, 2.5vw, 48px)",
  grid: (minW: string) => {
    return `repeat(auto-fill, minmax(min(${minW}, 100%), 1fr))`;
  },
};

const usePageTitle = (path: PageTitle = []): string => {
  const { t } = useTranslation();
  const paths = asArray(path).filter((el) => el !== undefined);
  return paths.map(t).concat("smartBeemo").join(" - ");
};

export { responsive, usePageTitle };
