import { Ico } from "@/assets/icons";
import { colors } from "@/components/Theme/colors";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { AccordionModulesProps, formatAccordionTitle } from "../utils";

export const AccordionModules = ({
  accordionContent: { data, component },
  isRegistered,
  onClickEnroll,
}: AccordionModulesProps) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Heading as={"h6"}>{t("Content")}</Heading>
      {data?.map((section, index) => (
        <Flex key={index} flexDir={"column"} gap={4}>
          <Heading>{section.moduleName ?? ""}</Heading>

          {section.classes?.map((item, classIndex) => (
            <Fragment key={classIndex}>
              <Accordion
                allowMultiple
                border={`1px solid ${colors.neutral[100]}`}
                borderRadius={"6px"}
              >
                <AccordionItem border={"none"}>
                  {({ isExpanded }) => (
                    <Fragment>
                      <AccordionButton
                        py={4}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        _hover={{ backgroundColor: "white" }}
                        borderRadius={"6px"}
                      >
                        <Text
                          fontSize={"14px"}
                          textAlign={"start"}
                          variant={"bold"}
                        >
                          {formatAccordionTitle(item.title as string)}
                        </Text>
                        <Box
                          w={"24px"}
                          h={"24px"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          {isExpanded ? (
                            <Ico.Minus
                              fontSize={"24px"}
                              color={colors.primary[300]}
                            />
                          ) : (
                            <Ico.Plus
                              fontSize={"24px"}
                              color={colors.primary[300]}
                            />
                          )}
                        </Box>
                      </AccordionButton>

                      <AccordionPanel pt={0} pb={4} px={4} fontSize={"12px"}>
                        {component && component(item)}
                      </AccordionPanel>
                    </Fragment>
                  )}
                </AccordionItem>
              </Accordion>
            </Fragment>
          ))}
        </Flex>
      ))}
      {!isRegistered && (
        <Button
          onClick={onClickEnroll}
          variant={"primary"}
          fontSize={"14px"}
          w={"fit-content"}
          px={4}
          width={{ base: "full", md: "153px" }}
          h={"36px"}
        >
          {t("¡Regístrate ahora!")}
        </Button>
      )}
    </Fragment>
  );
};
