import { Ico } from "@/assets/icons";
import { InputGroup, InputLeftAddon, Select } from "@chakra-ui/react";
import { MediaDevice } from "@zoom/videosdk";

interface CameraProps {
  activeCamera: string;
  cameraList: MediaDevice[];
  onCameraChange: (deviceId: string) => void;
}

const CameraControl: React.FC<CameraProps> = (props) => {
  const { activeCamera, cameraList, onCameraChange } = props;

  return (
    <InputGroup
      mb={"12px"}
      border="1px solid"
      borderColor="neutral.300"
      borderRadius={"8px"}
    >
      <InputLeftAddon pointerEvents="none" bg={"transparent"}>
        <Ico.Camera fontSize={"24px"} />
      </InputLeftAddon>
      <Select
        value={activeCamera}
        onChange={(e) => onCameraChange(e.target.value)}
        variant="outline"
        borderColor="neutral.300"
        color="secondary.300"
        placeholder="Cámara externa"
        focusBorderColor="neutral.300"
        borderLeftColor={"transparent"}
        borderLeftRadius={"0"}
        iconColor="primary.500"
        _selected={{ borderLeftColor: "transparent", borderLeftRadius: "0" }}
        _focus={{ borderLeftColor: "transparent", borderLeftRadius: "0" }}
        _focusWithin={{ borderLeftColor: "transparent", borderLeftRadius: "0" }}
      >
        {cameraList.map((camera) => (
          <option key={camera.deviceId} value={camera.deviceId}>
            {camera.label}
          </option>
        ))}
      </Select>
    </InputGroup>
  );
};

export default CameraControl;
