import { Flex } from "@chakra-ui/react";
import { usePresenter } from "../presenter/usePresenter";
import { Category } from "@/schemaTypes";
import { Chips } from "@/components/Forms";

export const SubcategoryPills: React.FC = () => {
  const { subCategoriesForView, selectedSubCategories, selectSubCategory } =
    usePresenter();
  return (
    <Flex mb={"32px"}>
      {subCategoriesForView ? (
        <Chips
          onChange={(values: string[]) => {
            selectSubCategory(values);
          }}
          options={subCategoriesForView.map((category: Category) => ({
            name: category.name,
            value: category.id,
          }))}
          values={selectedSubCategories}
          singleSelect={false}
        />
      ) : null}
    </Flex>
  );
};
