import {
  Box,
  Text,
  Heading,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../AppRoute";
import { Button } from "./Button";

interface ReviewEmailForgotPasswordProps {
  email?: string;
  isSuccess: boolean;
}

export const ReviewEmailForgotPassword: React.FC<
  ReviewEmailForgotPasswordProps
> = ({ email, isSuccess }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      w="100%"
      h={"100%"}
      my={"auto"}
    >
      <Box
        maxW={{ base: "100%", lg: "443px" }}
        minW={!isMobile ? "480px" : undefined}
        h={"100%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        mb={20}
      >
        <Image
          src={
            isSuccess ? "/img/reviewEmailSuccess.svg" : "/img/reviewEmail.svg"
          }
        />
        <Heading
          as={"h5"}
          color={"secondary.300"}
          fontStyle={"normal"}
          textAlign={"center"}
          mb="4"
          mt={"32px"}
        >
          <Trans>
            {isSuccess
              ? "Your password has been successfully updated"
              : "Check your email"}
          </Trans>
        </Heading>

        <Box mb={8}>
          <Text color={"secondary.200"} textAlign={"center"} fontSize={"16px"}>
            <Trans>
              {isSuccess
                ? "Enter and enjoy the best platform in ecommerce."
                : t(
                    "We have sent you a link to the email with which you can update your password.",
                    {
                      email: email?.replace(/^.+(?=.{2}@)/, "*".repeat(6)),
                    }
                  )}
            </Trans>
          </Text>
        </Box>

        <Box w="100%" mt={"10"}>
          <Button
            onClick={() => navigate(AppRoute.Base)}
            w={"100%"}
            variant="primary"
          >
            <Text variant={"bold"}>
              <Trans>{isSuccess ? "Go to my study portal" : "Accept"}</Trans>
            </Text>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
