import { Ico } from "@/assets/icons";
import { useUser } from "@/providers/useUser";
import { BootcampMentor } from "@/schemaTypes";
import {
  getFormattedDate,
  getFormattedTime,
} from "@/utils/dateTimeFormat/dateTimeFormats";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AccordionCardProps, formatMentors } from "../utils";

export const AccordionCard = ({ item, isRegistered }: AccordionCardProps) => {
  const { t } = useTranslation();
  const { geolocation } = useUser();

  return (
    <Flex flexDirection={"column"} gap={4}>
      <Text fontSize={"12px"} color={"secondary.200"}>
        {item.description}
      </Text>
      <Flex flexDir={"column"} gap={2}>
        {item.mentors && (
          <Text fontSize={"12px"} color={"secondary.100"}>
            {formatMentors(item.mentors as BootcampMentor[])}
          </Text>
        )}
        <Flex gap={6}>
          <Flex gap={2} alignItems={"center"}>
            <Ico.CalendarCheck fontSize={"16px"} />
            <Text fontSize={"12px"} color={"secondary.300"}>
              {getFormattedDate(
                item.dateTime ?? new Date(),
                geolocation.location
              )}
            </Text>
          </Flex>
          <Flex gap={2} alignItems={"center"}>
            <Ico.Clock fontSize={"16px"} />
            <Text fontSize={"12px"} color={"secondary.300"} fontWeight={400}>
              {getFormattedTime(
                item.dateTime ?? new Date(),
                geolocation.location
              )}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {isRegistered && (
        <Button
          onClick={() => {
            if (item.link) {
              window.open(item.link, "_blank");
            }
          }}
          isDisabled={!item.link}
          cursor={item.link ? "pointer" : "not-allowed"}
          variant={"primary"}
          fontSize={"14px"}
          w={"fit-content"}
          maxW={"148px"}
          px={4}
          width={{ base: "full", md: "153px" }}
          h={"36px"}
        >
          {t("Join session")}
        </Button>
      )}
    </Flex>
  );
};
